import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

const APIForm = axios.create({
	baseURL: REACT_APP_BASE_URL,
	headers: {
		Accept: "application/json",
		"Content-Type": "multipart/form-data",
	},
	withCredentials: true,
});


APIForm.interceptors.request.use((config) => {
	return config;
});


export default APIForm;
