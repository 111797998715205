import styled from "styled-components";

export const WelcomePageStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  #wrapper {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  #wrapper img {
    width: 70%;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
`;

export const ModalFormWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 30%; */
  width: ${(props) => (props.medium ? "50%" : props.large ? "99%" : "30%")};
  border: 2px solid #7ca212;
  box-shadow: 0 0 10px #7ca212;
  padding: 1rem;
  transition: all 0.3s;
  background-color: #fff;
  border-radius: 0.5rem;
  max-height: ${(props) => (props.large ? "initial" : "80%")};
  overflow-y: auto;
  box-sizing: border-box;
  .chart-wrapper {
    position: relative;
    .filter-wrapper {
      display: none;
      position: absolute;
      right: 0;
      top: 0.3rem;
      z-index: 6;
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 0.5rem;
      select {
        height: 1.8rem;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        font-size: 0.75rem;
        outline: #fff;
        transition: all 0.3s;
        &:focus {
          border: 2px solid #7ca212;
        }
      }
    }
  }
  &:focus {
    outline: #7ca212;
  }
  .top {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    h2 {
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #090b17;
      font-style: normal;
      font-weight: bold;
    }
    .close {
      position: absolute;
      width: 0.6rem;
      right: 1rem;
      top: 0.2rem;
    }
    .close svg {
      fill: #959190;
      cursor: pointer;
      transition: fill ease-in-out 0.5s;
      &:hover {
        fill: #7ca212;
      }
    }
  }
  .content {
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #090b17;
    font-style: normal;
    font-weight: bold;
    display: flex;
    flex-direction: column;
  }
  .action-button {
    margin: 0 auto;
    margin-top: 1rem;
  }
  @media (max-width: 768px) {
    width: ${(props) => (props.large ? "90%" : "50%")};
    button {
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 640px) {
    width: ${(props) => (props.large ? "100%" : "80%")};
  }
`;

export const ChartPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .selected-wrapper {
    width: 100%;
    padding: 0rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 10px;
    margin: 4rem 0;
    transform: translateX(0vw);
    transition: opacity 1.5s ease, transform 1.5s ease;
    /* transition: all 0.9s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
    .title-wrapper {
      width: 100%;
      color: #7ca212;
      background-color: #f8f8f8;
      font-weight: 700;
      p {
        text-align: center;
        font-size: 1rem;
        margin: 0;
        padding: 2rem 0;
      }
    }
    .charts-breakdown {
      display: flex;
      margin: 2rem 0;
    }
    @media (max-width: 1024px) {
      .charts-breakdown {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
      }
    }
  }

  .empty-text {
    display: flex;
    justify-content: center;
    padding: 70px 0;
  }
  .right {
    float: right;
  }
`;

export const ChartWrapper = styled.div`
  /* margin: auto; */
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  /* align-items: center; */
  min-height: 100vh;
  padding: 0rem 6rem;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    display: none;
  }
  .chart-wrapper {
    box-shadow: 0 4px 14px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transform: translateY(100vh) translateX(70vw);
    transition: opacity 1.5s ease, transform 1.5s ease;
    display: flex;
    width: 100%;
    /* margin: auto; */
  }
  .threatSource-wrapper {
  }
  .visible {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
  .chart-wrapper,
  .selected-wrapper {
    width: 100%;
    margin: auto;
    padding: 4rem 2rem;
    box-sizing: border-box;
  }
  .selected-wrapper {
    padding: 0rem;
    margin-top: 4rem;
    border-radius-top: 10px;

    .region {
      margin: 4rem 2rem;
      box-sizing: border-box;
      width: calc(100% - 4rem);
    }
    .title-wrapper {
      margin-bottom: 4rem;
      width: 100%;
      color: #7ca212;
      background-color: #f8f8f8;
      font-weight: 700;
      p {
        text-align: center;
        font-size: 1rem;
        margin: 0;
        padding: 2rem 0;
      }
      span {
        text-transform: capitalize;
      }
    }
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 10px;
    transition: all 0.9s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    box-sizing: border-box;
    .charts-breakdown {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 50px;

      box-sizing: border-box;
      margin: 0 2rem;
    }
  }

  .selected-chart-wrapper {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 10px;
    margin-bottom: 4rem;
    transform: translateY(0) translateX(0);
    transition: opacity 1.5s ease, transform 1.5s ease;
    grid-column: auto;
    overflow-x: auto;
    /* padding: 1rem; */

    box-sizing: border-box;
    .filter {
      width: 100%;
      padding-right: 1rem;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }
    .title-wrapper {
      margin-bottom: 1rem;
    }
  }
  .pie {
    padding: 0 12%;
    box-sizing: border-box;
  }
  @media (max-width: 1440px) {
    padding: 0rem calc((100vw - 1200px) / 2) 0;
    width: 100%;
    margin: initial;
  }
  @media (max-width: 1204px) {
    padding: 0rem calc((100vw - 900px) / 2) 4rem;
    .selected-wrapper .charts-breakdown {
      grid-template-columns: 500px auto;
      grid-gap: 30px;
    }
  }
  @media (max-width: 768px) {
    padding: 0rem calc((100vw - 700px) / 2) 4rem;
    .selected-wrapper .charts-breakdown {
      grid-template-columns: 400px auto;
      grid-gap: 20px;
    }
  }
  @media (max-width: 640px) {
    padding: 0rem calc((100vw - 400px) / 2) 4rem;
    justify-content: center;
    .chart-wrapper,
    .selected-wrapper {
      display: block;
      width: 100%;
    }
    .selected-wrapper {
      padding: 0.5rem;
      .title-wrapper p {
        text-align: left;
        padding: 2rem;
      }
      .charts-breakdown {
        grid-template-columns: auto;
      }
      .region {
        margin: 0;
        box-sizing: border-box;
        width: 100%;
        border-radius: 0;
      }
    }
    .chart-wrapper {
      padding: 2rem 1rem;
    }

    .selected-chart-wrapper {
      box-shadow: none;
      margin-bottom: 0;
    }
  }
`;
export const CategoryWrapper = styled.div`
  width: 100%;
  margin: auto;
  padding: 4rem 2rem;
  box-sizing: border-box;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  /* align-items: center; */
  margin-bottom: 1.5rem;
  select {
    height: 40px;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    font-size: 0.75rem;
  }
  p {
    margin-right: 1rem;
    font-weight: 700;
    font-size: 0.75rem;
    color: #7ca212;
  }
  div {
    display: flex;
    select :nth-child(1) {
      margin-right: 0.5rem;
    }
    select {
      outline: transparent;
      &:focus {
        box-shadow: 0 0 5px #7ca212;
        border: 1px solid #7ca212;
      }
    }
  }
`;

export const MapsWrapper = styled.div`
  display: flex;
`;

// ZTE
export const SearchStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h3 {
    font-size: 0.875rem;
    margin: 2rem 0;
    color: #62646d;
    text-transform: uppercase;
  }
  .table-wrapper {
    overflow: auto;
    width: 100%;
  }
  .top-wrapper {
    /* only add margin if h3 is removed */
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .search {
    width: 100%;
    margin-right: 1rem;
    div {
      margin-top: 0;
      label {
        display: none;
      }
      input {
        margin: 0;
        padding: 0.5rem;
      }
    }
  }
`;

export const HomeStyle = styled.div`
  padding: 0 1rem;
  align-items: center;
  flex-direction: column;
  .back-btn {
    display: flex;
    align-items: center;
    height: 1.2rem;
    width: 5rem;
    /* color: #574c4c; */
    border: 1px solid #b9d948;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    padding: 0.75rem;
    color: #fff;
    background-color: #96c115;
    align-self: flex-start;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #7ca50f;
    }
    p {
      line-height: 1.2rem;
      font-size: 0.75rem;
      margin-left: 0.3rem;
      color: #fff;
    }
  }
  .admin-details {
    padding: 1rem;
    max-width: 800px;

    h2 {
      font-size: 1.2rem;
      color: #62646d;
      text-transform: uppercase;
    }
    .details-wrapper {
      padding: 1rem;
      box-shadow: 0 0 5px #e5e8e9;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .info {
        width: 45%;
        h3 {
          font-size: 0.75rem;
        }
      }
    }
  }
  .buttons-container {
    display: flex;
  }
  .send-notification-button {
    margin-left: auto;
    height: 1.2rem;
  }
  .update-issue-button {
    float: right;
  }
  .add-issue {
    margin-top: 20px;
    float: right;
  }
  .headers {
    font-size: 0.875rem;
    font-weight: 700;
    color: #62646d;
    margin-bottom: -12px;
  }
  .table-style {
    padding: 0 1.2rem 1.2rem;
    box-shadow: 0 0 5px #e5e8e9;
    margin: 2rem 0;
    overflow-x: scroll;
  }
`;

// export const UsageWrapper = styled.div`
// 	display: flex;
// 	flex-wrap: wrap;
// 	display: grid;
// 	grid-auto-columns: 1fr;
// 	min-height: 100%;
// 	width: 100%;
// 	.tile {
// 		background-color: "#f3f3f3";
// 		border: 1px solid;
// 		color: "#f3f3f3";
// 		flex: 1 1 percentage(1/3);
// 		min-height: 100px;
// 		padding: 10px;
// 	}

// 	.size-2 {
// 		flex-basis: percentage(2/3);
// 		grid-column: auto / span 2;
// 	}

// 	.size-3 {
// 		flex-basis: 100%;
// 		grid-column: auto / span 3;
// 	}

// 	// Prevent vertical scrolling on portrait screens
// 	@media only screen and (orientation: portrait) {
// 		.tilegrid {
// 			height: 100%;
// 		}
// 	}

// 	// Make sure content isn't squished on landscape screens
// 	@media only screen and (orientation: landscape) {
// 		.tilegrid {
// 			grid-auto-rows: minmax(150px, 1fr);
// 		}
// 	}

// 	@media only screen and (min-width: 800px) {
// 		.tilegrid {
// 			grid-gap: 10px;
// 			grid-template-columns: repeat(5, 1fr);
// 		}

// 		.tile {
// 			margin: 10px;
// 			flex-basis: percentage(1/5);
// 		}
// 		.size-2 {
// 			flex-basis: percentage(2/5);
// 		}
// 		.size-3 {
// 			flex-basis: percentage(3/5);
// 		}

// 		@supports (display: grid) {
// 			.tilegrid {
// 				padding: 10px;
// 			}
// 			.tile {
// 				margin: 0;
// 			}
// 		}
// 	}
// `;

export const UsageWrapper = styled.div`
  padding-top: 3rem;
  padding-left: 12rem;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(1, 1fr);

  .card-fluid {
    width: 100%;
    height: auto;
  }
  .highcharts-background {
    fill: #ffffff; /* Your desired background color */
  }
  .filters {
    grid-column: span 2;
    top: 5.7rem;
    /* bottom: 0; */
    z-index: 9;
    position: fixed;
    width: 20%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    height: fit-content;
    select {
      height: 40px;
      border: 1px solid #bfbfbf;
      border-radius: 4px;
      font-size: 0.75rem;
      outline: #fff;
      transition: all 0.3s;
      position: relative;
      z-index: 6;
      &:focus {
        border: 2px solid #7ca212;
      }
    }
  }
  .title-text {
    font-size: 1.2em;
    color: rgb(51, 51, 51);
    font-weight: bold;
    fill: rgb(51, 51, 51);
    padding: 1rem 0;
    text-align: left;
  }

  .chart-wrapper {
    position: relative;
    .filter-wrapper {
      display: none;
      position: absolute;
      right: 0;
      top: 0.3rem;
      z-index: 6;
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 0.5rem;
      select {
        height: 1.8rem;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        font-size: 0.75rem;
        outline: #fff;
        transition: all 0.3s;
        &:focus {
          border: 2px solid #7ca212;
        }
      }
    }
  }
  /* & > :nth-child(3) {
		grid-column: span 2;
	} */
  /* @media (max-width: 500px) {
		grid-template-columns: 1fr;
		& > :nth-child(3) {
			grid-column: span 1; 
		}
	} */
  @media (max-width: 320px) {
    .filters {
      grid-template-columns: repeat(2, 1fr);
      /* width: 100%; */
    }
  }
  /* @media screen and (min-width: 1000px) {
		grid-template-columns: repeat(3, 1fr);
	} */
`;
