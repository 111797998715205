import { ProgressbarStyle } from "./RatingsCard.style";
const ProgressBar = ({percent}) => {
  return (
    <ProgressbarStyle $percent={percent}>
      <div className="indicator"></div>
    </ProgressbarStyle>
  );
};

export default ProgressBar;
