import { NavLink } from "react-router-dom";
import { useState, useRef } from "react";
import Brand from "../../assets/images/logo.png";
import { IoLogOutOutline, IoMenu } from "react-icons/io5";
import LinkCard from "../molecules/LinkCard/LinkCard";
import { useComponentVisible } from "../../utils/customHooks/useComponentVisible";

// import "./navbar.css";
import { styled } from "styled-components";
const NavStyle = styled.nav`
  height: 3.75rem;
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid #e5e8e9;
  .container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .navbar {
    height: 3.75rem;
    background-color: #fef7e5;
    position: relative;
  }

  .logo {
    width: 8.625rem;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      aspect-ratio: 3/2;
      object-fit: contain;
    }
  }

  .menu-icon {
    display: none;
  }

  .nav-elements {
    display: flex;
    align-items: center;
  }

  .nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
  }

  .nav-elements ul li:not(:last-child) {
    margin-right: 2rem;
  }

  .nav-elements ul a, .dashboard-style {
    display: flex;
    align-items: center;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1px;
    color: #3e3e3e;
    text-transform: uppercase;
    font-size: 0.75rem;
    cursor: pointer;
    padding: 0.5rem 0.75rem 0.375rem;
    transition: all 0.5s;
    &:hover {
      color: #7ca50f;
    }
  }

  .nav-elements ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
    padding: 0.5rem 0.75rem 0.375rem;
    border: 1px solid #b9d948;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #96c115;
    transition: all 0.5s;
    &:hover {
      color: #fff;
      background-color: #7ca50f;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1px;
    color: #3e3e3e;
    text-transform: uppercase;
    font-size: 0.75rem;
    cursor: pointer;
    padding: 0.5rem 0.75rem 0.375rem;
    border: 1px solid transparent;
    &:hover {
      color: #fff;
      background-color: #7ca50f;
      border: 1px solid #b9d948;
      border-radius: 0.25rem;
    }
  }

  .log-out {
    font-size: 1.2rem;
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
      margin-right: 0.6rem;
    }
  }

  @media (max-width: 600px) {
    .menu-icon {
      display: flex;
      align-items: center;
      font-weight: 400;
      text-decoration: none;
      letter-spacing: 1px;
      color: #3e3e3e;
      text-transform: uppercase;
      font-size: 0.75rem;
      cursor: pointer;
      padding: 0.5rem 0.75rem 0.375rem;
    }

    .nav-elements {
      position: absolute;
      right: 0;
      top: 3.6rem;
      background-color: rgb(255, 255, 255);
      width: 0px;
      height: calc(100vh - 3.75rem);
      transition: all 0.3s ease-in;
      overflow: hidden;
      z-index: 10;
      display: flex;
      align-items: flex-start;
    }

    .nav-elements.active {
      width: 100%;
    }

    .nav-elements ul {
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      justify-content: flex-start;
      width: 100%;
    }
    .nav-elements ul a.active {
      border-radius: 0;
    }
    .nav-elements ul a,
    .icon, .dashboard-style {
      justify-content: center;
    }
    .nav-elements ul li {
      margin-right: unset;
      margin-top: 22px;
      background-color: inherit;
    }
    .nav-elements ul li:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const Navbar = ({ navlinks }) => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const handleLogout = () => {
    localStorage.removeItem("tkn");
    window.location.reload();
  };


  const pageLinks = [
    { name: "ZTNA", path: "/ztna" },
    { name: "Threat", path: "/threat" },
  ];
  const cardRef = useRef(null);
  const [visible, setVisible] = useState(false);
	useComponentVisible(cardRef, setVisible);
	
  const handleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <NavStyle>
      <div className="container">
        <div className="logo">
          <img src={Brand} alt="Armacell's logo" />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          {/* <Hamburger /> */}
          <IoMenu className="log-out" />
          Menu
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            {navlinks.map((link, index) => (
              <li key={index}>
                {link.name !== "Dashboard" ? (
                  <NavLink to={link.path}>{link.name}</NavLink>
                ) : (
                  <div onClick={handleVisibility}  ref={cardRef}>
                    <div className="dashboard-style">
                      <span>{link.name}</span>
                    </div>
                    {visible && <LinkCard detailsList={pageLinks}/>}
                  </div>
                )}
              </li>
            ))}
            <li onClick={handleLogout} className="icon">
              <IoLogOutOutline className="log-out" />
              <span>Logout</span>
            </li>
          </ul>
        </div>
      </div>
    </NavStyle>
  );
};

export default Navbar;
