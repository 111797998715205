import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import markerClusters from "highcharts/modules/marker-clusters";
import proj4 from "proj4";

markerClusters(Highcharts);
if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
  HighchartsMap(Highcharts);
}

const ZtnaMaps = ({ mapData }) => {
  Highcharts.setOptions({
    credits: {
      enabled: false,
    },
  });
  return (
    <div className="">
        <div className="chart">
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                map: worldMap,
                height: (8 / 16) * 100 + "%", // 16:9 ratio
              },
              title: {
                text: mapData?.title?.toUpperCase(),
                style: {
                  fontSize: 14,
                },
              },
              mapNavigation: {
                enabled: true,
                buttonOptions: {
                  verticalAlign: "bottom",
                },
              },
              colorAxis: {
                min: 0,
                max: 20,
              },
              legend: {
                enabled: true,
              },
              tooltip: {
                headerFormat: "",
                pointFormat:
                  "<b>IP Address: {point.ipAddress}</b><br>Latitude: {point.lat} <br>Longitude: " +
                  "{point.lon}",
              },
              plotOptions: {
                mappoint: {
                  cluster: {
                    enabled: true,
                    allowOverlap: true,
                    animation: {
                      duration: 450,
                    },
                  },
                },
              },
              series: [
                {
                  // Use the gb-all map with no data as a basemap
                  name: "Basemap",
                  mapData: worldMap,
                  borderColor: "#A0A0A0",
                  nullColor: "rgba(177, 244, 177, 0.5)",
                  showInLegend: false,
                },
                {
                  // Specify points using lat/lon
                  type: "mappoint",
                  name: "Geolocations",
                  cluster: {
                    enabled: true,
                    dataLabels: {
                      style: {
                        fontSize: "8px",
                      },
                      y: -1,
                    },
                    allowOverlap: false,
                    animation: true,
                    layoutAlgorithm: {
                      type: "grid",
                      gridSize: 100,
                    },
                  },
                  data: mapData?.data,

                  cursor: "pointer",
                  point: {
                    events: {
                      click: function () {
                        console.log(this.keyword);
                      },
                    },
                  },
                },
              ],
            }}
            constructorType={"mapChart"}
          />
        </div>
    </div>
  );
};
export default ZtnaMaps;
