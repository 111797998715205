import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../atoms";
import { FormStyle, FieldStyle } from "./Form.style";
import SearchDropDown from "../AdminCard/SearchDropdown";
import { deauthenticateUserSessions } from "../../../Redux/Zte/Ztna/ZtnaActions";
import { useState } from "react";

const ConfirmTerminationForm = ({ setOpen, data }) => {
  const dispatch = useDispatch();
  const [selectedReason, setSelectedReason] = useState();
  const handleSubmit = () => {
    dispatch(deauthenticateUserSessions(data.id, selectedReason));
    setOpen(false);
  };

  const { deauthReasons, fetchingDeauthReasons } = useSelector(
    (state) => state.ztna
  );

  return (
    <FormStyle>
      <FieldStyle>
        <div className="button-style">
          <h6>Group</h6>
          <p>{data.Group}</p>
        </div>
        <h6>Deauthenticate Session</h6>
        <SearchDropDown
          data={deauthReasons}
          title={"Reasons"}
          type="select"
          group={"reason"}
          setData={setSelectedReason}
          user="form"
          loading={fetchingDeauthReasons}
        />
      </FieldStyle>
      <div className="issue-modal">
        <Button fill="fill" onclick={handleSubmit}>
          Deauthenticate
        </Button>
        <div className="right">
          <Button fill="fill" onclick={() => setOpen(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </FormStyle>
  );
};

export default ConfirmTerminationForm;
