import Modal from "@mui/material/Modal";
import { ModalFormWrapper } from "../../pages/Pages.style";
import { ReactComponent as CloseBtn } from "../../assets/icons/close.svg";
import UpdateIssueStatus from "../../components/molecules/form/UpdateIssueStatus";
import { useState, useEffect } from "react";

const UpdateIssueStatusModal = ({ open, setOpen, status }) => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setIsSuccessful(true);
  };
  useEffect(() => {
    setIsSuccessful(false);
  }, [isSuccessful]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalFormWrapper>
        <div className="top">
          <h2>Update Issue Status</h2>
          <div className="close">
            <CloseBtn onClick={handleClose} />
          </div>
        </div>
        <div className="edit-container">
          <UpdateIssueStatus status={status} />
        </div>
      </ModalFormWrapper>
    </Modal>
  );
};

export default UpdateIssueStatusModal;
