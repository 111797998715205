import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const ZtnaBarChart = ({ data, title, yAxis, xAxis, stack, country }) => {
  Highcharts.setOptions({
    credits: {
      enabled: false,
    },
  });
  const options = {
    chart: {
      type: "column",
      width: null,
    },
    title: {
      text: title ? title : "",
    },

    xAxis: {
      categories: xAxis,
      crosshair: true,
      accessibility: {
        description: "Issues",
      },
    },
    yAxis: {
      title: {
        text: yAxis,
      },
    },
    series: data,
    tooltip: {
      pointFormat:
        "<br>Resolution Speed (Hours): {point.y} <br>Count: {point.z}",
    },

    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            xAxis: {
              // labels: {
              //   formatter: function () {
              //     return this.value.charAt(0);
              //   },
              // },
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
          },
        },
      ],
    },
  };
  const stackOptions = {
    chart: {
      type: "column",
      width: null,
    },
    title: {
      text: "",
    },

    xAxis: {
      categories: xAxis,
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      title: {
        text: yAxis,
      },
    },
    series: data,
    tooltip: {
      pointFormat:
        "<br>Resolution Speed (Hours): {point.y} <br>Count: {point.z}",
    },

    credits: {
      enabled: false,
    },

    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            xAxis: {
              // labels: {
              //   formatter: function () {
              //     return this.value.charAt(0);
              //   },
              // },
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  const stackOptionsCountry = {
    chart: {
      type: "column",
      width: null,
    },
    title: {
      text: "",
    },

    xAxis: {
      categories: xAxis,
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      title: {
        text: yAxis,
      },
    },
    series: data,
    tooltip: {
      pointFormat:
        "<br>Resolution Speed (Hours): {point.y}",
    },

    credits: {
      enabled: false,
    },

    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            xAxis: {
              // labels: {
              //   formatter: function () {
              //     return this.value.charAt(0);
              //   },
              // },
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <HighchartsReact
      options={stack ? stackOptions : country ? stackOptionsCountry : options}
      highcharts={Highcharts}
    />
  );
};

export default ZtnaBarChart;
