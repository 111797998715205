import { useEffect } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import { Table } from "../../atoms";
import { structureData } from "../../../utils/FormatData";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  getAllActiveSessions,
  getListDeauthReasons,
} from "../../../Redux/Zte/Ztna/ZtnaActions";
import ConfirmTerminationModal from "../ConfirmTerminationModal";
import SearchFilter from "../SearchFilter/SearchFilter";

const SessionsTable = () => {
  const headers = [
    "Username",
    "IP Address",
    "Duration",
    "Inactive time",
    "Active time",
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState();
  const [selectedData, setSelectedData] = useState();
  const dispatch = useDispatch();
  const handleRowClick = (id) => {
    setOpen(true);
    setSelectedData(id);
  };

  useEffect(() => {
    dispatch(getAllActiveSessions(currentPage, searchQuery));
  }, [dispatch, currentPage, searchQuery]);

  useEffect(() => {
    dispatch(getListDeauthReasons());
  }, [dispatch]);

  const { activeSessions, fetchingActiveSessions } = useSelector(
    (state) => state.ztna
  );

  return (
    <AdminCardStyles>
      <SearchFilter setSearchQuery={setSearchQuery} placeholder="Username" />
      <div className="table-style">
        <Table
          headers={headers}
          data={structureData(
            "activeConnections",
            activeSessions?.data?.results
          )}
          loading={fetchingActiveSessions}
          handleRowClick={handleRowClick}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={activeSessions?.data?.totalCount}
          pageSize={10}
          type="info"
        />
      </div>
      <ConfirmTerminationModal
        open={open}
        setOpen={setOpen}
        data={selectedData}
      />
    </AdminCardStyles>
  );
};

export default SessionsTable;
