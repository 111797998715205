// import { FaBars } from 'react-icons/fa'
import styled from 'styled-components'
// import { NavLink as Link } from 'react-router-dom'

export const PrimaryNav = styled.nav`
	z-index: 14;
	height: 6rem;
	width: 100vw;
	display: flex;
	background: #fff;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 2rem 4rem;
	box-shadow: 0px 3px 5px 0px #eee;
	position: fixed;
	.icon {
		color: #96c115;
		font-size: 1.5rem;
		transition: color .5s;
		&:hover {
			cursor: pointer;
			color: #7ca212;
		}
	}
	.log-out {
		margin-left: 2rem;
	}
	#logo-wrapper {
		width: 8.625rem;
		display: flex;
		justify-content: center;
		/* background-color: red; */
	}
	#logo-wrapper img {
		width: 100%;
		aspect-ratio: 3/2;
		object-fit: contain;
	}
	@media (max-width: 1440px) {
		padding: 0.18rem calc((100vw - 1200px) / 2);
		height: 90px;
	}
	@media (max-width: 1200px) {
		padding: 0.18rem calc((100vw - 900px) / 2);
	}
	@media (max-width: 768px) {
		padding: 0.18rem calc((100vw - 700px) / 2);
	}
	@media (max-width: 640px) {
		padding: 0.18rem calc((100vw - 400px) / 2);
		#logo-wrapper {
			width: 40%;
		}
	}
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
 .mobile{
  display: none;
 }
  @media (max-width: 640px){
    .mobile{
      display: block;
    }
    .desktop{
      display: none;
    }
  }
`;