import React from 'react'
import { ToggleStyle, ToggleWrapperStyle } from './Atoms.Style';

const Toggle = ({toggle,toggleOptions,tab}) => {
    const toggleBg =(child)=>{
        if(tab === child){
            return "#96c115";
        }
        return null
    }
  return (
		<ToggleWrapperStyle>
			{toggleOptions?.map((option, index) => (
				<ToggleStyle
					key={index}
					onClick={() => toggle(option)}
					bgColor={toggleBg(option)}>
					{option}
				</ToggleStyle>
			))}
		</ToggleWrapperStyle>
	);
}

export default Toggle