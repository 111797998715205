import {
  GET_ALL_ISSUES_FAILURE,
  GET_ALL_ISSUES_START,
  GET_ALL_ISSUES_SUCCESS,
  GET_ISSUE_DETAIL_FAILURE,
  GET_ISSUE_DETAIL_START,
  GET_ISSUE_DETAIL_SUCCESS,
  GET_ISSUE_TYPE_START,
  GET_ISSUE_TYPE_SUCCESS,
  GET_ISSUE_TYPE_FAILURE,
  GET_HOLD_REASON_START,
  GET_HOLD_REASON_SUCCESS,
  GET_HOLD_REASON_FAILURE,
  GET_ISSUE_TYPE_BY_ID_START,
  GET_ISSUE_TYPE_BY_ID_SUCCESS,
  GET_ISSUE_TYPE_BY_ID_FAILURE,
  ADD_ISSUE_START,
  ADD_ISSUE_FAILURE,
  ADD_ISSUE_SUCCESS,
} from "./IssueActionTypes";

const initialState = {
  issues: [],
  fetchingAllIssues: false,
  fetchingAllIssuesError: null,
  issueDetail: {},
  fetchingIssueDetail: false,
  fetchingIssueDetailError: null,
  issueType: {},
  fetchingIssueType: false,
  fetchingIssueTypeError: null,
  holdReasons: [],
  fetchingHoldReasons: false,
  fetchingHoldReasonsError: null,
  issueTypeId: {},
  fetchingIssueTypeId: false,
  fetchingIssueTypeIdError: null,
  addIssueStart: false,
  addIssue: {},
  addIssueError: null,
};

const issueReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_ISSUES_START:
      return {
        ...state,
        fetchingAllIssues: true,
        fetchingAllIssuesError: null,
        issues: [],
      };
    case GET_ALL_ISSUES_FAILURE:
      return {
        ...state,
        fetchingAllIssues: false,
        fetchingAllIssuesError: payload,
        issues: [],
      };
    case GET_ALL_ISSUES_SUCCESS:
      return {
        ...state,
        fetchingAllIssues: false,
        fetchingAllIssuesError: null,
        issues: payload.data,
      };
    case GET_ISSUE_DETAIL_START:
      return {
        ...state,
        fetchingIssueDetail: true,
        fetchingIssueDetailError: null,
        issueDetail: {},
      };
    case GET_ISSUE_DETAIL_FAILURE:
      return {
        ...state,
        fetchingIssueDetail: false,
        fetchingIssueDetailError: payload,
        issueDetail: {},
      };
    case GET_ISSUE_DETAIL_SUCCESS:
      return {
        ...state,
        fetchingIssueDetail: false,
        fetchingIssueDetailError: null,
        issueDetail: payload,
      };
    case ADD_ISSUE_START:
      return {
        ...state,
        addIssueStart: true,
        addIssue: {},
        addIssueError: null,
      };
    case ADD_ISSUE_SUCCESS:
      return {
        ...state,
        addIssueStart: false,
        addIssue: payload,
        addIssueError: null,
      };
    case ADD_ISSUE_FAILURE:
      return {
        ...state,
        addIssueStart: false,
        addIssue: {},
        addIssueError: payload,
      };
    case GET_ISSUE_TYPE_START:
      return {
        ...state,
        fetchingIssueType: true,
        fetchingIssueTypeError: null,
        issueType: {},
      };
    case GET_ISSUE_TYPE_FAILURE:
      return {
        ...state,
        fetchingIssueType: false,
        fetchingIssueTypeError: payload,
        issueType: {},
      };
    case GET_ISSUE_TYPE_SUCCESS:
      return {
        ...state,
        fetchingIssueType: false,
        fetchingIssueTypeError: null,
        issueType: payload.data,
      };
    case GET_HOLD_REASON_START:
      return {
        ...state,
        fetchingHoldReasons: true,
        fetchingHoldReasonsError: null,
        holdReasons: [],
      };
    case GET_HOLD_REASON_FAILURE:
      return {
        ...state,
        fetchingHoldReasons: false,
        fetchingHoldReasonsError: payload,
        holdReasons: [],
      };
    case GET_HOLD_REASON_SUCCESS:
      return {
        ...state,
        fetchingHoldReasons: false,
        fetchingHoldReasonsError: null,
        holdReasons: payload.data,
      };
    case GET_ISSUE_TYPE_BY_ID_START:
      return {
        ...state,
        fetchingIssueTypeId: true,
        fetchingIssueTypeIdError: null,
        issueTypeId: {},
      };
    case GET_ISSUE_TYPE_BY_ID_FAILURE:
      return {
        ...state,
        fetchingIssueTypeId: false,
        fetchingIssueTypeIdError: payload,
        issueTypeId: {},
      };
    case GET_ISSUE_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingIssueTypeId: false,
        fetchingIssueTypeIdError: null,
        issueTypeId: payload.data,
      };
    default:
      return state;
  }
};

export default issueReducer;
