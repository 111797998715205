import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";

export const ZtnaPieChart = ({ data, title }) => {
  Highcharts.setOptions({
    credits: {
      enabled: false,
    },
  });
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: title ? title : "",
    },
    tooltip: {
      valueSuffix: "%",
      pointFormat: "{point.y:.1f}"
    },

    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 20,
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.y:.1f}",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
      pie: {
        size: "100%",
        height: "100%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 300,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            xAxis: {
              // labels: {
              // 	formatter: function () {
              // 		return this.value.charAt(0);
              // 	},
              // },
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Value",
        colorByPoint: true,
        data: data,
      },
    ],
  };
  return <PieChart options={options} highcharts={Highcharts} />;
};

export default ZtnaPieChart;
