import { useEffect, useState } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import SearchDropDown from "./SearchDropdown";
import { Button } from "../../atoms";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserByGroup,
  getAzureGroupsBasic,
  getUsersBasic,
  assignDeviceUser,
  initiateUsersUpdateGet,
} from "../../../Redux/Zte/ZteUsers/ZteUsersActions";
import { getDevicesBasic } from "../../../Redux/Zte/Device/DeviceActions";
import {
  ztnaServicesIdentifiersGet,
  assignGroupToServicePost,
} from "../../../Redux/Zte/Ztna/ZtnaActions";

const UserManagementCard = () => {
  const dispatch = useDispatch();
  const [groupId, setGroupId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [userId, setUserId] = useState("");
  const [userGroupIds, setUserGroupIds] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [adGroupId, setAdGroupId] = useState("");
  const [unassignPreviousUser, setUnassignPreviousUser] = useState(false)

  const handleAddGroupId = () => {
    dispatch(addUserByGroup(groupId));
  };
  useEffect(() => {
    dispatch(getUsersBasic());
    dispatch(getDevicesBasic());
    dispatch(getAzureGroupsBasic());
    dispatch(ztnaServicesIdentifiersGet());
  }, [dispatch]);

  const {
    azureGroupsBasic,
    fetchingAzureGroups,
    usersBasic,
    fetchingUsersBasics,
  } = useSelector((state) => state.users);
  const { allDevicesBasic, fetchingAllDevicesBasic } = useSelector(
    (state) => state.devices
  );
  const { ztnaServicesIdentifiers, fetchingZtnaServicesIdentifiers } = useSelector((state) => state.ztna);
  const updateData = {
    deviceId: deviceId,
    userID: userId,
    unassignPreviousUser: unassignPreviousUser,
  };
  const userGroupsData = {
    azureUserGroupIds: userGroupIds,
  };
  const groupServiceData = {
    ztnaServiceIdentifierId: serviceId,
    azureAdGroupId: adGroupId,
  };

  const handleAssign = () => {
    dispatch(assignDeviceUser(updateData));
  };
  const handleUpdate = () => {
    dispatch(initiateUsersUpdateGet(userGroupsData));
  };
  const handleAssignGroup = () => {
    dispatch(assignGroupToServicePost(groupServiceData));
  };
  const handleChecked = () => {
    setUnassignPreviousUser((unassignPreviousUser) => !unassignPreviousUser);
  };

  return (
    <AdminCardStyles>
      <div className="admin-input">
        <p>Group</p>
        <div className="group-container">
          <input
            placeholder="ID"
            className="input-text"
            onChange={(e) => setGroupId(e.target.value)}
          />
          <Button
            className="button-style"
            fill="fill"
            onclick={handleAddGroupId}
          >
            Add
          </Button>
        </div>
      </div>

      <div className="group-container">
        <SearchDropDown
          title={"Users"}
          type="select"
          data={usersBasic}
          group={"users"}
          setData={setUserId}
          heading={"Assign Device to User"}
          loading={fetchingUsersBasics}
        />
        <div className="no-heading-style">
          <SearchDropDown
            title={"Devices"}
            type="select"
            data={allDevicesBasic}
            setData={setDeviceId}
            group={"devices"}
            loading={fetchingAllDevicesBasic}
          />
        </div>
        <div className="heading-style user-checkbox">
              <p>Unassign Previous User</p>
              <input
                type="checkbox"
                onChange={handleChecked}
                className="check"
              />
            </div>
        <div className="button-style-ip">
          <Button fill="fill" onclick={handleAssign}>
            Assign
          </Button>
        </div>
      </div>
      <div className="group-container">
        <SearchDropDown
          title={"Groups"}
          type="select"
          data={azureGroupsBasic}
          setData={setAdGroupId}
          heading={"Assign Groups to Service"}
          group={"services"}
          loading={fetchingAzureGroups}
        />
        <div className="no-heading-style">
          <SearchDropDown
            title={"Services"}
            type="select"
            data={ztnaServicesIdentifiers}
            setData={setServiceId}
            group={"services"}
            loading={fetchingZtnaServicesIdentifiers}
          />
        </div>
        <div className="button-style-ip">
          <Button fill="fill" onclick={handleAssignGroup}>
            Assign
          </Button>
        </div>
      </div>
      <div className="group-container">
        <SearchDropDown
          title={"Groups"}
          type="checkbox"
          max={5}
          data={azureGroupsBasic}
          setUserGroupIds={setUserGroupIds}
          heading={"Update Groups"}
          group={"groups"}
          loading={fetchingAzureGroups}
        />
        <div className="button-style-ip">
          <Button fill="fill" onclick={handleUpdate}>
            Update
          </Button>
        </div>
      </div>
    </AdminCardStyles>
  );
};

export default UserManagementCard;
