import { styled } from "styled-components";

export const LinkCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 105px;
  border-radius: 0.25rem;
  border: 1px solid #96c115;
  font-size: 12px;
  color: #3e3e3e;
  position: absolute;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;

  .list-item {
    cursor: pointer;
    &:hover {
      background-color: #e5e8e9;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    border-radius: 0
}
`;
