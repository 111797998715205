import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "../../atoms";
import { FormStyle, FieldStyle } from "./Form.style";
import SetOnHold from "../../molecules/SetOnHold";
import {
  getHoldReasons,
  setHoldReasonPost,
  setUnresolveIssue,
  resolveIssuePost,
} from "../../../Redux/Zte/Issue/IssueAction";

const UpdateIssueStatus = ({ status }) => {
  const { issueId } = useParams();
  const dispatch = useDispatch();
  const [selectedReason, setSelectedReason] = useState();
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    dispatch(getHoldReasons());
  }, [dispatch]);

  const holdReasons = useSelector((state) => state.issues.holdReasons);
  const holdReasonPayload = {
    historyId: issueId,
    onHoldReasonId: selectedReason,
    onHoldDate: selectedDate,
  };
  const historyIdPayload = {
    historyId: issueId,
    resolutionDate: selectedDate,
  };
  const handleSubmit = () => {
    dispatch(setHoldReasonPost(holdReasonPayload));
  };
  const handleResolve = () => {
    dispatch(resolveIssuePost(historyIdPayload));
  };
  const handleUnresolve = () => {
    dispatch(setUnresolveIssue(historyIdPayload));
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <FormStyle>
      <div className="issue-modal">
        {status === 1 ? (
          <div>
            <div className="button-style">
              <h6>Set On Hold</h6>
              <SetOnHold
                holdReasons={holdReasons}
                setSelectedReason={setSelectedReason}
                handleSubmit={handleSubmit}
              />
              <FieldStyle>
                <label>Resolution / On Hold Date</label>
                <input
                  className="input-container"
                  type="datetime-local"
                  onChange={handleDateChange}
                  id="dateAdded"
                />
              </FieldStyle>
            </div>
            <div className="buttons-div">
              <div>
                <Button fill="fill" onclick={handleResolve}>
                  Resolve
                </Button>
              </div>
              <div className="button-div right">
                <Button fill="fill" onclick={handleSubmit}>
                  Update
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <FieldStyle>
              <label>Resolution Date</label>
              <input
                className="input-container"
                type="datetime-local"
                onChange={handleDateChange}
                id="dateAdded"
              />
            </FieldStyle>
            <div className="buttons-div">
              <div className="buttons-container">
                <Button fill="fill" onclick={handleUnresolve}>
                  Unresolve
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </FormStyle>
  );
};

export default UpdateIssueStatus;
