export const GET_USER_ISSUE_SUMMARY_FAILURE = "GET_USER_ISSUE_SUMMARY_FAILURE";
export const GET_USER_ISSUE_SUMMARY_SUCCESS = "GET_USER_ISSUE_SUMMARY_SUCCESS";
export const GET_USER_ISSUE_SUMMARY_START = "GET_USER_ISSUE_SUMMARY_START";
export const GET_ISSUE_TYPE_SUMMARY_FAILURE = "GET_ISSUE_TYPE_SUMMARY_FAILURE";
export const GET_ISSUE_TYPE_SUMMARY_SUCCESS = "GET_ISSUE_TYPE_SUMMARY_SUCCESS";
export const GET_ISSUE_TYPE_SUMMARY_START = "GET_ISSUE_TYPE_SUMMARY_START";
export const GET_ISSUE_DETAIL_SUMMARY_FAILURE = "GET_ISSUE_DETAIL_SUMMARY_FAILURE";
export const GET_ISSUE_DETAIL_SUMMARY_SUCCESS = "GET_ISSUE_DETAIL_SUMMARY_SUCCESS";
export const GET_ISSUE_DETAIL_SUMMARY_START = "GET_ISSUE_DETAIL_SUMMARY_START";
export const GET_ISSUE_TYPE_SUMMARY_DURATION_FAILURE = "GET_ISSUE_TYPE_SUMMARY_DURATION_FAILURE";
export const GET_ISSUE_TYPE_SUMMARY_DURATION_SUCCESS = "GET_ISSUE_TYPE_SUMMARY_DURATION_SUCCESS";
export const GET_ISSUE_TYPE_SUMMARY_DURATION_START = "GET_ISSUE_TYPE_SUMMARY_DURATION_START";
export const GET_ISSUE_DETAIL_SUMMARY_DURATION_FAILURE = "GET_ISSUE_DETAIL_SUMMARY_DURATION_FAILURE";
export const GET_ISSUE_DETAIL_SUMMARY_DURATION_SUCCESS = "GET_ISSUE_DETAIL_SUMMARY_DURATION_SUCCESS";
export const GET_ISSUE_DETAIL_SUMMARY_DURATION_START = "GET_ISSUE_DETAIL_SUMMARY_DURATION_START"
export const GET_ISSUE_TYPE_SUMMARY_RESOLUTION_FAILURE = "GET_ISSUE_TYPE_SUMMARY_RESOLUTION_FAILURE";
export const GET_ISSUE_TYPE_SUMMARY_RESOLUTION_SUCCESS = "GET_ISSUE_TYPE_SUMMARY_RESOLUTION_SUCCESS";
export const GET_ISSUE_TYPE_SUMMARY_RESOLUTION_START = "GET_ISSUE_TYPE_SUMMARY_RESOLUTION_START";
export const GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_FAILURE = "GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_FAILURE";
export const GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_SUCCESS = "GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_SUCCESS";
export const GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_START = "GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_START";
export const GET_ISSUE_TYPE_BY_COUNTRY_FAILURE = "GET_ISSUE_TYPE_BY_COUNTRY_FAILURE";
export const GET_ISSUE_TYPE_BY_COUNTRY_SUCCESS = "GET_ISSUE_TYPE_BY_COUNTRY_SUCCESS";
export const GET_ISSUE_TYPE_BY_COUNTRY_START = "GET_ISSUE_TYPE_BY_COUNTRY_START";
export const GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_FAILURE = "GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_FAILURE";
export const GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_SUCCESS = "GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_SUCCESS";
export const GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_START = "GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_START";
export const GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_FAILURE = "GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_FAILURE";
export const GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_SUCCESS = "GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_SUCCESS";
export const GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_START = "GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_START";
export const GET_AVERAGE_USER_SERVICE_EXP_FAILURE = "GET_AVERAGE_USER_SERVICE_EXP_FAILURE";
export const GET_AVERAGE_USER_SERVICE_EXP_SUCCESS = "GET_AVERAGE_USER_SERVICE_EXP_SUCCESS";
export const GET_AVERAGE_USER_SERVICE_EXP_START = "GET_AVERAGE_USER_SERVICE_EXP_START";
export const GET_AVERAGE_USER_SERVICE_EXP_REGION_FAILURE = "GET_AVERAGE_USER_SERVICE_EXP_REGION_FAILURE";
export const GET_AVERAGE_USER_SERVICE_EXP_REGION_SUCCESS = "GET_AVERAGE_USER_SERVICE_EXP_REGION_SUCCESS";
export const GET_AVERAGE_USER_SERVICE_EXP_REGION_START = "GET_AVERAGE_USER_SERVICE_EXP_REGION_START";
export const GET_AVERAGE_OVERALL_RATING_FAILURE = "GET_AVERAGE_OVERALL_RATING_FAILURE";
export const GET_AVERAGE_OVERALL_RATING_SUCCESS = "GET_AVERAGE_OVERALL_RATING_SUCCESS";
export const GET_AVERAGE_OVERALL_RATING_START = "GET_AVERAGE_OVERALL_RATING_START";
export const GET_AVERAGE_OVERALL_RATING_COUNTRY_FAILURE = "GET_AVERAGE_OVERALL_RATING_COUNTRY_FAILURE";
export const GET_AVERAGE_OVERALL_RATING_COUNTRY_SUCCESS = "GET_AVERAGE_OVERALL_RATING_COUNTRY_SUCCESS";
export const GET_AVERAGE_OVERALL_RATING_COUNTRY_START = "GET_AVERAGE_OVERALL_RATING_COUNTRY_START";
export const GET_AVERAGE_OVERALL_RATING_REGION_FAILURE = "GET_AVERAGE_OVERALL_RATING_REGION_FAILURE";
export const GET_AVERAGE_OVERALL_RATING_REGION_SUCCESS = "GET_AVERAGE_OVERALL_RATING_REGION_SUCCESS";
export const GET_AVERAGE_OVERALL_RATING_REGION_START = "GET_AVERAGE_OVERALL_RATING_REGION_START";
export const GET_AVERAGE_GENERIC_EOU_FAILURE = "GET_AVERAGE_GENERIC_EOU_FAILURE";
export const GET_AVERAGE_GENERIC_EOU_SUCCESS = "GET_AVERAGE_GENERIC_EOU_SUCCESS";
export const GET_AVERAGE_GENERIC_EOU_START = "GET_AVERAGE_GENERIC_EOU_START";
export const GET_AVERAGE_GENERIC_EOU_COUNTRY_FAILURE = "GET_AVERAGE_GENERIC_EOU_COUNTRY_FAILURE";
export const GET_AVERAGE_GENERIC_EOU_COUNTRY_SUCCESS = "GET_AVERAGE_GENERIC_EOU_COUNTRY_SUCCESS";
export const GET_AVERAGE_GENERIC_EOU_COUNTRY_START = "GET_AVERAGE_GENERIC_EOU_COUNTRY_START";
export const GET_GEO_LOCATION_FAILURE = "GET_GEO_LOCATION_FAILURE"
export const GET_GEO_LOCATION_SUCCESS = "GET_GEO_LOCATION_SUCCESS"
export const GET_GEO_LOCATION_START = "GET_GEO_LOCATION_START"