import React from "react";
import { SelectStyle } from "./Atoms.Style";

const Select = ({
	data,
	name,
	handleChange,
	placeholder,
	formData,
	dataObj,
	extra,
	defaulValue,
	initial
}) => {
	return (
		<SelectStyle
			aria-label={name}
			name={name}
			value={formData?.name}
			onChange={handleChange}
			defaultValue={defaulValue}>
			{placeholder ? (
				<option value={null} disabled>
					{placeholder}
				</option>
			) : null}
			{dataObj
				? dataObj?.map((option, index) => (
						<option
							key={index}
							value={initial ? option?.name : option?.name.toLowerCase()}
							id={option.id}
							selected={defaulValue && option.name === defaulValue}>
							{option?.name}
						</option>
				  ))
				: data?.map((option, index) => (
						<option
							key={index}
							value={
								initial
									? option
									: (typeof option === "string" && option?.toLowerCase()) ||
									  option
							}
							selected={defaulValue && option === defaulValue}>
							{option}
						</option>
				  ))}
			{extra ? (
				<option value={extra?.toLowerCase()} id="">
					{extra}
				</option>
			) : null}
		</SelectStyle>
	);
};

export default Select;
