import React, { useEffect, useRef, useState } from 'react'
import { InPageNaVWrapper } from './Molecules.style';

const InPageNavigation = ({sectionsRef, sections}) => {
     const markerRef = useRef(null);
			const [selectedTab, setSelectedTab] = useState(null);
			const itemsRef = useRef([]);
			const handleClick = (index) => (event) => {
				event.preventDefault();
				setSelectedTab(index);
				if (sectionsRef.current[index]) {
					sectionsRef.current[index].scrollIntoView({ behavior: "smooth" });
				}
				const top = itemsRef.current[index]?.offsetTop;
				const height = itemsRef.current[index]?.offsetHeight;

				if (markerRef.current) {
					markerRef.current.style.top = `${top}px`;
					markerRef.current.style.height = `${height}px`;
				}
			};
            useEffect(() => {
							if (selectedTab !== null) {
								const top = itemsRef.current[selectedTab]?.offsetTop;
								const height = itemsRef.current[selectedTab]?.offsetHeight;

								if (markerRef.current) {
									markerRef.current.style.top = `${top}px`;
									markerRef.current.style.height = `${height}px`;
								}
							}
						}, [selectedTab]);
  return (
		<InPageNaVWrapper className="js-items">
			{/* <p>Find Chart</p> */}
			<ul ref={itemsRef}>
				{sections?.map((link, index) => (
					<li
						key={index}
						onClick={handleClick(index)}
						ref={(el) => {
							if (el) {
								itemsRef.current[index] = el;
							}
						}}
						className={selectedTab === index ? "-selected" : ""}>
						{link}
					</li>
				))}
				<li className="marker" ref={markerRef}></li>
			</ul>
		</InPageNaVWrapper>
	);
}

export default InPageNavigation