import Styled from "styled-components";

export const SearchFilterStyle = Styled.div`
padding-bottom: 1.2rem;
padding-left: 1px;
.container{
    display: flex;
    position: relative;
}
label {
    font-size: 12px;
    font-weight: 700;
    color: #62646d;
    margin-top: -0.5px;
    padding-left: 8px;
}
input {
    border-radius: 2px;
    border: 1px solid #96c115;
    padding-left: 8px;
    color: #3e3e3e;
    height: 40px;
    width: 180px;
    &:focus {
        outline: none;
        border: 2px solid #96c115;
    }
}
input,
input::placeholder {
    font-size: 12px;
}

.icon-style {
    margin-left: -2.7rem;
    padding-top:10px;
}

`;
