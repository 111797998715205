export const initialDataEntryState = {
	threatSource: {
		id: "",
		name: "",
	},
	// region: {
	// 	id: 4,
	// 	name: "default",
	// },
	category: {
		id: "",
		name: "",
	},
	newCategory: "",
	categoryDescription: "",
	date: "",
	frequency: "",
};

export const threats = [
	{
		id: 1,
		name: "internet",
	},
	{
		id: 2,
		name: "email",
	},
	{ id: 3, name: "endpoint" },
];

export const threatSource = {
	categories: {
		internet: [
			{
				id: 1,
				name: "internet-category-one",
			},
			{
				id: 2,
				name: "internet-category-two",
			},
			{
				id: 3,
				name: "internet-category-three",
			},
		],
		email: [
			{
				id: 1,
				name: "email-category-one",
			},
			{
				id: 2,
				name: "email-category-two",
			},
			{
				id: 3,
				name: "email-category-three",
			},
		],
		endpoint: [
			{
				id: 1,
				name: "ussd-category-one",
			},
			{
				id: 2,
				name: "ussd-category-two",
			},
			{
				id: 3,
				name: "ussd-category-three",
			},
		],
	},
	regions: {
		1: [
			{ id: 1, name: "usa" },
			{ id: 2, name: "tokyo" },
			{ id: 3, name: "china" },
		],
		2: [
			{ id: 1, name: "nigeria" },
			{ id: 2, name: "zambia" },
			{ id: 3, name: "Ghana" },
		],
	},
};
