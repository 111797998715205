import React, { useCallback, useEffect, useRef, useState } from "react";
import { ModalFormWrapper, UsageWrapper } from "./Pages.style";
import {
  getAverageConnectionLength,
  getUsersActivePerService,
  getUsersActiveVsAssigned,
  getUsersAssignedPerService,
  getActiveUsersByMonth,
  getWeeklyConcurrentConnection,
  getDailyConcurrentConnection,
  getServices,
  getCountryActiveMonthlyUsers,
  getActiveUserHistoryByService,
  getActiveUserHistoryByRegion,
  getHourlyConnectionTrend,
} from "../Redux/Zte/Usage/UsageActions";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  formatBarData,
  formatStackedBarChart,
  formatDailyConcurrentBars,
  formatStackedMonthlyUsers,
  formatWeeklyConcurrentLines,
  formatCountryMonthly,
  formatLine,
  formatRegionHistory,
  formatConnectionsData,
  formatConnectionsMonths,
} from "../utils/formatChartData";
import { Modal } from "@mui/material";
import { ReactComponent as CloseBtn } from "../assets/icons/close.svg";
import { Select } from "../components/atoms";
import { generateYears } from "../utils/getYears";
import InPageNavigation from "../components/molecules/InPageNavigation";
import ZtnaLineChart from "../components/organism/lineCharts/ZtnaLineCharts";

const UsageDashboard = () => {
  const dispatch = useDispatch();
  const {
    activeUsers,
    assignedUsers,
    activeVsAssignedUsers,
    activeConnectionLength,
    monthlyActiveUsers,
    weeklyConcurrent,
    dailyConcurrent,
    services,
    countryMonthlyActiveUser,
    serviceHistory,
    regionHistory,
    hourlyConnectionTrendData,
  } = useSelector((state) => state.usage);

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const [selectedYear, setSelectedYear] = useState(2024);
  const [dailyConcurrentYear, setDailyConcurrentYear] = useState(2024);

  const [activeUsersCountryYear, setActiveUsersCountryYear] = useState(2023);
  const handleClose = () => {
    setOpen(false);
    setModalData("");
  };
  const active = formatBarData(
    activeUsers?.data,
    `Active Users by App (${activeUsers?.month || " "})`,
    "Service",
    "Number of active users",
    "name",
    "count",
    setModalData,
    "active"
  );
  const assigned = formatBarData(
    assignedUsers,
    "Assigned Users by App",
    "Service",
    "Number of assigned users",
    "name",
    "count",
    setModalData,
    "assigned"
  );
  const assignedVsActive = formatStackedBarChart(
    activeVsAssignedUsers,
    "Assigned vs Active Users",
    "Number of users",
    "User type",
    "serviceName",
    "Assigned Users",
    "Active Users",
    "assignedUsers",
    "activeUsers",
    setModalData,
    "assignedVsActive"
  );
  const activeConnection = formatStackedBarChart(
    activeConnectionLength,
    "Average Session Time",
    "Time",
    "Month",
    "dateIdentifier",
    "Active Time",
    "Inactive Time",
    "activeTime",
    "inactiveTime"
  );
  const initialYear = 2023;
  const years = generateYears(initialYear);
  useEffect(() => {
    dispatch(getUsersActivePerService());
    dispatch(getUsersAssignedPerService());
    dispatch(getUsersActiveVsAssigned());
    dispatch(getAverageConnectionLength());
    dispatch(getServices());
    dispatch(getActiveUsersByMonth());
    dispatch(getActiveUserHistoryByService());
    dispatch(getActiveUserHistoryByRegion());
    dispatch(getHourlyConnectionTrend());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getWeeklyConcurrentConnection(selectedYear));
  }, [dispatch, selectedYear]);
  useEffect(() => {
    dispatch(getDailyConcurrentConnection(dailyConcurrentYear));
  }, [dispatch, dailyConcurrentYear]);
  useEffect(() => {
    dispatch(getCountryActiveMonthlyUsers(activeUsersCountryYear));
  }, [dispatch, activeUsersCountryYear]);
  const concurrenceOptions = ["Average", "Minimum", "Maximum"];
  const activeUserServiceHistory = formatLine(
    serviceHistory,
    "Service Active User Trend"
  );
  // const activeUserRegionHistory = formatRegionHistory(
  // 	regionHistory,
  // 	"Region Active User Trend",
  // 	"ARP"
  // );
  const [selectedService, setSelectedService] = useState({
    monthlyActive: "",
    monthlyCountryActive: "",
    weekDay: "",
    regionHistory: "",
  });
  const [concurrence, setConcurrence] = useState(concurrenceOptions[0]);
  const [overallconcurrence, setOverallConcurrence] = useState(
    concurrenceOptions[0]
  );

  const concurrenceFilters = (option) => {
    switch (option?.toLowerCase()) {
      case "minimum":
        return ["MinConcurrent", "MinUnique"];
      case "maximum":
        return ["MaxConcurrent", "MaxUnique"];
      default:
        return ["Concurrent", "Unique"];
    }
  };

  const [selectedConcurrence, setSelectedConcurrence] = useState("Choose...");
  useEffect(() => {
    setSelectedConcurrence(concurrenceFilters(concurrence)[0]);
  }, [concurrence]);
  const connectionMonthData = formatConnectionsMonths(
    hourlyConnectionTrendData
  );
  const [connectionMonth, setConnectionMonth] = useState(0);
  const connectionTypes = ["concurrent connections", "unique connections"];
  const [connectionType, setConnectionType] = useState(
    "connectionCountAverage"
  );

  const handleFilterChange = (e, filterType) => {
    return filterType === "services"
      ? setSelectedService({
          ...selectedService,
          [e.target.name]: e.target.value,
        })
      : filterType === "selectedConcurrence"
      ? setSelectedConcurrence(e.target.value)
      : filterType === "concurrence"
      ? setConcurrence(e.target.value)
      : filterType === "overallconcurrence"
      ? setOverallConcurrence(e.target.value)
      : filterType === "activeUsersCountryYear"
      ? setActiveUsersCountryYear(e.target.value)
      : filterType === "dailyConcurrentYear"
      ? setDailyConcurrentYear(e.target.value)
      : filterType === "connectionType"
      ? setConnectionMonth(e.target.value)
      : setSelectedYear(e.target.value);
  };
  const handleMonthChange = (e) => {
    for (let i in connectionMonthData) {
      if (
        connectionMonthData[i].name.toUpperCase() ===
        e.target.value.toUpperCase()
      ) {
        setConnectionMonth(connectionMonthData[i].id);
      }
    }
  };
  const handleConnectionType = (e) => {
    if (e.target.value === "concurrent connections") {
      setConnectionType("connectionCountAverage");
    } else {
      setConnectionType("uniqueCountAverage");
    }
  };
  const concurrentByDay = useCallback(() => {
    return formatDailyConcurrentBars(
      dailyConcurrent,
      "Week Day Trend",
      setModalData,
      "dailyConcurrent",
      selectedConcurrence
    );
  }, [selectedConcurrence, dailyConcurrent]);
  const concurrentConnections = useCallback(() => {
    return formatWeeklyConcurrentLines(
      weeklyConcurrent,
      "Overall Trend",
      undefined,
      undefined,
      overallconcurrence === "maximum"
        ? "maxConcurrent"
        : overallconcurrence === "minimum"
        ? "minConcurrent"
        : "concurrent",
      overallconcurrence === "maximum"
        ? "maxUnique"
        : overallconcurrence === "minimum"
        ? "minUnique"
        : "unique"
    );
  }, [overallconcurrence, weeklyConcurrent]);
  const monthlyActive = useCallback(() => {
    return formatStackedMonthlyUsers(
      monthlyActiveUsers,
      selectedService.monthlyActive,
      "Regional Active users by App"
    );
  }, [monthlyActiveUsers, selectedService.monthlyActive]);
  const countryMonthlyActive = useCallback(() => {
    return formatCountryMonthly(
      countryMonthlyActiveUser,
      selectedService.monthlyCountryActive,
      "Country Active users by App "
    );
  }, [countryMonthlyActiveUser, selectedService.monthlyCountryActive]);
  const activeUserRegionHistory = useCallback(() => {
    return formatRegionHistory(
      regionHistory,
      "Regional Active users by App",
      selectedService.regionHistory
    );
  }, [regionHistory, selectedService.regionHistory]);
  useEffect(() => {
    setSelectedService({
      monthlyActive: services[0],
      monthlyCountryActive: services[0],
      weekDay: concurrenceOptions[0],
      regionHistory: services[0],
    });
    // eslint-disable-next-line 
  }, [services]);
  const sectionsRef = useRef([]);
  const sections = [
    "Assigned Users by App",
    "Active Users by App",
    "Assigned vs Active Users",
    "Overall Trend",
    "Regional Active users by App",
    "Country Active users by App ",
    "Week Day Trend",
    "Hourly Trend",
    "Average Session Time",
    "Service Active User Trend",
    "Regional Active users by App",
  ];

  return (
    <>
      <UsageWrapper onClick={() => modalData && setOpen(true)}>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[0] = el)}
        >
          <HighchartsReact highcharts={Highcharts} options={assigned[1]} />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[1] = el)}
        >
          <HighchartsReact highcharts={Highcharts} options={active[1]} />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[2] = el)}
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={assignedVsActive[1]}
          />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[3] = el)}
        >
          <div className="filter-wrapper">
            <Select
              name="overallconcurrence"
              data={concurrenceOptions}
              formData={overallconcurrence}
              handleChange={(e) => handleFilterChange(e, "overallconcurrence")}
              placeholder={"Choose..."}
              defaulValue={overallconcurrence}
            />
            <Select
              name="year"
              data={years}
              formData={selectedYear}
              handleChange={(e) => handleFilterChange(e, "year")}
              placeholder={"Choose a year"}
              defaulValue={selectedYear}
            />
          </div>

          <HighchartsReact
            highcharts={Highcharts}
            options={concurrentConnections()}
          />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[4] = el)}
        >
          <div className="filter-wrapper">
            <Select
              name="monthlyActive"
              data={services}
              formData={selectedService}
              handleChange={(e) => handleFilterChange(e, "services")}
              placeholder={"Choose a service"}
              defaulValue={selectedService.monthlyActive}
            />
          </div>
          <HighchartsReact highcharts={Highcharts} options={monthlyActive()} />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[5] = el)}
        >
          <div className="filter-wrapper">
            <Select
              name="monthlyCountryActive"
              data={services}
              formData={selectedService}
              handleChange={(e) => handleFilterChange(e, "services")}
              placeholder={"Choose a service"}
              defaulValue={selectedService.monthlyCountryActive}
              initial
            />
            <Select
              name="year"
              data={years}
              formData={activeUsersCountryYear}
              handleChange={(e) =>
                handleFilterChange(e, "activeUsersCountryYear")
              }
              placeholder={"Choose a year"}
              defaulValue={activeUsersCountryYear}
            />
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={countryMonthlyActive()}
          />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[6] = el)}
        >
          <div className="filter-wrapper">
            <Select
              name="weekDayConcurrence"
              data={concurrenceFilters(concurrence)}
              formData={selectedConcurrence}
              handleChange={(e) => handleFilterChange(e, "selectedConcurrence")}
              placeholder={"Choose..."}
              defaulValue={selectedConcurrence}
            />
            <Select
              name="weekDay"
              data={concurrenceOptions}
              formData={concurrence}
              handleChange={(e) => handleFilterChange(e, "concurrence")}
              placeholder={"Choose..."}
              defaulValue={concurrence}
            />
            <Select
              name="year"
              data={years}
              formData={dailyConcurrentYear}
              handleChange={(e) => handleFilterChange(e, "dailyConcurrentYear")}
              placeholder={"Choose a year"}
              defaulValue={dailyConcurrentYear}
            />
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={concurrentByDay()[1]}
          />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[7] = el)}
        >
          <div className="filter-wrapper">
            <Select
              name="overallconcurrence"
              dataObj={connectionMonthData}
              handleChange={(e) => handleMonthChange(e)}
              placeholder={"Choose month"}
              defaulValue={overallconcurrence}
            />
            <Select
              name="year"
              data={connectionTypes}
              handleChange={(e) => handleConnectionType(e)}
              placeholder={"Choose connection type"}
              defaulValue={connectionTypes[0]}
            />
          </div>

          <p className="title-text">Hourly Trend</p>

          <ZtnaLineChart
            data={formatConnectionsData(
              hourlyConnectionTrendData[connectionMonth],
              connectionType
            )}
            yAxis={"Count"}
            xAxis={[
              "0:00",
              "1:00",
              "2:00",
              "3:00",
              "4:00",
              "5:00",
              "6:00",
              "7:00",
              "8:00",
              "9:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ]}
          />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[8] = el)}
        >
          <HighchartsReact highcharts={Highcharts} options={activeConnection} />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[9] = el)}
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={activeUserServiceHistory}
          />
        </div>
        <div
          className="chart-wrapper"
          ref={(el) => (sectionsRef.current[10] = el)}
        >
          <div className="filter-wrapper">
            <Select
              name="regionHistory"
              data={services}
              formData={selectedService}
              handleChange={(e) => handleFilterChange(e, "services")}
              placeholder={"Choose a service"}
              defaulValue={selectedService.regionHistory}
              initial
            />
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={activeUserRegionHistory()}
          />
        </div>
      </UsageWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalFormWrapper large="true">
          <div className="top">
            <div className="close">
              <CloseBtn onClick={handleClose} />
            </div>
          </div>
          <div className="chart-wrapper">
            {modalData === "dailyConcurrent" ? (
              <div className="filter-wrapper">
                <Select
                  name="weekDayConcurrence"
                  data={concurrenceFilters(concurrence)}
                  formData={selectedConcurrence}
                  handleChange={(e) =>
                    handleFilterChange(e, "selectedConcurrence")
                  }
                  placeholder={"Choose..."}
                  defaulValue={selectedConcurrence}
                />
                <Select
                  name="weekDay"
                  data={concurrenceOptions}
                  formData={concurrence}
                  handleChange={(e) => {
                    handleFilterChange(e, "concurrence");
                  }}
                  placeholder={"Choose..."}
                  defaulValue={concurrence}
                />
                <Select
                  name="year"
                  data={years}
                  formData={dailyConcurrentYear}
                  handleChange={(e) =>
                    handleFilterChange(e, "dailyConcurrentYear")
                  }
                  placeholder={"Choose a year"}
                  defaulValue={dailyConcurrentYear}
                />
              </div>
            ) : null}
            <HighchartsReact
              highcharts={Highcharts}
              options={
                modalData === "active"
                  ? active[0]
                  : modalData === "assigned"
                  ? assigned[0]
                  : modalData === "dailyConcurrent"
                  ? concurrentByDay()[0]
                  : assignedVsActive[0]
              }
            />
          </div>
        </ModalFormWrapper>
      </Modal>
      <InPageNavigation sectionsRef={sectionsRef} sections={sections} />
    </>
  );
};

export default UsageDashboard;
