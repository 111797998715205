import RatingsCard from "./RatingsCard";
import ProgressBar from "./ProgressBar";
import { Button } from "../../atoms";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { postRateUserServicesExp } from ".././../../Redux/Zte/ZteUsers/ZteUsersActions";

const ServiceQuestions = ({ services }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [stepService, setStepService] = useState(0);
  const [yesValue, setYesValue] = useState(0);
  const [usageScoreQ5, setUsageScoreQ5] = useState(null);
  const [stabilityScoreQ6, setStabilityScoreQ6] = useState(null);
  const [nextVisible, setNextVisible] = useState(false);
  const [previousVisible, setPreviousVisible] = useState(true);

  let appName = services[stepService]?.name;

  const previous = (falseValue) => {
    step >= 2 && setStep((step) => step - 1);
    falseValue && setYesValue(0);
    setNextVisible(false);
  };
  const next = () => {
    step <= 5 && setStep((step) => step + 1);
  };
  const progress = (stepService + 1) / services.length;
  const percent = progress * 100;

  const usageFrequency = [
    { title: "1-5 days a month", value: 1 },
    { title: "6-10 days a month", value: 2 },
    { title: "11-15 days a month", value: 3 },
    { title: "16 or more days a month", value: 4 },
  ];
  const disconnectionFrequency = [
    { title: "1-5", value: 5 },
    { title: "6-10", value: 4 },
    { title: "11-15", value: 3 },
    { title: "16-20", value: 2 },
    { title: "20 or more", value: 1 },
  ];

  const displayNextService = () => {
    stepService <= services.length &&
      setStepService((stepService) => stepService + 1);
    setStep(1);
    setNextVisible(false);
    setYesValue(0);
    setPreviousVisible(true);
    setUsageScoreQ5(null)
    setStabilityScoreQ6(null)
  };

  const serviceRating = {
    serviceId: services[stepService]?.ztnaServiceId,
    usageScoreQ5: usageScoreQ5,
    stabilityScoreQ6: stabilityScoreQ6,
  };
  const handleSubmitService = () => {
    dispatch(postRateUserServicesExp(serviceRating));
    setNextVisible(true);
    setPreviousVisible(false);
  };


  switch (step) {
    case 1:
      return (
        <>
          <ProgressBar percent={percent} />
          <p className="heading">Rate {appName} Service</p>
          <RatingsCard
            question={`How often do you connect to ${appName}`}
            type="select"
            options={usageFrequency}
            score={usageScoreQ5}
            setScore={setUsageScoreQ5}
            questionType="usage"
          />
          <div className="buttons-container">
            <div className="button-right">
              <Button fill="fill" onclick={next} disabled={!usageScoreQ5}>
                Next
              </Button>
            </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <ProgressBar percent={percent} />
          <RatingsCard
            question={`Did you experience any unexpected disconnections using ${appName} last month? (Yes/No)`}
            type="select"
            options={disconnectionFrequency}
            yesValue={yesValue}
            setYesValue={setYesValue}
            next={next}
            step={step}
            setStep={setStep}
            handleSubmitQuestions={handleSubmitService}
            score={stabilityScoreQ6}
            setScore={setStabilityScoreQ6}
          />
          <div className="buttons-container">
            {previousVisible === true && (
              <Button fill="fill" onclick={previous}>
                Previous
              </Button>
            )}

            {nextVisible === true && stepService <= services.length - 2 && (
              <div className="button-right">
                <Button fill="fill" onclick={displayNextService}>
                  Rate Next Service
                </Button>
              </div>
            )}
          </div>
        </>
      );
    case 3:
      return (
        <>
          <ProgressBar percent={percent} />
        </>
      );
    default:
  }
};

export default ServiceQuestions;
