
const {
	REACT_APP_CLIENT_ID,
	REACT_APP_INSTANCE,
	REACT_APP_AUDIENCE,
	REACT_APP_TENANT_ID,
} = process.env;
export const msalConfig = {
	auth: {
		clientId: REACT_APP_CLIENT_ID,
		authority: `${REACT_APP_INSTANCE}${REACT_APP_TENANT_ID}`,
		//identityInstance: `${REACT_APP_INSTANCE}${REACT_APP_AUDIENCE}`,
		//redirectUri: "/charts",
	},
	cache: {
		cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
};
