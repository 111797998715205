import styled from "styled-components";

export const ButtonStyle = styled.button`
	background-color: ${(props) => (props.fill ? "#96c115" : "#fff")};
	color: ${(props) => (props.fill ? "#fff" : "#96c115")};
	border: ${(props) => (props.fill ? "1px solid #96c115" : "1px solid #fff")};
	padding: 0.75rem 1.375rem;
	border-radius: 5px;
	display: flex;
	outline: transparent;
	cursor: pointer;
	transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
		border-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
		background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-weight: 700;
	font-size: 0.75rem;
	&:hover {
		background-color: ${(props) => (props.fill ? "#7ca212" : null)};
		border-color: #7ca212;
	}
`;

export const SelectStyle = styled.select`
	text-transform: capitalize;
`;

export const LoaderStyle = styled.div`
	width: 100%;
	height: 100%;
	background-color: #fff;
	position: relative;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const PaginationStyle = styled.ul`
	display: flex;
	list-style-type: none;
	padding: 0;
	justify-content: center;
	.pagination-item {
		padding: 0 12px;
		height: 2rem;
		text-align: center;
		margin: auto 4px;
		color: rgba(0, 0, 0, 0.87);
		display: flex;
		box-sizing: border-box;
		align-items: center;
		letter-spacing: 0.01071em;
		border-radius: 1rem;
		line-height: 1.43;
		font-size: 13px;
		min-width: 2rem;
	}

	.dots {
		&:hover {
			background-color: rgba(0, 0, 0, 0.04);
			cursor: default;
		}
	}
	/* &:hover {
		background-color: rgba(0, 0, 0, 0.04);
		cursor: pointer;
	} */

	.selected {
		background-color: rgba(0, 0, 0, 0.15);
	}
	.right,
	.left {
		width: 2rem;
		height: 2rem;
		line-height: 2rem;
		border-radius: 50%;
		display: inline-block;
		&:hover {
			background-color: rgba(0, 0, 0, 0.04);
			cursor: default;
		}
	}
`;

export const ToggleWrapperStyle = styled.div`
	display: inline-flex;
	/* border: 1px solid #cccccc; */
	border: 1px solid #b9d948;
	border-radius: 0.25rem;
`;

export const ToggleStyle = styled.p`
	background-color: ${(props) => props.bgColor || "#fff"};
	color: ${(props) => (props.bgColor ? "#fff" : "#574c4c")};
	padding: 0.5rem;
	line-height: 26px;
	text-transform: capitalize;
`;