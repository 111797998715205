import React, { useState, useEffect, useRef } from "react";
import Loader from "../../atoms/Loader";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatThreatEntriesForHighChart } from "../../../utils/formatChartData";
import { getThreatSourceEntries } from "../../../Redux/threats/ThreatDashboard/ThreatDashboardActions";
import { useDispatch, useSelector } from "react-redux";

const ThreatSourceLines = ({ setId, filter }) => {
	const [formattedData, setFormattedData] = useState();
	const dispatch = useDispatch();
	const { threatEntries, fetchingThreatEntries } = useSelector(
		(state) => state.threatDashboard
	);
 	const chartRef = useRef(null);

 useEffect(() => {
		if (chartRef.current) {
			// Ensure the chart updates when the container size changes
			chartRef.current.chart.reflow();
		}
 }, [chartRef]);

	useEffect(() => {
		dispatch(getThreatSourceEntries(filter.threatSourceDuration, filter.year));
	}, [filter, dispatch]);

	useEffect(() => {
		!fetchingThreatEntries &&
			threatEntries &&
			setFormattedData(formatThreatEntriesForHighChart(threatEntries, setId));
	}, [fetchingThreatEntries,threatEntries,setId]);
	return fetchingThreatEntries ? (
		<Loader />
	) : (
			<HighchartsReact highcharts={Highcharts} options={formattedData} />
	);
};

export default ThreatSourceLines;
