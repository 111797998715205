import styled from "styled-components";

export const FieldStyle = styled.div`
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
  label,
  .heading-style {
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    display: block;
    margin-bottom: 0.5rem;
    color: #7ca212;
    text-transform: uppercase;
    letter-spacing: 0.18px;
  }

  input,
  select,
  textarea {
    outline: transparent;
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #cccccc;
    line-height: 26px;
    padding: 12px 16px;
    margin: 0 0 16px;
    font-size: 16px;
    height: auto;
    width: 100%;
    &:focus {
      /* box-shadow: 0 0 5px #96c115; */
      box-shadow: 0 0 #7ca212 inset;
      border: 2px solid #7ca212;
    }
  }

  .search-icon {
    padding: 0 10px;
    margin-top: -16px;
  }
  .container {
    border: 1px solid #cccccc;
  }
  .content {
    background: #f6f6f6;
  }
  .select-btn {
    border-radius: 2px;
    justify-content: space-between;
    background: #f6f6f6;
    height: auto;
    width: 100%;
    line-height: 26px;
    padding: 12px 16px;
    font-size: 16px;
  }

  .select-btn,
  .options li,
  .options input {
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  .content .options {
    margin-top: 10px;
    max-height: 250px;
    overflow-y: auto;
  }

  .options li,
  .label-input {
    height: 50px;
    padding: 0 13px;
    font-size: 16px;
    border-radius: 5px;
  }

  .options li:hover,
  .label-input:hover {
    background: #d3d3d3;
  }
  .options {
    padding: 0 16px 16px;
  }

  .icon {
    position: relative;
    top: 38px;
    left: 94%;
  }

  .down-arrow {
    float: right;
  }
  .small-text {
    padding: 0 13px 13px;
    font-size: 16px;
  }
`;

export const FormStyle = styled.div`
  background-color: #fff;
  padding-bottom: 2rem;
  .threat-btn {
    float: right;
  }
  .issue-modal {
    display: flex;
    padding-top: 30px;
    justify-content: center;
  }
  .input-container {
    width: 280px;
  }

  .button-style {
    padding-bottom: 20px;
  }

  .buttons-div {
    display: flex;
    justify-content: center;
  }

  .right {
    padding-left: 20px;
  }

  h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    display: block;
    margin-bottom: 0.5rem;
    color: #7ca212;
    text-transform: uppercase;
    letter-spacing: 0.18px;
  }
  p {
    font-size: 0.875rem;
    color: #62646d;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;
