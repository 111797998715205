import { useEffect, useState } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import { Table } from "../../atoms";
import { structureData } from "../../../utils/FormatData";
import { useDispatch, useSelector } from "react-redux";
import { getAzureGroups } from "../../../Redux/Zte/ZteUsers/ZteUsersActions";
import SearchFilter from "../SearchFilter/SearchFilter";
import InformationModal from "../InformationModal";

const GroupInformationTable = () => {
  const headers = ["Name", "Created", "Added"];
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    dispatch(getAzureGroups(currentPage, searchQuery));
  }, [dispatch, currentPage, searchQuery]);

  const { allAzureAdGroups, fetchingAllAzureAdGroups } = useSelector(
    (state) => state.users
  );
  const handleRowClick = (id) => {
    setOpen(true);
    setSelectedData(id);
  };

  return (
    <AdminCardStyles>
      <SearchFilter setSearchQuery={setSearchQuery} placeholder="Name" />
      <div className="table-style">
        <Table
          headers={headers}
          data={structureData(
            "userManagement",
            allAzureAdGroups?.data?.results
          )}
          loading={fetchingAllAzureAdGroups}
          handleRowClick={handleRowClick}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={allAzureAdGroups?.data?.totalCount}
          pageSize={5}
          type="info"
        />
      </div>
      <InformationModal
        open={open}
        setOpen={setOpen}
        data={selectedData}
        type="group-info"
      />
    </AdminCardStyles>
  );
};

export default GroupInformationTable;
