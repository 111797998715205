import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../components/atoms";
import Layout from "../components/molecules/Layout";
import RatingsCard from "../components/molecules/RatingsCard/RatingsCard";
import ServiceQuestions from "../components/molecules/RatingsCard/ServiceQuestions";
import ProgressBar from "../components/molecules/RatingsCard/ProgressBar";
import {
  fetchGetRateServices,
  postRateUserGenericExp,
  // fetchUserRatedGeneric,
} from "../Redux/Zte/ZteUsers/ZteUsersActions";
const Ratings = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [easeOfConnectivityQ2, setEaseOfConnectivityQ2] = useState(null);
  const [clarityOfConnectivityQ3, setClarityOfConnectivityQ3] = useState(null);
  const [comment, setComment] = useState("");
  const [submitGeneric, setSubmitGeneric] = useState(false);
  const { userInfo, userRatedGeneric, rateServices, fetchingRateServices } = useSelector(
    (state) => state.users
  );
  const userId = userInfo?.basicInformation?.userId
  // useEffect(() => {
  //   if (userId) {
  //     dispatch(fetchUserRatedGeneric(userId));
  //   }
  // }, [dispatch, userId]);

  useEffect(() => {
    if (userRatedGeneric === true) {
      setStep(4);
    }
  }, [dispatch, userRatedGeneric]);
  const percent = (step / 3) * 100;

  const genericRating = {
    easeOfConnectivityQ2: easeOfConnectivityQ2,
    clarityOfConnectivityQ3: clarityOfConnectivityQ3,
    comment: comment,
  };

  const handleSubmitGeneric = () => {
    dispatch(postRateUserGenericExp(genericRating));
    setSubmitGeneric(true);
  };

  const previous = () => {
    step >= 2 && setStep((step) => step - 1);
  };
  const next = () => {
    step <= 5 && setStep((step) => step + 1);
  };

  useEffect(() => {
    dispatch(fetchGetRateServices(userId));
  }, [dispatch, userId]);

  const services = rateServices;

  switch (step) {
    case 1:
      return (
        <Layout>
          <ProgressBar percent={percent} />
          <RatingsCard
            question="How easy is it to connect to internal apps with the new technology compared to with VPN?"
            type="star"
            rating={easeOfConnectivityQ2}
            setRating={setEaseOfConnectivityQ2}
          />
          <div className="buttons-container">
            <div className="button-right">
              <Button
                fill="fill"
                onclick={next}
                disabled={!easeOfConnectivityQ2}
              >
                Next
              </Button>
            </div>
          </div>
        </Layout>
      );
    case 2:
      return (
        <Layout>
          <ProgressBar percent={percent} />
          <RatingsCard
            question="How clear are the steps for connecting to internals apps using this new technology?"
            type="star"
            rating={clarityOfConnectivityQ3}
            setRating={setClarityOfConnectivityQ3}
          />
          <div className="buttons-container">
            <Button fill="fill" onclick={previous}>
              Previous
            </Button>
            <div className="button-right">
              <Button
                fill="fill"
                onclick={next}
                disabled={!clarityOfConnectivityQ3}
              >
                Next
              </Button>
            </div>
          </div>
        </Layout>
      );
    case 3:
      return (
        <Layout>
          <ProgressBar percent={percent} />
          <RatingsCard
            question={`Other Comments`}
            type="comment"
            setComment={setComment}
            handleSubmit={handleSubmitGeneric}
            submit={submitGeneric}
          />
          <div className="buttons-container">
            {!submitGeneric && (
              <Button fill="fill" onclick={previous}>
                Previous
              </Button>
            )}
            {services?.length > 0 && (
              <div className="button-right">
                <Button fill="fill" onclick={next}>
                  Next
                </Button>
              </div>
            )}
          </div>
        </Layout>
      );
    case 4:
      return (
        <Layout>
          {rateServices?.length > 0 ? (
            <ServiceQuestions services={rateServices} />
          ) : fetchingRateServices === true ? (
            <RatingsCard type={"no-service"} value="Loading..." />
          ) : (
            <RatingsCard type={"no-service"} value="No Services to Rate" />
          )}
        </Layout>
      );
    default:
  }
};

export default Ratings;
