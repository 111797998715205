export const GET_ACTIVE_USERS_PER_SERVICE_START = "GET_ACTIVE_USERS_PER_SERVICE_START";
export const GET_ACTIVE_USERS_PER_SERVICE_SUCCESS = "GET_ACTIVE_USERS_PER_SERVICE_SUCCESS";
export const GET_ACTIVE_USERS_PER_SERVICE_FAILURE = "GET_ACTIVE_USERS_PER_SERVICE_FAILURE";
export const GET_ASSIGNED_USERS_PER_SERVICE_START = "GET_ASSIGNED_USERS_PER_SERVICE_START";
export const GET_ASSIGNED_USERS_PER_SERVICE_SUCCESS = "GET_ASSIGNED_USERS_PER_SERVICE_SUCCESS";
export const GET_ASSIGNED_USERS_PER_SERVICE_FAILURE = "GET_ASSIGNED_USERS_PER_SERVICE_FAILURE";
export const GET_ACTIVE_VS_ASSIGNED_USERS_START = "GET_ACTIVE_VS_ASSIGNED_USERS_START";
export const GET_ACTIVE_VS_ASSIGNED_USERS_SUCCESS = "GET_ACTIVE_VS_ASSIGNED_USERS_SUCCESS";
export const GET_ACTIVE_VS_ASSIGNED_USERS_FAILURE = "GET_ACTIVE_VS_ASSIGNED_USERS_FAILURE";
export const GET_ACTIVE_CONNECTION_LENGTH_SUCCESS = "GET_ACTIVE_CONNECTION_LENGTH_SUCCESS";
export const GET_ACTIVE_CONNECTION_LENGTH_FAILURE = "GET_ACTIVE_CONNECTION_LENGTH_FAILURE";
export const GET_ACTIVE_MONTHLY_USERS_START =
	"GET_ACTIVE_MONTHLY_USERS_START";
export const GET_ACTIVE_MONTHLY_USERS_SUCCESS =
	"GET_ACTIVE_MONTHLY_USERS_SUCCESS";
export const GET_ACTIVE_MONTHLY_USERS_FAILURE =
	"GET_ACTIVE_VS_ASSIGNED_USERS_FAILURE";
export const GET_WEEKLY_CONCURRENT_CONNECTION_START =
	"GET_WEEKLY_CONCURRENT_CONNECTION_START";
export const GET_WEEKLY_CONCURRENT_CONNECTION_SUCCESS =
	"GET_WEEKLY_CONCURRENT_CONNECTION_SUCCESS";
export const GET_WEEKLY_CONCURRENT_CONNECTION_FAILURE =
	"GET_ACTIVE_VS_ASSIGNED_USERS_FAILURE";
export const GET_DAILY_CONCURRENT_CONNECTION_START =
	"GET_DAILY_CONCURRENT_CONNECTION_START";
export const GET_DAILY_CONCURRENT_CONNECTION_SUCCESS =
	"GET_DAILY_CONCURRENT_CONNECTION_SUCCESS";
export const GET_DAILY_CONCURRENT_CONNECTION_FAILURE =
	"GET_DAILY_CONCURRENT_CONNECTION_FAILURE";
export const AVERAGE_ZTNA_USAGE_TIME_START =
	"AVERAGE_ZTNA_USAGE_TIME_START";
export const AVERAGE_ZTNA_USAGE_TIME_SUCCESS =
	"AVERAGE_ZTNA_USAGE_TIME_SUCCESS";
export const AVERAGE_ZTNA_USAGE_TIME_FAILURE = "AVERAGE_ZTNA_USAGE_TIME_FAILURE";
export const MONTHLY_ACTIVE_USERS_START = "MONTHLY_ACTIVE_USERS_START";
export const MONTHLY_ACTIVE_USERS_SUCCESS =
	"MONTHLY_ACTIVE_USERS_SUCCESS";
export const MONTHLY_ACTIVE_USERS_FAILURE = "MONTHLY_ACTIVE_USERS_FAILURE";
export const GET_COUNTRY_MONTHLY_ACTIVE_USERS_START =
	"GET_COUNTRY_MONTHLY_ACTIVE_USERS_START";
export const GET_COUNTRY_MONTHLY_ACTIVE_USERS_SUCCESS =
	"GET_COUNTRY_MONTHLY_ACTIVE_USERS_SUCCESS";
export const GET_COUNTRY_MONTHLY_ACTIVE_USERS_FAILURE =
	"GET_COUNTRY_MONTHLY_ACTIVE_USERS_FAILURE";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE";
export const GET_ACTIVE_USER_SERVICE_HISTORY_SUCCESS = "GET_ACTIVE_USER_SERVICE_HISTORY_SUCCESS";
export const GET_ACTIVE_USER_SERVICE_HISTORY_FAILURE = "GET_ACTIVE_USER_SERVICE_HISTORY_FAILURE";
export const GET_ACTIVE_USER_REGION_HISTORY_SUCCESS = "GET_ACTIVE_USER_REGION_HISTORY_SUCCESS";
export const GET_ACTIVE_USER_REGION_HISTORY_FAILURE = "GET_ACTIVE_USER_REGION_HISTORY_FAILURE";
export const GET_HOURLY_CONNECTION_TREND_START = "GET_HOURLY_CONNECTION_TREND_START"
export const GET_HOURLY_CONNECTION_TREND_FAILURE = "GET_HOURLY_CONNECTION_TREND_FAILURE"
export const GET_HOURLY_CONNECTION_TREND_SUCCESS = "GET_HOURLY_CONNECTION_TREND_SUCCESS"