import React, { useEffect, useState } from "react";
import { Layout, UserDetailCard } from "../components/molecules";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetailsById,
  getUserDeviceInfoById,
  getUserIssueInfoById,
  getUserAppInfoById,
  fetchUserActiveConnections,
} from "../Redux/Zte/ZteUsers/ZteUsersActions";
import { structureData } from "../utils/FormatData";
import { HomeStyle } from "./Pages.style";
import { IoIosArrowBack } from "react-icons/io";
import { Button, Table } from "../components/atoms";
import CreateIssueModal from "../components/molecules/CreateIssueModal";
import SectionToggle from "../components/molecules/SectionToggle/SectionToggle";
import AppCard from "../components/molecules/AppSection/AppCard";
import InformationModal from "../components/molecules/InformationModal";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserDetails = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openIssueModal, setOpenIssueModal] = useState(false);
  const [activeTab, setActiveTab] = useState("User");
  const tabList = ["User", "Apps"];
  const [open, setOpen] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    dispatch(getUserDetailsById(userId));
    dispatch(getUserDeviceInfoById(userId));
    dispatch(getUserIssueInfoById(userId));
    dispatch(getUserAppInfoById(userId));
    dispatch(fetchUserActiveConnections(userId));
  }, [dispatch, userId]);

  const handleOpen = () => {
    setOpenIssueModal(true);
  };
  const {
    userDetail,
    fetchingUserDetail,
    userActiveConnections,
    fetchingUserActiveConnections,
    userDeviceInfo,
    userIssueInfo,
    userAppInfo,
  } = useSelector((state) => state.users);
  const headers = ["Username", "Duration", "Expiry"];

  const userData = {
    basicInformation: userDetail?.basicInformation,
    deviceInformation: userDeviceInfo,
    userIssues: userIssueInfo,
  };

  return (
    <Layout>
      <CreateIssueModal open={openIssueModal} setOpen={setOpenIssueModal} />
      <HomeStyle>
        <div className="back-btn" onClick={() => navigate(-1)}>
          <IoIosArrowBack />
          <p>Back</p>
        </div>
        <SectionToggle
          tabList={tabList}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        >
          {activeTab === "User" ? (
            <>
              {!fetchingUserDetail ? (
                <div>
                  <UserDetailCard
                    userDetails={structureData("userDetail", userData)}
                  />
                  <h1 className="headers">Sessions</h1>
                  <div className="table-style">
                    <Table
                      headers={headers}
                      data={structureData(
                        "activeSessions",
                        userActiveConnections
                      )}
                      loading={fetchingUserActiveConnections}
                    />
                  </div>

                  <div className="add-issue">
                    <Button fill={"fill"} onclick={handleOpen}>
                      Create Issue
                    </Button>
                  </div>
                </div>
              ) : (
                <FontAwesomeIcon
                  icon={faSpinner}
                  size={"xl"}
                  color={"#62646d"}
                />
              )}
            </>
          ) : activeTab === "Apps" ? (
            <>
              <AppCard
                appDetails={userAppInfo?.ztnaInformation}
                setSelectedData={setSelectedData}
                setOpen={setOpen}
              />
              <InformationModal
                open={open}
                setOpen={setOpen}
                data={selectedData}
                userDetails={userData}
                type="app-info"
              />
            </>
          ) : null}
        </SectionToggle>
      </HomeStyle>
    </Layout>
  );
};

export default UserDetails;
