import { useEffect } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import { Table } from "../../atoms";
import { structureData } from "../../../utils/FormatData";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getDeviceMovementHistory } from "../../../Redux/Zte/Device/DeviceActions";
import SortFilterMessages from "../../molecules/SortFilterMessages/SortFilterMessages";
import InformationModal from "../InformationModal";

const DeviceMoveHistory = () => {
  const headers = ["DeviceName", "Date of Move"];
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1).toJSON()
  );
  const [endDate, setEndDate] = useState(new Date().toJSON());
  const [sortValue, setSortvalue] = useState("");
  const [sortParams, setSortParams] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [message, setMessage] = useState("");
  const [host, setHost] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [open, setOpen] = useState();
  const [selectedData, setSelectedData] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDeviceMovementHistory(
        currentPage,
        startDate,
        endDate,
        sortOrder,
        sortParams,
        host,
        message,
        deviceName
      )
    );
    // eslint-disable-next-line
  }, [dispatch, currentPage]);
  const handleApply = () => {
    dispatch(
      getDeviceMovementHistory(
        currentPage,
        startDate,
        endDate,
        sortOrder,
        sortParams,
        host,
        message,
        deviceName
      )
    );
  };
  const handleRowClick = (id) => {
    setOpen(true);
    setSelectedData(id);
  };

  const { deviceMovement, fetchingDeviceMovement } = useSelector(
    (state) => state.devices
  );

  return (
    <AdminCardStyles>
      <div>
        <SortFilterMessages
          sortValue={sortValue}
          setSortvalue={setSortvalue}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSortParams={setSortParams}
          setSortOrder={setSortOrder}
          setMessage={setMessage}
          setHost={setHost}
          setDeviceName={setDeviceName}
          handleApply={handleApply}
          type={"moveHistory"}
        />
      </div>
      <div className="table-style">
        <Table
          headers={headers}
          data={structureData("deviceMovement", deviceMovement.results)}
          loading={fetchingDeviceMovement}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={deviceMovement?.totalCount}
          pageSize={10}
          type="info"
          handleRowClick={handleRowClick}
        />
      </div>
      <InformationModal
        open={open}
        setOpen={setOpen}
        data={selectedData}
        type="emsMessages"
      />
    </AdminCardStyles>
  );
};

export default DeviceMoveHistory;
