import Styled from "styled-components";

export const SortFilterStyle = Styled.div`
  
    .main{
        display: flex; 
        padding-top: 1rem;
       
        margin-right: 2px;
        width: 100%;
        overflow: scroll;
        input {
            margin-right: -2px;
        }
    }
    .container {
        display: flex;
        padding: 0.5px;
    }
    .item-container {
        display: flex;
        align-items: center;
        width: 200px;
        height: 40px;
        border-radius: 2px;
        border: 1px solid #96c115;  
        padding-left: 8px;
        font-size: 14px;
    }
    .title {
        margin-right: 4px;
        font-size: 12px;
        color: #3e3e3e;
    }
    .icon-style {
        margin-left: -20px;
        margin-top: 12px;
        margin-right: 2px;
    }
    label {
        font-size: 12px;
        font-weight: 700;
        color: #62646d;
        margin-top: -0.5px;
        padding-left: 8px;
    }
    .date-filters{
        margin-top: -1rem;
    }
    .button-style{
        margin-left: 20px;
        margin-top: -2px;
    }

    .sent-messages-button{
        padding-bottom: 1rem;
    }
    .date-selector{
        padding-top: 0.8px;
    }
`;

export const SortCardStyle = Styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    border-radius: 2px;
    border: 1px solid #96c115;
    font-size: 12px;
    color: #3e3e3e;
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    .list-item {
        cursor: pointer;
        padding: 8px;
        &:hover {
            background-color: #999999;
        }
    }
`;

export const FilterStyle = Styled.div`
    display: flex;
    height: 40px;
    margin-left: 2px;
    input {
        border-radius: 2px;
        border: 1px solid #96c115;
        padding-left: 8px;
        color: #3e3e3e;
        &:focus {
            outline: none;
            border: 2px solid #96c115;
        }
    }
    input,
    input::placeholder {
        font-size: 12px;
    }

    .icon-style {
        margin-left: -24px;
    }
    label {
        font-size: 12px;
        font-weight: 700;
        color: #62646d;
        margin-top: -0.5px;
        padding-left: 8px;
    }
    .date-filters{
        margin-top: -1rem;
    }
`;
