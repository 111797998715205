import {
  GET_USER_ISSUE_SUMMARY_FAILURE,
  GET_USER_ISSUE_SUMMARY_SUCCESS,
  GET_USER_ISSUE_SUMMARY_START,
  GET_ISSUE_TYPE_SUMMARY_FAILURE,
  GET_ISSUE_TYPE_SUMMARY_SUCCESS,
  GET_ISSUE_TYPE_SUMMARY_START,
  GET_ISSUE_DETAIL_SUMMARY_FAILURE,
  GET_ISSUE_DETAIL_SUMMARY_SUCCESS,
  GET_ISSUE_DETAIL_SUMMARY_START,
  GET_ISSUE_TYPE_SUMMARY_DURATION_FAILURE,
  GET_ISSUE_TYPE_SUMMARY_DURATION_SUCCESS,
  GET_ISSUE_TYPE_SUMMARY_DURATION_START,
  GET_ISSUE_DETAIL_SUMMARY_DURATION_FAILURE,
  GET_ISSUE_DETAIL_SUMMARY_DURATION_SUCCESS,
  GET_ISSUE_DETAIL_SUMMARY_DURATION_START,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_FAILURE,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_SUCCESS,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_START,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_FAILURE,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_SUCCESS,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_START,
  GET_ISSUE_TYPE_BY_COUNTRY_FAILURE,
  GET_ISSUE_TYPE_BY_COUNTRY_SUCCESS,
  GET_ISSUE_TYPE_BY_COUNTRY_START,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_FAILURE,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_SUCCESS,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_START,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_FAILURE,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_SUCCESS,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_START,
  GET_AVERAGE_USER_SERVICE_EXP_FAILURE,
  GET_AVERAGE_USER_SERVICE_EXP_SUCCESS,
  GET_AVERAGE_USER_SERVICE_EXP_START,
  GET_AVERAGE_USER_SERVICE_EXP_REGION_FAILURE,
  GET_AVERAGE_USER_SERVICE_EXP_REGION_SUCCESS,
  GET_AVERAGE_USER_SERVICE_EXP_REGION_START,
  GET_AVERAGE_OVERALL_RATING_FAILURE,
  GET_AVERAGE_OVERALL_RATING_SUCCESS,
  GET_AVERAGE_OVERALL_RATING_START,
  GET_AVERAGE_OVERALL_RATING_COUNTRY_FAILURE,
  GET_AVERAGE_OVERALL_RATING_COUNTRY_SUCCESS,
  GET_AVERAGE_OVERALL_RATING_COUNTRY_START,
  GET_AVERAGE_OVERALL_RATING_REGION_FAILURE,
  GET_AVERAGE_OVERALL_RATING_REGION_SUCCESS,
  GET_AVERAGE_OVERALL_RATING_REGION_START,
  GET_AVERAGE_GENERIC_EOU_FAILURE,
  GET_AVERAGE_GENERIC_EOU_SUCCESS,
  GET_AVERAGE_GENERIC_EOU_START,
  GET_AVERAGE_GENERIC_EOU_COUNTRY_FAILURE,
  GET_AVERAGE_GENERIC_EOU_COUNTRY_SUCCESS,
  GET_AVERAGE_GENERIC_EOU_COUNTRY_START,
  GET_GEO_LOCATION_FAILURE,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_START,
} from "./ZtnaDashboardActionTypes";
import {
  getUserIssueSummary,
  getIssueTypeSummary,
  getIssueDetailSummary,
  getIssueTypeSummaryByDuration,
  getIssueDetailSummaryByDuration,
  getIssueTypeSummaryByResolutionSpeed,
  getIssueTypeSummaryByResolutionSpeedByDuration,
  getIssueTypeByCountry,
  getIssueDetailByResolutionSpeed,
  getIssueDetailByResolutionSpeedByDuration,
  getAverageUserServiceExperienceRating,
  getAverageUserServiceExperienceRatingRegion,
  getAverageOverallRating,
  getAverageOverallRatingByCountry,
  getAverageOverallRatingByRegion,
  getAverageGenericEaseofUse,
  getAverageGenericEaseofUseByCountry,
  geoLocation,
} from "../../../api/zte/ztnaDashboard";
import { toast } from "react-toastify";

export const getUserIssueSummaryStart = () => {
  return {
    type: GET_USER_ISSUE_SUMMARY_START,
  };
};

export const getUserIssueSummarySuccess = (payload) => {
  return {
    type: GET_USER_ISSUE_SUMMARY_SUCCESS,
    payload,
  };
};

export const getUserIssueSummaryFailed = (payload) => {
  return {
    type: GET_USER_ISSUE_SUMMARY_FAILURE,
    payload,
  };
};

export const getUserIssue = () => async (dispatch) => {
  dispatch(getUserIssueSummaryStart());
  const { data, error } = await getUserIssueSummary();
  if (data && data?.statusCode === "90000") {
    dispatch(getUserIssueSummarySuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getUserIssueSummaryFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssueDetailSummaryStart = () => {
  return {
    type: GET_ISSUE_DETAIL_SUMMARY_START,
  };
};

export const getIssueDetailSummarySuccess = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_SUMMARY_SUCCESS,
    payload,
  };
};

export const getIssueDetailSummaryFailed = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_SUMMARY_FAILURE,
    payload,
  };
};

export const getIssueDetail = () => async (dispatch) => {
  dispatch(getIssueDetailSummaryStart());
  const { data, error } = await getIssueDetailSummary();
  if (data && data?.statusCode === "90000") {
    dispatch(getIssueDetailSummarySuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getIssueDetailSummaryFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssueTypeSummaryStart = () => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_START,
  };
};

export const getIssueTypeSummarySuccess = (payload) => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_SUCCESS,
    payload,
  };
};

export const getIssueTypeSummaryFailed = (payload) => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_FAILURE,
    payload,
  };
};

export const getIssueType = () => async (dispatch) => {
  dispatch(getIssueTypeSummaryStart());
  const { data, error } = await getIssueTypeSummary();
  if (data && data?.statusCode === "90000") {
    dispatch(getIssueTypeSummarySuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getIssueTypeSummaryFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssueTypeSummaryByDurationStart = () => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_DURATION_START,
  };
};

export const getIssueTypeSummaryByDurationSuccess = (payload) => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_DURATION_SUCCESS,
    payload,
  };
};

export const getIssueTypeSummaryByDurationFailed = (payload) => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_DURATION_FAILURE,
    payload,
  };
};

export const getIssueTypeByDuration = (duration) => async (dispatch) => {
  dispatch(getIssueTypeSummaryByDurationStart());
  const { data, error } = await getIssueTypeSummaryByDuration(duration);
  if (data && data?.statusCode === "90000") {
    dispatch(getIssueTypeSummaryByDurationSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getIssueTypeSummaryByDurationFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssueDetailSummaryByDurationStart = () => {
  return {
    type: GET_ISSUE_DETAIL_SUMMARY_DURATION_START,
  };
};

export const getIssueDetailSummaryByDurationSuccess = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_SUMMARY_DURATION_SUCCESS,
    payload,
  };
};

export const getIssueDetailSummaryByDurationFailed = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_SUMMARY_DURATION_FAILURE,
    payload,
  };
};

export const getIssueDetailByDuration = (interval) => async (dispatch) => {
  dispatch(getIssueDetailSummaryByDurationStart());
  const { data, error } = await getIssueDetailSummaryByDuration(interval);
  if (data && data?.statusCode === "90000") {
    dispatch(getIssueDetailSummaryByDurationSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getIssueDetailSummaryByDurationFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssueTypeSummaryByResolutionSpeedStart = () => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_RESOLUTION_START,
  };
};

export const getIssueTypeSummaryByResolutionSpeedSuccess = (payload) => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_RESOLUTION_SUCCESS,
    payload,
  };
};

export const getIssueTypeSummaryByResolutionSpeedFailed = (payload) => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_RESOLUTION_FAILURE,
    payload,
  };
};

export const getIssueTypeByResolutionSpeed = () => async (dispatch) => {
  dispatch(getIssueTypeSummaryByResolutionSpeedStart());
  const { data, error } = await getIssueTypeSummaryByResolutionSpeed();
  if (data && data?.statusCode === "90000") {
    dispatch(getIssueTypeSummaryByResolutionSpeedSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getIssueTypeSummaryByResolutionSpeedFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssueTypeSummaryByResolutionSpeedByDurationStart = () => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_START,
  };
};

export const getIssueTypeSummaryByResolutionSpeedByDurationSuccess = (
  payload
) => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_SUCCESS,
    payload,
  };
};

export const getIssueTypeSummaryByResolutionSpeedByDurationFailed = (
  payload
) => {
  return {
    type: GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_FAILURE,
    payload,
  };
};

export const getIssueTypeByResolutionSpeedByDuration =
  (duration) => async (dispatch) => {
    dispatch(getIssueTypeSummaryByResolutionSpeedByDurationStart());
    const { data, error } =
      await getIssueTypeSummaryByResolutionSpeedByDuration(duration);
    if (data && data?.statusCode === "90000") {
      dispatch(getIssueTypeSummaryByResolutionSpeedByDurationSuccess(data));
    } else if (data?.statusCode === "10001") {
      toast.error(data.message);
    } else {
      dispatch(getIssueTypeSummaryByResolutionSpeedByDurationFailed(error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const getIssueTypeCountryStart = () => {
  return {
    type: GET_ISSUE_TYPE_BY_COUNTRY_START,
  };
};

export const getIssueTypeCountrySuccess = (payload) => {
  return {
    type: GET_ISSUE_TYPE_BY_COUNTRY_SUCCESS,
    payload,
  };
};

export const getIssueTypeCountryFailed = (payload) => {
  return {
    type: GET_ISSUE_TYPE_BY_COUNTRY_FAILURE,
    payload,
  };
};

export const getIssueTypeCountry = () => async (dispatch) => {
  dispatch(getIssueTypeCountryStart());
  const { data, error } = await getIssueTypeByCountry();
  if (data && data?.statusCode === "90000") {
    dispatch(getIssueTypeCountrySuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getIssueTypeCountryFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssuesDetailByResolutionSpeedStart = () => {
  return {
    type: GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_START,
  };
};

export const getIssuesDetailByResolutionSpeedSuccess = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_SUCCESS,
    payload,
  };
};

export const getIssuesDetailByResolutionSpeedFailed = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_FAILURE,
    payload,
  };
};

export const getIssuesDetailByResolutionSpeed = () => async (dispatch) => {
  dispatch(getIssuesDetailByResolutionSpeedStart());
  const { data, error } = await getIssueDetailByResolutionSpeed();
  if (data && data?.statusCode === "90000") {
    dispatch(getIssuesDetailByResolutionSpeedSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getIssuesDetailByResolutionSpeedFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssuesDetailByResolutionSpeedDurationStart = () => {
  return {
    type: GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_START,
  };
};

export const getIssuesDetailByResolutionSpeedDurationSuccess = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_SUCCESS,
    payload,
  };
};

export const getIssuesDetailByResolutionSpeedDurationFailed = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_FAILURE,
    payload,
  };
};

export const getIssuesDetailByResolutionSpeedByDuration =
  (duration) => async (dispatch) => {
    dispatch(getIssuesDetailByResolutionSpeedDurationStart());
    const { data, error } = await getIssueDetailByResolutionSpeedByDuration(
      duration
    );
    if (data && data?.statusCode === "90000") {
      dispatch(getIssuesDetailByResolutionSpeedDurationSuccess(data));
    } else if (data?.statusCode === "10001") {
      toast.error(data.message);
    } else {
      dispatch(getIssuesDetailByResolutionSpeedDurationFailed(error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const getAverageUserServiceExpRatingStart = () => {
  return {
    type: GET_AVERAGE_USER_SERVICE_EXP_START,
  };
};

export const getAverageUserServiceExpRatingSuccess = (payload) => {
  return {
    type: GET_AVERAGE_USER_SERVICE_EXP_SUCCESS,
    payload,
  };
};

export const getAverageUserServiceExpRatingFailed = (payload) => {
  return {
    type: GET_AVERAGE_USER_SERVICE_EXP_FAILURE,
    payload,
  };
};

export const getAverageUserServiceExpRating = () => async (dispatch) => {
  dispatch(getAverageUserServiceExpRatingStart());
  const { data, error } = await getAverageUserServiceExperienceRating();
  if (data && data?.statusCode === "90000") {
    dispatch(getAverageUserServiceExpRatingSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAverageUserServiceExpRatingFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAverageUserServiceExpRatingRegionStart = () => {
  return {
    type: GET_AVERAGE_USER_SERVICE_EXP_REGION_START,
  };
};

export const getAverageUserServiceExpRatingRegionSuccess = (payload) => {
  return {
    type: GET_AVERAGE_USER_SERVICE_EXP_REGION_SUCCESS,
    payload,
  };
};

export const getAverageUserServiceExpRatingRegionFailed = (payload) => {
  return {
    type: GET_AVERAGE_USER_SERVICE_EXP_REGION_FAILURE,
    payload,
  };
};

export const getAverageUserServiceExpRatingRegion = () => async (dispatch) => {
  dispatch(getAverageUserServiceExpRatingRegionStart());
  const { data, error } = await getAverageUserServiceExperienceRatingRegion();
  if (data && data?.statusCode === "90000") {
    dispatch(getAverageUserServiceExpRatingRegionSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAverageUserServiceExpRatingRegionFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAverageOverallRatingStart = () => {
  return {
    type: GET_AVERAGE_OVERALL_RATING_START,
  };
};

export const getAverageOverallRatingSuccess = (payload) => {
  return {
    type: GET_AVERAGE_OVERALL_RATING_SUCCESS,
    payload,
  };
};

export const getAverageOverallRatingFailed = (payload) => {
  return {
    type: GET_AVERAGE_OVERALL_RATING_FAILURE,
    payload,
  };
};

export const getAverageRating = () => async (dispatch) => {
  dispatch(getAverageOverallRatingStart());
  const { data, error } = await getAverageOverallRating();
  if (data && data?.statusCode === "90000") {
    dispatch(getAverageOverallRatingSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAverageOverallRatingFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAverageOverallRatingByCountryStart = () => {
  return {
    type: GET_AVERAGE_OVERALL_RATING_COUNTRY_START,
  };
};

export const getAverageOverallRatingByCountrySuccess = (payload) => {
  return {
    type: GET_AVERAGE_OVERALL_RATING_COUNTRY_SUCCESS,
    payload,
  };
};

export const getAverageOverallRatingByCountryFailed = (payload) => {
  return {
    type: GET_AVERAGE_OVERALL_RATING_COUNTRY_FAILURE,
    payload,
  };
};

export const getAverageOverallRatingCountry = () => async (dispatch) => {
  dispatch(getAverageOverallRatingByCountryStart());
  const { data, error } = await getAverageOverallRatingByCountry();
  if (data && data?.statusCode === "90000") {
    dispatch(getAverageOverallRatingByCountrySuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAverageOverallRatingByCountryFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAverageOverallRatingByRegionStart = () => {
  return {
    type: GET_AVERAGE_OVERALL_RATING_REGION_START,
  };
};

export const getAverageOverallRatingByRegionSuccess = (payload) => {
  return {
    type: GET_AVERAGE_OVERALL_RATING_REGION_SUCCESS,
    payload,
  };
};

export const getAverageOverallRatingByRegionFailed = (payload) => {
  return {
    type: GET_AVERAGE_OVERALL_RATING_REGION_FAILURE,
    payload,
  };
};

export const getAverageOverallRatingRegion = () => async (dispatch) => {
  dispatch(getAverageOverallRatingByRegionStart());
  const { data, error } = await getAverageOverallRatingByRegion();
  if (data && data?.statusCode === "90000") {
    dispatch(getAverageOverallRatingByRegionSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAverageOverallRatingByRegionFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAverageGenericEouStart = () => {
  return {
    type: GET_AVERAGE_GENERIC_EOU_START,
  };
};

export const getAverageGenericEouSuccess = (payload) => {
  return {
    type: GET_AVERAGE_GENERIC_EOU_SUCCESS,
    payload,
  };
};

export const getAverageGenericEouFailed = (payload) => {
  return {
    type: GET_AVERAGE_GENERIC_EOU_FAILURE,
    payload,
  };
};

export const getAverageGenericEou = () => async (dispatch) => {
  dispatch(getAverageGenericEouStart());
  const { data, error } = await getAverageGenericEaseofUse();
  if (data && data?.statusCode === "90000") {
    dispatch(getAverageGenericEouSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAverageGenericEouFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAverageGenericEouCountryStart = () => {
  return {
    type: GET_AVERAGE_GENERIC_EOU_COUNTRY_START,
  };
};

export const getAverageGenericEouCountrySuccess = (payload) => {
  return {
    type: GET_AVERAGE_GENERIC_EOU_COUNTRY_SUCCESS,
    payload,
  };
};

export const getAverageGenericEouCountryFailed = (payload) => {
  return {
    type: GET_AVERAGE_GENERIC_EOU_COUNTRY_FAILURE,
    payload,
  };
};

export const getAverageGenericEouCountry = () => async (dispatch) => {
  dispatch(getAverageGenericEouCountryStart());
  const { data, error } = await getAverageGenericEaseofUseByCountry();
  if (data && data?.statusCode === "90000") {
    dispatch(getAverageGenericEouCountrySuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAverageGenericEouCountryFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const geoLocationStart = () => {
  return {
    type: GET_GEO_LOCATION_START,
  };
};

export const geoLocationSuccess = (payload) => {
  return {
    type: GET_GEO_LOCATION_SUCCESS,
    payload,
  };
};

export const geoLocationFailed = (payload) => {
  return {
    type: GET_GEO_LOCATION_FAILURE,
    payload,
  };
};

export const geoLocationData = (interval) => async (dispatch) => {
  dispatch(geoLocationStart());
  const { data, error } = await geoLocation(interval);
  if (data && data?.statusCode === "90000") {
    dispatch(geoLocationSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(geoLocationFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
