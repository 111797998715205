import moment from "moment";

export const structureData = (type, data) => {
  let result;
  switch (type) {
    case "devices":
      result = data?.map((device) => {
        return {
          id: device?.id,
          "Device Name": device?.deviceName,
          "FortiClient Version": device?.fortiClientVersion,
          "Managed by EMS": device?.managedByEMS ? "True" : "False",
        };
      });
      break;
    case "users":
      result = data?.map((user) => {
        return {
          id: user?.objectID,
          "Display Name": user?.displayName,
          "User Principal Name": user?.userPrincipalName,
          Email: user?.email,
        };
      });
      break;
    case "deviceDetail":
      result = {
        Device: {
          Name: data?.deviceName,
          "Operating System": data?.operatingSystem || "-",
          "Operating System Version": data?.operatingSystemVersion || "-",
          "ManagedBy EMS": data?.managedByEMS ? "True" : "False",
          "FortiClient Version": data?.fortiClientVersion || "-",
          "Policy Name": data?.activeEndpointPolicyName || "-",
          "Last FortiClient Version Update Date":
            data?.lastFortiClientVersionUpdateDate || "-",
        },
        User: data?.users
          ? data?.users.map((value) => {
              return {
                "Display Name": value.displayName,
                Alias: value.alias,
                Email: value.email,
              };
            })
          : "No data found",
        "Recently Logged In Users": !data?.machineUserNames
          ? "No user found"
          : data?.machineUserNames.map((user) => {
              return {
                name: user.name,
                isActive: user.isActive ? "True" : "False",
              };
            }),
        "Device History": data?.emsGroupHistories
          ? data?.emsGroupHistories.map((value) => {
              return {
                "Group Name": value?.groupName,
                "Date Added": value?.dateAdded
                  ? moment(value?.dateAdded).format("MM-DD-YYYY [,] hh:mm a")
                  : " ",
                "Date Modified": value?.dateModified
                  ? moment(value?.dateModified).format("MM-DD-YYYY [,] hh:mm a")
                  : "N/A",
                "Modified By": value?.modifiedBy ? value?.modifiedBy : "N/A",
              };
            })
          : "No data found",
        "Policy History": data?.deviceEndpointPolicyHistories
          ? data?.deviceEndpointPolicyHistories.map((value) => {
              return {
                "Policy Name": value?.policyName?.name,
                "Date Added": value?.dateAdded
                  ? moment(value?.dateAdded).format("MM-DD-YYYY [,] hh:mm a")
                  : " ",
                "Date Removed": value?.dateRemoved
                  ? moment(value?.dateRemoved).format("MM-DD-YYYY [,] hh:mm a")
                  : "N/A",
              };
            })
          : "No data found",
      };
      break;
    case "userDetail":
      result = {
        User: {
          "Display Name": data?.basicInformation?.displayName,
          "First Name": data?.basicInformation?.firstName,
          "Last Name": data?.basicInformation?.lastName,
          Alias: data?.basicInformation?.alias,
          "User Principal Name": data?.basicInformation?.userPrincipalName,
          Email: data?.basicInformation?.email,
        },
        Device:
          data?.deviceInformation === null
            ? "No devices found"
            : data?.deviceInformation?.map((value) => {
                return {
                  "Device Name": value?.deviceName,
                  "Operating System": value?.operatingSystem,

                  "Managed By EMS": value?.managedByEMS ? "True" : "False",

                  "FortiClient Version": value?.fortiClientVersion,
                  Policy: value?.activeEndpointPolicyName || "-",
                  "Last FortiClient Version Update Date":
                    value?.lastFortiClientVersionUpdateDate
                      ? moment(value?.lastFortiClientVersionUpdateDate).format(
                          "MM-DD-YYYY [,] hh:mm a"
                        )
                      : " ",
                };
              }),

        Issues:
          data?.userIssues === null
            ? "No issues found"
            : data?.userIssues?.map((value, index) => {
                return {
                  id: value?.issueHistoryId,
                  Type: value?.type,
                  Detail: value?.detail,
                  Device: value?.deviceName,
                  Status: value?.status,
                  Reported: value?.dateAdded
                    ? moment(value?.dateAdded).format("MM-DD-YYYY [,] hh:mm a")
                    : "-",
                  Resolved: value?.resolutionDate
                    ? moment(value?.resolutionDate).format(
                        "MM-DD-YYYY [,] hh:mm a"
                      )
                    : "pending",
                };
              }),
      };
      break;
    case "apps":
      result = data?.map((app) => {
        return {
          id: app?.id,
          "Device Name": app?.deviceName,
          "FortiClient Version": app?.fortiClientVersion,
          "Managed by EMS": app?.managedByEMS ? "True" : "False",
        };
      });
      break;
    case "issues":
      result = data?.map((info) => {
        return {
          id: info?.issueHistoryId,
          Detail: info?.issueDetailTitle,
          Device: info?.deviceName,
          Status:
            info?.status === 0
              ? "Resolved"
              : info?.status === 1
              ? "Unresolved"
              : info?.status === 2
              ? "On Hold"
              : "-",
          Reported: info?.dateAdded
            ? moment(info?.dateAdded).format("MM-DD-YYYY [,] hh:mm a")
            : "-",
          Resolved: info?.resolutionDate
            ? moment(info?.resolutionDate).format("MM-DD-YYYY [,] hh:mm a")
            : "Pending",
        };
      });
      break;
    case "issue detail":
      result = {
        Issue: {
          id: data?.issueHistoryId,
          "Issue type": data?.typeIssueName,
          "Issue Detail": data?.issueDetailTitle,
          Status:
            data?.status === 0
              ? "Resolved"
              : data?.status === 1
              ? "Unresolved"
              : data?.status === 2
              ? "On Hold"
              : "-",
          "Date added": data?.dateAdded
            ? moment(data?.dateAdded).format("MM-DD-YYYY [,] hh:mm a")
            : "-",
          "Resolution date": data?.resolutionDate
            ? moment(data?.resolutionDate).format("MM-DD-YYYY [,] hh:mm a")
            : "Pending",

          "Resolution speed": data?.resolutionSpeed,
          "On Hold Date time": data?.onHoldDate
            ? moment(data?.onHoldDate).format("MM-DD-YYYY [,] hh:mm a")
            : "-",
          "On Hold reason": data?.onHoldReason || "-",
          "Release Date time": data?.releaseDate
            ? moment(data?.releaseDate).format("MM-DD-YYYY [,] hh:mm a")
            : "-",
          Comment: data?.comment || "-",
        },
        User: {
          "User Principal Name": data?.userPrincipalName || "-",
          "Display Name": data?.displayName || "-",
          "Device Name": data?.deviceName || "-",
        },
        "Admin Information": {
          "Added by": data?.addedBy || "-",
          "Updated by": data?.updatedBy || "-",
        },
      };
      break;
    case "userManagement":
      result = data?.map((group) => {
        return {
          Name: group?.name,
          Description: group?.description,
          Modified: moment(group?.dateModified).format("MM-DD-YYY"),
          Created: moment(group?.dateCreated).format("MM-DD-YYYY"),
          Added: moment(group?.dateAdded).format("MM-DD-YYYY"),
        };
      });
      break;
    case "activeSessions":
      data?.message
        ? (result = "")
        : (result = data?.map((value) => {
            return {
              Username: value?.username,
              Duration: value?.durationSecs,
              Expiry: value?.expirySecs,
            };
          }));
      break;
    case "jobInformation":
      result = data?.map((info) => {
        return {
          "Job Title": info?.jobTitle,
          Successful: info?.isSuccessful === true ? "True" : "False",
          "Last Runtime": moment(info?.lastRunTime).format(
            "MM-DD-YYYY, h:mm:ss a"
          ),
        };
      });
      break;
    case "activeConnections":
      data?.message
        ? (result = "")
        : (result = data?.map((info) => {
            return {
              id: info?.id,
              Username: info?.username,
              "IP Address": info?.ipAddr,
              Group: info?.userGroup,
              Duration: info?.durationSecs,
              "Inactive time": info?.runningInactiveTime,
              "Active time": info?.runningActiveTime,
            };
          }));
      break;
    case "sentMessages":
      result = data?.map((message) => {
        return {
          Message: message?.message,
          Sender: message?.initiatedBy,
          Date: moment(message?.dataAdded).format("MM-DD-YYYY, h:mm:ss a"),
          Device: message?.device,
          Success: message?.isSuccessful === true ? "True" : "False",
        };
      });
      break;
    case "aadGroupAssignments":
      result = data?.map((aadGroups) => {
        return {
          Service: aadGroups?.service,
          Groups: aadGroups?.groups,
        };
      });
      break;
    case "deviceMovement":
      result = data?.map((history) => {
        return {
          DeviceName: history?.deviceName,
          "Date of Move": moment(history?.dateOfMove).format(
            "MM-DD-YYYY, h:mm:ss a"
          ),
          Message: history?.emsMessage,
        };
      });
      break;
    case "assignmentSuggestions":
      result = data?.map((suggestion) => {
        return {
          "Device Name": suggestion?.deviceName,
          "User Principal Name": suggestion?.userPrincipalName,
          Reason: suggestion?.reason,
          "Status Description": suggestion?.statusDescription,
        };
      });
      break;
    case "emsGroupList":
      result = data?.map((group) => {
        return {
          Value: group?.value,
        };
      });
      break;

    default:
      result = null;
  }

  return result;
};
