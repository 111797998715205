import { useEffect } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import { Table } from "../../atoms";
import { structureData } from "../../../utils/FormatData";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getLinkedAzureGroups } from "../../../Redux/Zte/Ztna/ZtnaActions";
import SearchFilter from "../SearchFilter/SearchFilter";
import { Button } from "../../atoms";

const ServiceGroupsTable = () => {
  const headers = ["Service", "Groups"];
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchQuery.length === 0) {
      dispatch(getLinkedAzureGroups(""));
    }
  }, [dispatch, searchQuery]);

  const { linkedAzureAdGroups, fetchingLinkedAzureAdGroups } = useSelector(
    (state) => state.ztna
  );

  const formatData = (data) => {
    let result = [];
    for (let i in data) {
      let groups = [];
      for (let j in data[i].aadGroups) {
        groups.push(` ${data[i].aadGroups[j].name}`);
      }
      result.push({ service: i, groups: `${groups}` });
    }
    return result;
  };

  const handleApply = () => {
    dispatch(getLinkedAzureGroups(searchQuery));
  };

  return (
    <AdminCardStyles>
      <div className="ztna-container">
        <SearchFilter
          setSearchQuery={setSearchQuery}
          placeholder="Service Name"
        />
        <div className="button-style">
          <Button fill="fill" onclick={handleApply}>
            Apply Filter
          </Button>
        </div>
      </div>
      <div className="table-style">
        <Table
          headers={headers}
          data={structureData(
            "aadGroupAssignments",
            formatData(linkedAzureAdGroups)
          )}
          loading={fetchingLinkedAzureAdGroups}
        />
      </div>
    </AdminCardStyles>
  );
};

export default ServiceGroupsTable;
