import {
  GET_USER_ISSUE_SUMMARY_FAILURE,
  GET_USER_ISSUE_SUMMARY_SUCCESS,
  GET_USER_ISSUE_SUMMARY_START,
  GET_ISSUE_TYPE_SUMMARY_FAILURE,
  GET_ISSUE_TYPE_SUMMARY_SUCCESS,
  GET_ISSUE_TYPE_SUMMARY_START,
  GET_ISSUE_DETAIL_SUMMARY_FAILURE,
  GET_ISSUE_DETAIL_SUMMARY_SUCCESS,
  GET_ISSUE_DETAIL_SUMMARY_START,
  GET_ISSUE_TYPE_SUMMARY_DURATION_FAILURE,
  GET_ISSUE_TYPE_SUMMARY_DURATION_SUCCESS,
  GET_ISSUE_TYPE_SUMMARY_DURATION_START,
  GET_ISSUE_DETAIL_SUMMARY_DURATION_FAILURE,
  GET_ISSUE_DETAIL_SUMMARY_DURATION_SUCCESS,
  GET_ISSUE_DETAIL_SUMMARY_DURATION_START,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_FAILURE,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_SUCCESS,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_START,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_FAILURE,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_SUCCESS,
  GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_START,
  GET_ISSUE_TYPE_BY_COUNTRY_FAILURE,
  GET_ISSUE_TYPE_BY_COUNTRY_SUCCESS,
  GET_ISSUE_TYPE_BY_COUNTRY_START,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_FAILURE,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_SUCCESS,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_START,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_FAILURE,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_SUCCESS,
  GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_START,
  GET_AVERAGE_USER_SERVICE_EXP_FAILURE,
  GET_AVERAGE_USER_SERVICE_EXP_SUCCESS,
  GET_AVERAGE_USER_SERVICE_EXP_START,
  GET_AVERAGE_USER_SERVICE_EXP_REGION_FAILURE,
  GET_AVERAGE_USER_SERVICE_EXP_REGION_SUCCESS,
  GET_AVERAGE_USER_SERVICE_EXP_REGION_START,
  GET_AVERAGE_OVERALL_RATING_FAILURE,
  GET_AVERAGE_OVERALL_RATING_SUCCESS,
  GET_AVERAGE_OVERALL_RATING_START,
  GET_AVERAGE_OVERALL_RATING_COUNTRY_FAILURE,
  GET_AVERAGE_OVERALL_RATING_COUNTRY_SUCCESS,
  GET_AVERAGE_OVERALL_RATING_COUNTRY_START,
  GET_AVERAGE_OVERALL_RATING_REGION_FAILURE,
  GET_AVERAGE_OVERALL_RATING_REGION_SUCCESS,
  GET_AVERAGE_OVERALL_RATING_REGION_START,
  GET_AVERAGE_GENERIC_EOU_FAILURE,
  GET_AVERAGE_GENERIC_EOU_SUCCESS,
  GET_AVERAGE_GENERIC_EOU_START,
  GET_AVERAGE_GENERIC_EOU_COUNTRY_FAILURE,
  GET_AVERAGE_GENERIC_EOU_COUNTRY_SUCCESS,
  GET_AVERAGE_GENERIC_EOU_COUNTRY_START,
  GET_GEO_LOCATION_FAILURE,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_START,
} from "./ZtnaDashboardActionTypes";

const initialState = {
  fetchingUserIssue: false,
  fetchingUserIssueError: null,
  userIssue: {},
  fetchingIssueType: false,
  fetchingIssueTypeError: null,
  issueType: {},
  fetchingIssueDetail: false,
  fetchingIssueDetailError: null,
  issueDetail: {},
  fetchingIssueTypeDuration: false,
  fetchingIssueTypeDurationError: null,
  issueTypeDuration: {},
  fetchingIssueDetailDuration: false,
  fetchingIssueDetailDurationError: null,
  issueDetailDuration: {},
  fetchingIssueTypeResolution: false,
  fetchingIssueTypeResolutionError: null,
  issueTypeResolution: {},
  fetchingIssueTypeResolutionDuration: false,
  fetchingIssueTypeResolutionDurationError: null,
  issueTypeResolutionDuration: {},
  fetchingIssueTypeCountry: false,
  fetchingIssueTypeCountryError: null,
  issueTypeCountry: {},
  fetchingAvgUserServiceExp: false,
  fetchingAvgUserServiceExpError: null,
  avgUserServiceExpCountry: {},
  fetchingAvgUserServiceExpRegion: false,
  fetchingAvgUserServiceExpRegionError: null,
  avgUserServiceExpRegion: {},
  fetchingAvgRating: false,
  fetchingAvgRatingError: null,
  avgRating: {},
  fetchingAvgRatingCountry: false,
  fetchingAvgRatingCountryError: null,
  avgRatingCountry: {},
  fetchingAvgRatingRegion: false,
  fetchingAvgRatingRegionError: null,
  avgRatingRegion: {},
  fetchingAvgEaseofUse: false,
  fetchingAvgEaseofUseError: null,
  avgEaseofUse: {},
  fetchingAvgEaseofUseCountry: false,
  fetchingAvgEaseofUseCountryError: false,
  avgEaseofUseCountry: false,
  fetchingIssueDetailResolutionSpeed: false,
  fetchingIssueDetailResolutionSpeedError: null,
  issueDetailResolutionSpeed: {},
  fetchingIssueDetailResolutionSpeedDuration: false,
  fetchingIssueDetailResolutionSpeedurationError: null,
  issueDetailResolutionSpeeduration: {},
  geolocationData: {},
  fetchingGeolocationData: false,
  fetchingGeolocationDataError: null,
};

const ztnaDashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USER_ISSUE_SUMMARY_START:
      return {
        ...state,
        fetchingUserIssue: true,
        fetchingUserIssueError: null,
      };
    case GET_USER_ISSUE_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchingUserIssue: false,
        fetchingUserIssueError: null,
        userIssue: payload?.data,
      };
    case GET_USER_ISSUE_SUMMARY_FAILURE:
      return {
        ...state,
        fetchingUserIssue: false,
        fetchingUserIssueError: payload,
        userIssue: [],
      };
    case GET_ISSUE_TYPE_SUMMARY_START:
      return {
        ...state,
        fetchingIssueType: true,
        fetchingIssueTypeError: null,
      };
    case GET_ISSUE_TYPE_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchingIssueType: false,
        fetchingIssueTypeError: null,
        issueType: payload?.data,
      };
    case GET_ISSUE_TYPE_SUMMARY_FAILURE:
      return {
        ...state,
        fetchingIssueType: false,
        fetchingIssueTypeError: payload,
        issueType: [],
      };
    case GET_ISSUE_DETAIL_SUMMARY_START:
      return {
        ...state,
        fetchingIssueDetail: true,
        fetchingIssueTypeError: null,
      };
    case GET_ISSUE_DETAIL_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchingIssueDetail: false,
        fetchingIssueDetailError: null,
        issueDetail: payload.data,
      };
    case GET_ISSUE_DETAIL_SUMMARY_FAILURE:
      return {
        ...state,
        fetchingIssueDetail: false,
        fetchingIssueDetailError: payload,
        issueDetail: [],
      };
    case GET_ISSUE_TYPE_SUMMARY_DURATION_START:
      return {
        ...state,
        fetchingIssueTypeDuration: true,
        fetchingIssueTypeDurationError: null,
      };
    case GET_ISSUE_TYPE_SUMMARY_DURATION_SUCCESS:
      return {
        ...state,
        fetchingIssueTypeDuration: false,
        fetchingIssueTypeDurationError: null,
        issueTypeDuration: payload?.data?.summary,
      };
    case GET_ISSUE_TYPE_SUMMARY_DURATION_FAILURE:
      return {
        ...state,
        fetchingIssueTypeDuration: false,
        fetchingIssueTypeDurationError: payload,
        issueTypeDuration: [],
      };
    case GET_ISSUE_DETAIL_SUMMARY_DURATION_START:
      return {
        ...state,
        fetchingIssueDetailDuration: true,
        fetchingIssueDetailDurationError: null,
      };
    case GET_ISSUE_DETAIL_SUMMARY_DURATION_SUCCESS:
      return {
        ...state,
        fetchingIssueDetailDuration: false,
        fetchingIssueDetailDurationError: null,
        issueDetailDuration: payload?.data?.summary,
      };
    case GET_ISSUE_DETAIL_SUMMARY_DURATION_FAILURE:
      return {
        ...state,
        fetchingIssueDetailDuration: false,
        fetchingIssueDetailDurationError: payload,
        issueDetailDuration: [],
      };
    case GET_ISSUE_TYPE_SUMMARY_RESOLUTION_START:
      return {
        ...state,
        fetchingIssueTypeResolution: true,
        fetchingIssueTypeResolutionError: null,
      };
    case GET_ISSUE_TYPE_SUMMARY_RESOLUTION_SUCCESS:
      return {
        ...state,
        fetchingIssueTypeResolution: false,
        fetchingIssueTypeResolutionError: null,
        issueTypeResolution: payload?.data?.average[0],
      };

    case GET_ISSUE_TYPE_SUMMARY_RESOLUTION_FAILURE:
      return {
        ...state,
        fetchingIssueTypeResolution: false,
        fetchingIssueTypeResolutionError: payload,
        issueTypeResolution: [],
      };
    case GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_START:
      return {
        ...state,
        fetchingIssueTypeResolutionDuration: true,
        fetchingIssueTypeResolutionDurationError: null,
      };
    case GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_SUCCESS:
      return {
        ...state,
        fetchingIssueTypeResolutionDuration: false,
        fetchingIssueTypeResolutionDurationError: null,
        issueTypeResolutionDuration: payload?.data?.average,
      };
    case GET_ISSUE_TYPE_SUMMARY_RESOLUTION_DURATION_FAILURE:
      return {
        ...state,
        fetchingIssueTypeResolutionDuration: false,
        fetchingIssueTypeResolutionDurationError: payload,
        issueTypeResolutionDuration: [],
      };
    case GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_START:
      return {
        ...state,
        fetchingIssueDetailResolutionSpeed: true,
        fetchingIssueTypeResolutionDurationError: null,
      };
    case GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_SUCCESS:
      return {
        ...state,
        fetchingIssueDetailResolutionSpeed: false,
        fetchingIssueDetailResolutionSpeedError: null,
        issueDetailResolutionSpeed: payload.data.average[0],
      };
    case GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_FAILURE:
      return {
        ...state,
        fetchingIssueDetailResolutionSpeed: false,
        fetchingIssueDetailResolutionSpeedError: payload,
        issueDetailResolutionSpeed: [],
      };
    case GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_START:
      return {
        ...state,
        fetchingIssueDetailResolutionSpeedDuration: true,
        fetchingIssueDetailResolutionSpeedDurationError: null,
      };
    case GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_SUCCESS:
      return {
        ...state,
        fetchingIssueDetailResolutionSpeedDuration: false,
        fetchingIssueDetailResolutionSpeedDurationError: null,
        issueDetailResolutionSpeeduration: payload.data?.average,
      };
    case GET_ISSUE_DETAIL_BY_RESOLUTION_SPEED_DURATION_FAILURE:
      return {
        ...state,
        fetchingIssueDetailResolutionSpeedDuration: false,
        fetchingIssueDetailResolutionSpeedDurationError: payload,
        issueDetailResolutionSpeeduration: [],
      };
    case GET_ISSUE_TYPE_BY_COUNTRY_START:
      return {
        ...state,
        fetchingIssueTypeCountry: true,
        fetchingIssueTypeCountryError: null,
      };
    case GET_ISSUE_TYPE_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        fetchingIssueTypeCountry: false,
        fetchingIssueTypeCountryError: null,
        issueTypeCountry: payload?.data,
      };
    case GET_ISSUE_TYPE_BY_COUNTRY_FAILURE:
      return {
        ...state,
        fetchingIssueTypeCountry: false,
        fetchingIssueTypeCountryError: payload,
        issueTypeCountry: [],
      };
    case GET_AVERAGE_USER_SERVICE_EXP_START:
      return {
        ...state,
        fetchingAvgUserServiceExp: true,
        fetchingAvgUserServiceExpError: null,
      };
    case GET_AVERAGE_USER_SERVICE_EXP_SUCCESS:
      return {
        ...state,
        fetchingAvgUserServiceExp: false,
        fetchingAvgUserServiceExpError: null,
        avgUserServiceExpCountry: payload?.data,
      };
    case GET_AVERAGE_USER_SERVICE_EXP_FAILURE:
      return {
        ...state,
        fetchingAvgUserServiceExp: false,
        fetchingAvgUserServiceExpError: payload,
        avgUserServiceExpCountry: [],
      };
    case GET_AVERAGE_USER_SERVICE_EXP_REGION_START:
      return {
        ...state,
        fetchingAvgUserServiceExpRegion: true,
        fetchingAvgUserServiceExpRegionError: null,
      };
    case GET_AVERAGE_USER_SERVICE_EXP_REGION_SUCCESS:
      return {
        ...state,
        fetchingAvgUserServiceExpRegion: false,
        fetchingAvgUserServiceExpRegionError: null,
        avgUserServiceExpRegion: payload?.data,
      };
    case GET_AVERAGE_USER_SERVICE_EXP_REGION_FAILURE:
      return {
        ...state,
        fetchingAvgUserServiceExpRegion: false,
        fetchingAvgUserServiceExpRegionError: payload,
        avgUserServiceExpRegion: [],
      };
    case GET_AVERAGE_OVERALL_RATING_START:
      return {
        ...state,
        fetchingAvgRating: true,
        fetchingAvgRatingError: null,
      };
    case GET_AVERAGE_OVERALL_RATING_SUCCESS:
      return {
        ...state,
        fetchingAvgRating: false,
        fetchingAvgRatingError: null,
        avgRating: payload?.data,
      };
    case GET_AVERAGE_OVERALL_RATING_FAILURE:
      return {
        ...state,
        fetchingAvgRating: false,
        fetchingAvgRatingError: payload,
        avgRating: [],
      };
    case GET_AVERAGE_OVERALL_RATING_COUNTRY_START:
      return {
        ...state,
        fetchingAvgRatingCountry: true,
        fetchingAvgRatingCountryError: null,
      };
    case GET_AVERAGE_OVERALL_RATING_COUNTRY_SUCCESS:
      return {
        ...state,
        fetchingAvgRatingCountry: false,
        fetchingAvgRatingCountryError: null,
        avgRatingCountry: payload?.data,
      };
    case GET_AVERAGE_OVERALL_RATING_COUNTRY_FAILURE:
      return {
        ...state,
        fetchingAvgRatingCountry: false,
        fetchingAvgRatingCountryError: payload,
        avgRatingCountry: [],
      };
    case GET_AVERAGE_OVERALL_RATING_REGION_START:
      return {
        ...state,
        fetchingAvgRatingRegion: true,
        fetchingAvgRatingRegionError: null,
      };
    case GET_AVERAGE_OVERALL_RATING_REGION_SUCCESS:
      return {
        ...state,
        fetchingAvgRatingRegion: false,
        fetchingAvgRatingRegionError: null,
        avgRatingRegion: payload?.data,
      };
    case GET_AVERAGE_OVERALL_RATING_REGION_FAILURE:
      return {
        ...state,
        fetchingAvgRatingRegion: false,
        fetchingAvgRatingRegionError: payload,
        avgRatingRegion: [],
      };
    case GET_AVERAGE_GENERIC_EOU_START:
      return {
        ...state,
        fetchingAvgEaseofUse: true,
        fetchingAvgEaseofUseError: null,
      };
    case GET_AVERAGE_GENERIC_EOU_SUCCESS:
      return {
        ...state,
        fetchingAvgEaseofUse: false,
        fetchingAvgEaseofUseError: null,
        avgEaseofUse: payload?.data,
      };
    case GET_AVERAGE_GENERIC_EOU_FAILURE:
      return {
        ...state,
        fetchingAvgEaseofUse: false,
        fetchingAvgEaseofUseError: payload,
        avgEaseofUse: [],
      };
    case GET_AVERAGE_GENERIC_EOU_COUNTRY_START:
      return {
        ...state,
        fetchingAvgEaseofUseCountry: true,
        fetchingAvgEaseofUseCountryError: null,
      };
    case GET_AVERAGE_GENERIC_EOU_COUNTRY_SUCCESS:
      return {
        ...state,
        fetchingAvgEaseofUseCountry: false,
        fetchingAvgEaseofUseCountryError: null,
        avgEaseofUseCountry: payload?.data,
      };
    case GET_AVERAGE_GENERIC_EOU_COUNTRY_FAILURE:
      return {
        ...state,
        fetchingAvgEaseofUseCountry: false,
        fetchingAvgEaseofUseCountryError: payload,
        avgEaseofUseCountry: [],
      };
    case GET_GEO_LOCATION_START:
      return {
        ...state,
        fetchingGeolocationData: true,
        fetchingGeolocationDataError: null,
      };
    case GET_GEO_LOCATION_SUCCESS:
      return {
        ...state,
        fetchingGeolocationData: false,
        fetchingGeolocationDataError: null,
        geolocationData: payload.data,
      };
    case GET_GEO_LOCATION_FAILURE:
      return {
        ...state,
        fetchingGeolocationData: false,
        fetchingGeolocationDataError: payload,
        geolocationData: [],
      };
    default:
      return state;
  }
};

export default ztnaDashboardReducer;
