import API from "../axio";

export const getThreatDropdownInfo = async () => {
	try {
		const { data } = await API.get("/threatdashboard/sources-and-categories");
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const updateThreat = async (payload) => {
	try {
		const { data } = await API.post("/threatdashboard/add-threat", payload);
		return {
			data: data?.message,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const getSelectedThreatCategoryBreakdown = async (
	clickedThreatSourceId,
	duration,
	year,
) => {
	try {
		const { data } = await API.get(
			`threatdashboard/get-threat-entries-by-regions?threatSourceId=${clickedThreatSourceId}&durationType=${duration}&year=${year}`
		);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const getThreatSourceBreakdown = async (
	clickedThreatSourceId,
	duration,
	year
) => {
	try {
		const { data } = await API.get(
			`threatdashboard/threat-by-source?threatSourceId=${clickedThreatSourceId}&durationType=${duration}&year=${year}`
		);
		return {
			data: data.data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const getThreatEntries = async (
	duration,
	year,
) => {
	try {
		const { data } = await API.get(
			`threatdashboard/threats-by-duration?durationType=${duration}&year=${year}`
		);
		return {
			data: data.data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const getSource = async () => {
	try {
		const { data } = await API.get(`/threatdashboard/threat-sources`);
		return {
			data: data.data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const getARegionBreakdown = async (
	threatSourceId,
	regionId,
	durationType,
	year
) => {
	try {
		const { data } = await API.get(
			`/threatdashboard/get-threat-entries-by-a-region?threatSourceId=${threatSourceId}&regionId=${regionId}&durationType=${durationType}&year=${year}`
		);
		return {
			data: data.data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
