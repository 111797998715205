import { useEffect, useState } from "react";
import { Button } from "../components/atoms";
import { DeviceDetail, Layout } from "../components/molecules";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { structureData } from "../utils/FormatData";
import { HomeStyle } from "./Pages.style";
import { IoIosArrowBack } from "react-icons/io";
import { getIssueDetailById } from "../Redux/Zte/Issue/IssueAction";
import UpdateIssueStatusModal from "../components/molecules/UpdateIssueStatusModal";

const IssueDetails = () => {
  const { issueId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { issueDetail, fetchingIssueDetail } = useSelector(
    (state) => state.issues
  );
  useEffect(() => {
    dispatch(getIssueDetailById(issueId));
  }, [dispatch, issueId]);

  const handleOpen = () => {
    setOpen(true);
  };
  const status = issueDetail?.data?.status;
  return (
    <Layout>
      <HomeStyle>
        {status === 1 || status === 2 ? (
          <UpdateIssueStatusModal
            open={open}
            setOpen={setOpen}
            status={status}
          />
        ) : null}

        <div className="back-btn" onClick={() => navigate(-1)}>
          <IoIosArrowBack />
          <p>Back</p>
        </div>
        {!fetchingIssueDetail ? (
          <div>
            <DeviceDetail
              deviceDetail={structureData("issue detail", issueDetail?.data)}
            />
            {status && status!== 0 ? (
              <div className="update-issue-button">
                <Button fill={"fill"} onclick={handleOpen}>
                  Update Issue Status
                </Button>
              </div>
            ): null}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </HomeStyle>
    </Layout>
  );
};

export default IssueDetails;
