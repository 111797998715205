import {
  Home,
  Search,
  Issues,
  UserDetails,
  DeviceDetails,
  IssueDetails,
  Admin,
  Ratings,
  Ztna,
  Threat,
} from "../pages";

export const routes = [
  { path: "/", component: Home, level: 1 },
  { path: "/ztna", component: Ztna, level: 1 },
  { path: "/threat", component: Threat, level: 1 },
  { path: "/rate-services", component: Ratings, level: 1 },
  { path: "/search", component: Search, level: 2 },
  { path: "/user-details/:userId", component: UserDetails, level: 2 },
  { path: "/device-details/:deviceId", component: DeviceDetails, level: 2 },
  { path: "/issues", component: Issues, level: 2 },
  { path: "/issue-details/:issueId", component: IssueDetails, level: 2 },
  { path: "/admin", component: Admin, level: 3 },
];
