import { useState } from "react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RatingStarIcons = ({ rating, setRating }) => {
  const [hover, setHover] = useState(null);
  return (
    <>
      {[...Array(6)].map((key, value) => {
          const ratingValue = value + 1;
        return (
          <span key={value}>
            <FontAwesomeIcon
              icon={faStar}
              color={ratingValue <= (hover || rating) ? "#7CA212" : "#ccc"}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
              cursor={"pointer"}
              onClick={() => setRating(ratingValue)}
            />
          </span>
        );
      })}
    </>
  );
};

export default RatingStarIcons;
