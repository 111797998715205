import { useState, useRef } from "react";
import SortCard from "./SortCard";
import FilterItems from "./FilterItems";
import FilterItemsDate from "./FilterItemsDate";
import { SortFilterStyle } from "./SortFilter.Style";
import { Button } from "../../atoms";
import { useComponentVisible } from "../../../utils/customHooks/useComponentVisible";

const SortFilter = ({
  setStatus,
  setSortParams,
  setSortOrder,
  setReportingFilter,
  reportingFilter,
  setReportingFilterDateFrom,
  setReportingFilterDateTo,
  setResolutionFilter,
  resolutionFilter,
  setResolutionFilterDateFrom,
  setResolutionFilterDateTo,
  handleApply,
}) => {
  const sortFields = [
    "Detail (A-Z)",
    "Detail (Z-A)",
    "Status (A-Z)",
    "Status (Z-A)",
    "Reporting date (Earliest to Latest)",
    "Reporting date (Latest to Earliest)",
    "Resolution date (Earliest to Latest)",
    "Resolution date (Latest to Earliest)",
  ];
  const statusFields = [
    "Status (Resolved)",
    "Status (Unresolved)",
    "Status (On Hold)",
  ];
  const dateFields = ["Reporting date", "Resolution date"];
  const [filterValue, setFilterValue] = useState("");
  const [sortValue, setSortvalue] = useState("");
  const sortRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [checkboxValuesStatus, setCheckboxValuesStatus] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);

  useComponentVisible(sortRef, setVisible);

  const handleVisibility = () => {
    setVisible(!visible);
  };
  return (
    <SortFilterStyle>
      <div className="container" onClick={handleVisibility} ref={sortRef}>
        <div className="date-filters">
          <label>{"Sort By"}</label>
          <SortCard
            detailsList={sortFields}
            title={sortFields[0]}
            setFilterValue={setSortvalue}
            filterValue={sortValue}
            setSortParams={setSortParams}
            setSortOrder={setSortOrder}
            visible={visible}
          />
        </div>
      </div>
      <div className="container">
        <div>
          <FilterItems
            detailsList={statusFields}
            title={"Filter By Status"}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            setStatus={setStatus}
            setReportingFilter={setReportingFilter}
            setResolutionFilter={setResolutionFilter}
            checkboxValues={checkboxValuesStatus}
            setCheckboxValues={setCheckboxValuesStatus}
          />
        </div>
      </div>
      <div className="container">
        <div>
          <FilterItems
            detailsList={dateFields}
            title={"Filter By Dates"}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            setStatus={setStatus}
            setReportingFilter={setReportingFilter}
            setResolutionFilter={setResolutionFilter}
            checkboxValues={checkboxValues}
            setCheckboxValues={setCheckboxValues}
          />
        </div>
      </div>
      {reportingFilter && (
        <>
          <div className="container">
            <FilterItemsDate
              setFilterValue={setReportingFilterDateFrom}
              labelTitle="Reporting Start Date"
            />
          </div>
          <div className="container">
            <div>
              <FilterItemsDate
                setFilterValue={setReportingFilterDateTo}
                labelTitle="Reporting End Date"
              />
            </div>
          </div>
        </>
      )}
      {resolutionFilter && (
        <>
          <div className="container">
            <FilterItemsDate
              setFilterValue={setResolutionFilterDateFrom}
              labelTitle="Resolution Start Date"
            />
          </div>
          <div className="container">
            <FilterItemsDate
              setFilterValue={setResolutionFilterDateTo}
              labelTitle="Resolution End Date"
            />
          </div>
        </>
      )}
      <div className="button-style">
        <Button fill="fill" onclick={handleApply}>
          Apply Filters
        </Button>
      </div>
    </SortFilterStyle>
  );
};

export default SortFilter;
