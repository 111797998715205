import React from 'react'
import DetailsCard from './DetailsCard'
import { UserDetailCardStyle } from '../Molecules.style';

const DeviceDetail = ({deviceDetail}) => {
      const deviceDetailKeys = deviceDetail && Object.keys(deviceDetail)

  return (
		deviceDetailKeys?.map((dataKey, index) => {
			return (
				deviceDetail?.[dataKey] && (
					<UserDetailCardStyle key={index}>
						<h2>{dataKey}</h2>
						<DetailsCard key={index} cardInfo={deviceDetail?.[dataKey]} />
					</UserDetailCardStyle>
				)
			);
		})
	);
}

export default DeviceDetail