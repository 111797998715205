import React from "react";
import { DetailsCardStyle } from "./DetailsCard.style";
import { Table } from "../../atoms";
import { useNavigate } from "react-router-dom";

const DetailsCard = ({ cardInfo, title }) => {
  const navigate = useNavigate();
  const handleRowClick = (id) => {
    id && navigate(`/issue-details/${id}`);
  };
  let cardSegments;
  if (Object.keys(cardInfo).length) {
    cardSegments =
      cardInfo?.constructor === Object
        ? Object.keys(cardInfo)
        : Object.keys(cardInfo[0]);
  }

  return (
    <DetailsCardStyle>
      <div className="container">
        <div
          className={`details-wrapper ${
            cardInfo?.constructor !== Object &&
            (title === "Service" || title === "Issues")
              ? "no-padding"
              : ""
          }`}
        >
          {Object.keys(cardInfo).length ? (
            typeof cardInfo === "string" ? (
              <p className="not_found">{cardInfo}</p>
            ) : cardInfo?.constructor !== Object &&
              title !== "Service" &&
              title !== "Issues" ? (
              <>
                {cardInfo?.map((segment, index) => (
                  <div
                    key={index}
                    className={`${
                      cardInfo.length === 1 || index === cardInfo.length - 1
                        ? "no_border"
                        : null
                    } info_border`}
                  >
                    {cardSegments?.map((subSegment, index) => (
                      <div key={index} className="info">
                        <h3>{subSegment}</h3>
                        <p>{segment?.[subSegment]}</p>
                      </div>
                    ))}
                  </div>
                ))}
                <p></p>
              </>
            ) : cardInfo?.constructor !== Object ? (
              <Table
                data={cardInfo}
                headers={cardSegments}
                handleRowClick={handleRowClick}
              />
            ) : (
              cardSegments?.map((segment, index) => {
                return (
                  segment !== "id" && (
                    <div key={index} className="info">
                      <h3>{segment}</h3>
                      <p>{cardInfo?.[segment]}</p>
                    </div>
                  )
                );
              })
            )
          ) : (
            <p className="not_found">No Data Found</p>
          )}
        </div>
      </div>
    </DetailsCardStyle>
  );
};

export default DetailsCard;
