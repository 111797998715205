import React from 'react'
import { Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";


const PrivateRoutes = ({children}) => {
    const { accounts } = useMsal();


  return accounts[0] ? children : <Navigate to="/" replace />;
}

export default PrivateRoutes

