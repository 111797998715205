import { SearchFilterStyle } from "./SearchFilter.Styles";

const SearchFilter = ({ setSearchQuery, placeholder }) => {
  return (
    <SearchFilterStyle>
      <label>{"Filter By"}</label>
      <div className="container">
        <input
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={placeholder}
        />
      </div>
    </SearchFilterStyle>
  );
};

export default SearchFilter;
