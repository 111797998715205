import { IoFilterSharp } from "react-icons/io5";
import { useState, useEffect, useRef } from "react";
import { SortCardStyle } from "./SortFilter.Style";
import { useComponentVisible } from "../../../utils/customHooks/useComponentVisible";

const FilterItems = ({
  detailsList,
  title,
  setFilterValue,
  setStatus,
  setReportingFilter,
  setResolutionFilter,
  checkboxValues,
  setCheckboxValues,
}) => {
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [appliedStatus, setAppliedStatus] = useState([0]);
  const [statusVisible, setStatusVisible] = useState(false);
  const statusRef = useRef(null);

  useComponentVisible(statusRef, setStatusVisible);

  const handleCheck = (e, id) => {
    var updatedList = [...appliedFilters];
    var updatedStatusList = [...appliedStatus];
    var newChecked = [...checkboxValues];
    if (title === "Filter By Status") {
      if (e.target.checked) {
        newChecked.push(e.target.value);
        if (id === 0 && updatedStatusList.includes(0)) {
          updatedStatusList = [0];
        } else {
          updatedStatusList = [...appliedStatus, id];
        }
      } else {
        let index = checkboxValues.indexOf(e.target.value);
        newChecked.splice(index, 1);
        updatedStatusList.splice(appliedStatus.indexOf(id), 1);
      }
      setAppliedStatus(updatedStatusList);
    } else {
      if (e.target.checked) {
        newChecked.push(e.target.value);
        updatedList = [...appliedFilters, e.target.value];
      } else {
        let index = checkboxValues.indexOf(e.target.value);
        newChecked.splice(index, 1);
        updatedList.splice(appliedFilters.indexOf(e.target.value), 1);
      }
      setAppliedFilters(updatedList);
    }
    setCheckboxValues(newChecked);
  };

  useEffect(() => {
    if (title === "Filter By Status") {
      setStatus(appliedStatus);
    }
    // eslint-disable-next-line
  }, [appliedFilters, appliedStatus, title]);

  useEffect(() => {
    if (appliedFilters.includes("Reporting date")) {
      setReportingFilter(true);
    } else if (!appliedFilters.includes("Reporting date")) {
      setReportingFilter(false);
    }
    // eslint-disable-next-line
  }, [appliedFilters]);

  useEffect(() => {
    if (appliedFilters.includes("Resolution date")) {
      setResolutionFilter(true);
    } else if (!appliedFilters.includes("Resolution date")) {
      setResolutionFilter(false);
    }
    // eslint-disable-next-line
  }, [appliedFilters]);

  return (
    <div ref={statusRef}>
      <div
        className="container"
        onClick={() => setStatusVisible(!statusVisible)}
      >
        <div className="item-container">
          <span className="title">{title}</span>
        </div>
        <div className="icon-style">
          <IoFilterSharp />
        </div>
      </div>

      {statusVisible ? (
        <div>
          <SortCardStyle>
            {/* <div
              className="list-item"
              onClick={() => setFilterValue(firstItem)}
            >
              <input
                key={0}
                id={0}
                name={firstItem}
                value={firstItem}
                onChange={(e) => handleCheck(e, firstItem)}
                type="checkbox"
                className="check"
                checked={firstItemValue}
              />
              <label forHtml={0}>{firstItem}</label>
            </div> */}
            {detailsList.map((item, index) => {
              const itemValue = checkboxValues.filter(
                (itemValue) => itemValue === item
              )[0];
              return (
                <div
                  className="list-item"
                  key={index}
                  onClick={() => setFilterValue(item)}
                >
                  <input
                    key={index}
                    id={index}
                    name={item}
                    value={item}
                    onChange={(e) => handleCheck(e, index)}
                    type="checkbox"
                    className="check"
                    checked={itemValue}
                  />
                  <label htmlFor={index}>{item}</label>
                </div>
              );
            })}
          </SortCardStyle>
        </div>
      ) : null}
    </div>
  );
};

export default FilterItems;
