import React, { useState, useEffect } from "react";
import { LineStyle, LegendToolTip } from "./LineStyle.style";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatCategoryBreakdown } from "../../../utils/formatChartData";
import { useSelector } from "react-redux";

const CategoryLines = () => {
	const [formattedData, setFormattedData] = useState();
	const [showTip, setShowTip] = useState(false);
	const [legendTip, setLegendTip] = useState({
		text: "",
		color: "",
		index: "",
	});
	const { threatBreakdown } = useSelector((state) => state.threatDashboard);
	useEffect(() => {
		if (Object.keys(threatBreakdown).length > 0) {
			let formated = formatCategoryBreakdown(
				threatBreakdown,
				undefined,
				setLegendTip,
				setShowTip
			);
			setFormattedData(formated);
		}
	}, [threatBreakdown]);
		return (
		<LineStyle>
			{showTip && legendTip?.description && (
				<LegendToolTip color={legendTip.color}>
					<p className="legend-tooltip">
						<span>{legendTip?.tip}: </span> 
						{legendTip?.description}	
					</p>
				</LegendToolTip>
			)}
			<HighchartsReact highcharts={Highcharts} options={formattedData} />
		</LineStyle>
	);
};

export default CategoryLines;
