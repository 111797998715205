import {
  GET_ZTNA_SERVICES_FAILURE,
  GET_ZTNA_SERVICES_SUCCESS,
  GET_ZTNA_SERVICES_START,
  GET_ZTNA_SERVICES_GROUP_START,
  GET_ZTNA_SERVICES_GROUP_FAILURE,
  GET_ZTNA_SERVICES_GROUP_SUCCESS,
  ADD_ZTNA_SERVICE_FAILURE,
  ADD_ZTNA_SERVICE_SUCCESS,
  ADD_ZTNA_SERVICE_START,
  ADD_ZTNA_SERVICE_IDENTIFIER_FAILURE,
  ADD_ZTNA_SERVICE_IDENTIFIER_SUCCESS,
  ADD_ZTNA_SERVICE_IDENTIFIER_START,
  ADD_ZTNA_SERVICES_GROUP_START,
  ADD_ZTNA_SERVICES_GROUP_FAILURE,
  ADD_ZTNA_SERVICES_GROUP_SUCCESS,
  UPLOAD_ZEN_FAILURE,
  UPLOAD_ZEN_SUCCESS,
  UPLOAD_ZEN_START,
  UPLOAD_FAZ_FAILURE,
  UPLOAD_FAZ_SUCCESS,
  UPLOAD_FAZ_START,
  GET_LOCATION_TYPES_FAILURE,
  GET_LOCATION_TYPES_SUCCESS,
  GET_LOCATION_TYPES_START,
  ADD_IP_FAILURE,
  ADD_IP_SUCCESS,
  ADD_IP_START,
  GET_OWNED_IPS_FAILURE,
  GET_OWNED_IPS_SUCCESS,
  GET_OWNED_IPS_START,
  UPDATE_OWNED_IPS_FAILURE,
  UPDATE_OWNED_IPS_SUCCESS,
  UPDATE_OWNED_IPS_START,
  GET_ZTNA_SERVICE_IDENTIFIERS_FAILURE,
  GET_ZTNA_SERVICE_IDENTIFIERS_SUCCESS,
  GET_ZTNA_SERVICE_IDENTIFIERS_START,
  ASSIGN_GROUP_TO_SERVICE_FAILURE,
  ASSIGN_GROUP_TO_SERVICE_SUCCESS,
  ASSIGN_GROUP_TO_SERVICE_START,
  GET_JOBS_RUNTIME_FAILURE,
  GET_JOBS_RUNTIME_SUCCESS,
  GET_JOBS_RUNTIME_START,
  GET_ACTIVE_SESSIONS_FAILURE,
  GET_ACTIVE_SESSIONS_SUCCESS,
  GET_ACTIVE_SESSIONS_START,
  DEAUTHENTICATE_SESSIONS_FAILURE,
  DEAUTHENTICATE_SESSIONS_SUCCESS,
  DEAUTHENTICATE_SESSIONS_START,
  GET_DEAUTH_REASONS_FAILURE,
  GET_DEAUTH_REASONS_SUCCESS,
  GET_DEAUTH_REASONS_START,
  GET_LINKED_AZUREAD_GROUPS_FAILURE,
  GET_LINKED_AZUREAD_GROUPS_SUCCESS,
  GET_LINKED_AZUREAD_GROUPS_START,
  SAVE_ACTIVE_USERS_START,
  SAVE_ACTIVE_USERS_SUCCESS,
  SAVE_ACTIVE_USERS_FAILURE,
  SAVE_ACTIVE_USERS_BY_COUNTRY_START,
  SAVE_ACTIVE_USERS_BY_COUNTRY_SUCCESS,
  SAVE_ACTIVE_USERS_BY_COUNTRY_FAILURE,
  SAVE_UPDATE_CONNECTIONS_START,
  SAVE_UPDATE_CONNECTIONS_SUCCESS,
  SAVE_UPDATE_CONNECTIONS_FAILURE,
  SAVE_UPDATE_CONNECTIONS_BY_DATE_START,
  SAVE_UPDATE_CONNECTIONS_BY_DATE_SUCCESS,
  SAVE_UPDATE_CONNECTIONS_BY_DATE_FAILURE,
} from "./ZtnaActionTypes";
import {
  getZtnaServiceGroup,
  getZtnaServices,
  addZtnaServiceGroup,
  addZtnaService,
  addZtnaServiceIdentifier,
  uploadZenFile,
  uploadFazFile,
  getAllLocationTypes,
  addIp,
  getAllOwnedIps,
  updateOwnedIps,
  getZtnaServiceIdentifiers,
  assignGroupToService,
  getJobsRuntime,
  getActiveSessions,
  deauthenticateSessions,
  getDeauthReasons,
  getLinkedAzureAdGroups,
  saveActiveUsers,
  saveActiveUsersByCountry,
  saveUpdateConnections,
  saveUpdateConnectionsByDate,
} from "../../../api/zte/ztna";
import { toast } from "react-toastify";

export const getZtnaServicesStart = () => {
  return {
    type: GET_ZTNA_SERVICES_START,
  };
};

export const getZtnaServicesSuccess = (payload) => {
  return {
    type: GET_ZTNA_SERVICES_SUCCESS,
    payload,
  };
};

export const getZtnaServicesFailed = (payload) => {
  return {
    type: GET_ZTNA_SERVICES_FAILURE,
    payload,
  };
};

export const ztnaServicesGet = () => async (dispatch) => {
  dispatch(getZtnaServicesStart());
  const { data, error } = await getZtnaServices();
  if (data && data?.statusCode === "90000") {
    dispatch(getZtnaServicesSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getZtnaServicesFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addZtnaServicesStart = () => {
  return {
    type: ADD_ZTNA_SERVICE_START,
  };
};

export const addZtnaServicesSuccess = (payload) => {
  return {
    type: ADD_ZTNA_SERVICE_SUCCESS,
    payload,
  };
};

export const addZtnaServicesFailed = (payload) => {
  return {
    type: ADD_ZTNA_SERVICE_FAILURE,
    payload,
  };
};

export const ztnaServicesAdd = (payload) => async (dispatch) => {
  dispatch(getZtnaServicesStart());
  const { data, error } = await addZtnaService(payload);
  if (data) {
    dispatch(addZtnaServicesSuccess(data));
    toast.success(data?.message);
  } else {
    dispatch(addZtnaServicesFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getZtnaServicesGroupStart = () => {
  return {
    type: GET_ZTNA_SERVICES_GROUP_START,
  };
};

export const getZtnaServicesGroupSuccess = (payload) => {
  return {
    type: GET_ZTNA_SERVICES_GROUP_SUCCESS,
    payload,
  };
};

export const getZtnaServicesGroupFailed = (payload) => {
  return {
    type: GET_ZTNA_SERVICES_GROUP_FAILURE,
    payload,
  };
};

export const ztnaServicesGroupGet = () => async (dispatch) => {
  dispatch(getZtnaServicesGroupStart());
  const { data, error } = await getZtnaServiceGroup();
  if (data && data?.statusCode === "90000") {
    dispatch(getZtnaServicesGroupSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getZtnaServicesGroupFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addZtnaServicesGroupStart = () => {
  return {
    type: ADD_ZTNA_SERVICES_GROUP_START,
  };
};

export const addZtnaServicesGroupSuccess = (payload) => {
  return {
    type: ADD_ZTNA_SERVICES_GROUP_SUCCESS,
    payload,
  };
};

export const addZtnaServicesGroupFailed = (payload) => {
  return {
    type: ADD_ZTNA_SERVICES_GROUP_FAILURE,
    payload,
  };
};

export const ztnaServicesGroupAdd = (payload) => async (dispatch) => {
  dispatch(addZtnaServicesGroupStart());
  const { data, error } = await addZtnaServiceGroup(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(addZtnaServicesGroupSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(addZtnaServicesGroupFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addZtnaServicesIdentifierStart = () => {
  return {
    type: ADD_ZTNA_SERVICE_IDENTIFIER_START,
  };
};

export const addZtnaServicesIdentifierSuccess = (payload) => {
  return {
    type: ADD_ZTNA_SERVICE_IDENTIFIER_SUCCESS,
    payload,
  };
};

export const addZtnaServicesIdentifierFailed = (payload) => {
  return {
    type: ADD_ZTNA_SERVICE_IDENTIFIER_FAILURE,
    payload,
  };
};

export const ztnaServicesIdentifierAdd = (payload) => async (dispatch) => {
  dispatch(addZtnaServicesIdentifierStart());
  const { data, error } = await addZtnaServiceIdentifier(payload);
  if (data & (data?.statusCode === "90000")) {
    dispatch(addZtnaServicesIdentifierSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(addZtnaServicesIdentifierFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getZtnaServicesIdentifiersStart = () => {
  return {
    type: GET_ZTNA_SERVICE_IDENTIFIERS_START,
  };
};

export const getZtnaServicesIdentifiersSuccess = (payload) => {
  return {
    type: GET_ZTNA_SERVICE_IDENTIFIERS_SUCCESS,
    payload,
  };
};

export const getZtnaServicesIdentifiersFailed = (payload) => {
  return {
    type: GET_ZTNA_SERVICE_IDENTIFIERS_FAILURE,
    payload,
  };
};

export const ztnaServicesIdentifiersGet = (payload) => async (dispatch) => {
  dispatch(getZtnaServicesIdentifiersStart());
  const { data, error } = await getZtnaServiceIdentifiers(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(getZtnaServicesIdentifiersSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getZtnaServicesIdentifiersFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const uploadZenStart = () => {
  return {
    type: UPLOAD_ZEN_START,
  };
};

export const uploadZenSuccess = (payload) => {
  return {
    type: UPLOAD_ZEN_SUCCESS,
    payload,
  };
};

export const uploadZenFailed = (payload) => {
  return {
    type: UPLOAD_ZEN_FAILURE,
    payload,
  };
};

export const uploadZenPost = (file) => async (dispatch) => {
  dispatch(uploadZenStart());
  const { data, error } = await uploadZenFile(file);
  if (data && data?.statusCode === "90000") {
    dispatch(uploadZenSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else if (!error.response) {
    dispatch(uploadZenFailed("Oops, something went wrong! Please try again"));
    toast.error("Oops something went wrong!! Try again later");
  } else {
    dispatch(uploadZenFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addIpStart = () => {
  return {
    type: UPLOAD_FAZ_START,
  };
};

export const addIpSuccess = (payload) => {
  return {
    type: UPLOAD_FAZ_SUCCESS,
    payload,
  };
};

export const addIpFailed = (payload) => {
  return {
    type: UPLOAD_FAZ_FAILURE,
    payload,
  };
};

export const addIpPost = (file) => async (dispatch) => {
  dispatch(addIpStart());
  const { data, error } = await addIp(file);
  if (data && data?.statusCode === "90000") {
    dispatch(addIpSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else if (!error.response) {
    dispatch(addIpFailed("Oops, something went wrong! Please try again"));
    toast.error("Oops something went wrong!! Try again later");
  } else {
    dispatch(addIpFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAllOwnedIpsStart = () => {
  return {
    type: GET_OWNED_IPS_START,
  };
};

export const getAllOwnedIpsSuccess = (payload) => {
  return {
    type: GET_OWNED_IPS_SUCCESS,
    payload,
  };
};

export const getAllOwnedIpsFailed = (payload) => {
  return {
    type: GET_OWNED_IPS_FAILURE,
    payload,
  };
};

export const getOwnedIps = () => async (dispatch) => {
  dispatch(getAllOwnedIpsStart());
  const { data, error } = await getAllOwnedIps();
  if (data && data?.statusCode === "90000") {
    dispatch(getAllOwnedIpsSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAllOwnedIpsSuccess(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const updateOwnedIpsStart = () => {
  return {
    type: UPDATE_OWNED_IPS_START,
  };
};

export const updateOwnedIpsSuccess = (payload) => {
  return {
    type: UPDATE_OWNED_IPS_SUCCESS,
    payload,
  };
};

export const updateOwnedIpsFailed = (payload) => {
  return {
    type: UPDATE_OWNED_IPS_FAILURE,
    payload,
  };
};

export const updateAllOwnedIps = (payload) => async (dispatch) => {
  dispatch(getAllOwnedIpsStart());
  const { data, error } = await updateOwnedIps(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(getAllOwnedIpsSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAllOwnedIpsSuccess(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getAllLocationsStart = () => {
  return {
    type: GET_LOCATION_TYPES_START,
  };
};

export const getAllLocationsSuccess = (payload) => {
  return {
    type: GET_LOCATION_TYPES_SUCCESS,
    payload,
  };
};

export const getAllLocationsFailed = (payload) => {
  return {
    type: GET_LOCATION_TYPES_FAILURE,
    payload,
  };
};

export const getAllLocations = () => async (dispatch) => {
  dispatch(getAllLocationsStart());
  const { data, error } = await getAllLocationTypes();
  if (data && data?.statusCode === "90000") {
    dispatch(getAllLocationsSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getAllLocationsFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const uploadFazStart = () => {
  return {
    type: ADD_IP_START,
  };
};

export const uploadFazSuccess = (payload) => {
  return {
    type: ADD_IP_SUCCESS,
    payload,
  };
};

export const uploadFazFailed = (payload) => {
  return {
    type: ADD_IP_FAILURE,
    payload,
  };
};

export const uploadFazPost = (payload) => async (dispatch) => {
  dispatch(uploadFazStart());
  const { data, error } = await uploadFazFile(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(uploadFazSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else if (!error.response) {
    dispatch(uploadFazFailed("Oops, something went wrong! Please try again"));
    toast.error("Oops something went wrong!! Try again later");
  } else {
    dispatch(uploadFazFailed(error.response.data.error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const assignGroupToServiceStart = () => {
  return {
    type: ASSIGN_GROUP_TO_SERVICE_START,
  };
};

export const assignGroupToServiceSuccess = (payload) => {
  return {
    type: ASSIGN_GROUP_TO_SERVICE_SUCCESS,
    payload,
  };
};

export const assignGroupToServiceFailed = (payload) => {
  return {
    type: ASSIGN_GROUP_TO_SERVICE_FAILURE,
    payload,
  };
};

export const assignGroupToServicePost = (payload) => async (dispatch) => {
  dispatch(getZtnaServicesStart());
  const { data, error } = await assignGroupToService(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(addZtnaServicesSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else if (!error.response) {
    dispatch(addZtnaServicesFailed(error));
    toast.error("Oops something went wrong!! Try again later");
  } else {
    dispatch(addZtnaServicesFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getJobsRuntimeStart = () => {
  return {
    type: GET_JOBS_RUNTIME_START,
  };
};

export const getJobsRuntimeSuccess = (payload) => {
  return {
    type: GET_JOBS_RUNTIME_SUCCESS,
    payload,
  };
};

export const getJobsRuntimeFailed = (payload) => {
  return {
    type: GET_JOBS_RUNTIME_FAILURE,
    payload,
  };
};

export const getJobsLastRuntime = () => async (dispatch) => {
  dispatch(getJobsRuntimeStart());
  const { data, error } = await getJobsRuntime();
  if (data && data?.statusCode === "90000") {
    dispatch(getJobsRuntimeSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getJobsRuntimeFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getActiveSessionsStart = () => {
  return {
    type: GET_ACTIVE_SESSIONS_START,
  };
};

export const getActiveSessionsSuccess = (payload) => {
  return {
    type: GET_ACTIVE_SESSIONS_SUCCESS,
    payload,
  };
};

export const getActiveSessionsFailed = (payload) => {
  return {
    type: GET_ACTIVE_SESSIONS_FAILURE,
    payload,
  };
};

export const getAllActiveSessions =
  (currentPage, searchQuery) => async (dispatch) => {
    dispatch(getActiveSessionsStart());
    const { data, error } = await getActiveSessions(currentPage, searchQuery);
    if (data && data?.statusCode === "90000") {
      dispatch(getActiveSessionsSuccess(data));
    } else if (data?.statusCode === "10001") {
      toast.error(data.message);
    } else {
      dispatch(getActiveSessionsFailed(error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const deauthenticateSessionsStart = () => {
  return {
    type: DEAUTHENTICATE_SESSIONS_START,
  };
};

export const deauthenticateSessionsSuccess = (payload) => {
  return {
    type: DEAUTHENTICATE_SESSIONS_SUCCESS,
    payload,
  };
};

export const deauthenticateSessionsFailed = (payload) => {
  return {
    type: DEAUTHENTICATE_SESSIONS_FAILURE,
    payload,
  };
};

export const deauthenticateUserSessions =
  (id, reasonId) => async (dispatch) => {
    dispatch(deauthenticateSessionsStart());
    const { data, error } = await deauthenticateSessions(id, reasonId);
    if (data && data?.statusCode === "90000") {
      dispatch(deauthenticateSessionsSuccess(data));
      toast.success(data?.message);
    } else if (data?.statusCode === "10001") {
      toast.error(data.message);
    } else {
      dispatch(deauthenticateSessionsFailed(error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const getDeauthReasonsStart = () => {
  return {
    type: GET_DEAUTH_REASONS_START,
  };
};

export const getDeauthReasonsSuccess = (payload) => {
  return {
    type: GET_DEAUTH_REASONS_SUCCESS,
    payload,
  };
};

export const getDeauthReasonsFailed = (payload) => {
  return {
    type: GET_DEAUTH_REASONS_FAILURE,
    payload,
  };
};

export const getListDeauthReasons = () => async (dispatch) => {
  dispatch(getDeauthReasonsStart());
  const { data, error } = await getDeauthReasons();
  if (data && data?.statusCode === "90000") {
    dispatch(getDeauthReasonsSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getDeauthReasonsFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getLinkedAzureGroupsStart = () => {
  return {
    type: GET_LINKED_AZUREAD_GROUPS_START,
  };
};

export const getLinkedAzureGroupsSuccess = (payload) => {
  return {
    type: GET_LINKED_AZUREAD_GROUPS_SUCCESS,
    payload,
  };
};

export const getLinkedAzureGroupsFailed = (payload) => {
  return {
    type: GET_LINKED_AZUREAD_GROUPS_FAILURE,
    payload,
  };
};

export const getLinkedAzureGroups = (searchQuery) => async (dispatch) => {
  dispatch(getLinkedAzureGroupsStart());
  const { data, error } = await getLinkedAzureAdGroups(searchQuery);
  if (data && data?.statusCode === "90000") {
    dispatch(getLinkedAzureGroupsSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(getLinkedAzureGroupsFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const saveActiveUsersStart = () => {
  return {
    type: SAVE_ACTIVE_USERS_START,
  };
};

export const saveActiveUsersSuccess = (payload) => {
  return {
    type: SAVE_ACTIVE_USERS_SUCCESS,
    payload,
  };
};

export const saveActiveUsersFailed = (payload) => {
  return {
    type: SAVE_ACTIVE_USERS_FAILURE,
    payload,
  };
};

export const postSaveActiveUsers = (date) => async (dispatch) => {
  dispatch(saveActiveUsersStart());
  const { data, error } = await saveActiveUsers(date);
  if (data && data?.statusCode === "90000") {
    dispatch(saveActiveUsersSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(saveActiveUsersFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const saveActiveUsersByCountryStart = () => {
  return {
    type: SAVE_ACTIVE_USERS_BY_COUNTRY_START,
  };
};

export const saveActiveUsersByCountrySuccess = (payload) => {
  return {
    type: SAVE_ACTIVE_USERS_BY_COUNTRY_SUCCESS,
    payload,
  };
};

export const saveActiveUsersByCountryFailed = (payload) => {
  return {
    type: SAVE_ACTIVE_USERS_BY_COUNTRY_FAILURE,
    payload,
  };
};

export const postSaveActiveUsersByCountry = (date) => async (dispatch) => {
  dispatch(saveActiveUsersByCountryStart());
  const { data, error } = await saveActiveUsersByCountry(date);
  if (data && data?.statusCode === "90000") {
    dispatch(saveActiveUsersByCountrySuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(saveActiveUsersByCountryFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const saveUpdateConnectionsStart = () => {
  return {
    type: SAVE_UPDATE_CONNECTIONS_START,
  };
};

export const saveUpdateConnectionsSuccess = (payload) => {
  return {
    type: SAVE_UPDATE_CONNECTIONS_SUCCESS,
    payload,
  };
};

export const saveUpdateConnectionsFailed = (payload) => {
  return {
    type: SAVE_UPDATE_CONNECTIONS_FAILURE,
    payload,
  };
};

export const postSaveUpdateConnections = () => async (dispatch) => {
  dispatch(saveUpdateConnectionsStart());
  const { data, error } = await saveUpdateConnections();
  if (data && data?.statusCode === "90000") {
    dispatch(saveUpdateConnectionsSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(saveUpdateConnectionsFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};


export const saveUpdateConnectionsByDateStart = () => {
  return {
    type: SAVE_UPDATE_CONNECTIONS_BY_DATE_START,
  };
};

export const saveUpdateConnectionsByDateSuccess = (payload) => {
  return {
    type: SAVE_UPDATE_CONNECTIONS_BY_DATE_SUCCESS,
    payload,
  };
};

export const saveUpdateConnectionsByDateFailed = (payload) => {
  return {
    type: SAVE_UPDATE_CONNECTIONS_BY_DATE_FAILURE,
    payload,
  };
};

export const postSaveUpdateConnectionsByDate = (date) => async (dispatch) => {
  dispatch(saveUpdateConnectionsByDateStart());
  const { data, error } = await saveUpdateConnectionsByDate(date);
  if (data && data?.statusCode === "90000") {
    dispatch(saveUpdateConnectionsByDateSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(saveUpdateConnectionsByDateFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
