import React, { useEffect, useState } from "react";
import { Table } from "../components/atoms";
import { Field } from "../components/molecules";
import { SearchStyle } from "./Pages.style";
import { searchDashboardByDevice } from "../Redux/Zte/Device/DeviceActions";
import { searchDashboardByUser } from "../Redux/Zte/ZteUsers/ZteUsersActions";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/molecules/Layout";
import Toggle from "../components/atoms/Toggle";
import { structureData } from "../utils/FormatData";
import { useNavigate } from "react-router-dom";

const Search = () => {
	const [openTab, setOpenTab] = useState("users");
	const [searchParams, setSearchParams] = useState({ search: "" });
	const { devices, searchingDevices } = useSelector((state) => state.devices);
	const { users, searchingForUsers } = useSelector((state) => state.users);
	const [currentPage, setCurrentPage] = useState(1);

	const tabToggle = (tab) => {
		setOpenTab(tab);
		setCurrentPage(1);
		setSearchParams({ search: "" });
	};
	const handleSearch = (e) => {
		setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
		setCurrentPage(1);
	};
	const usersHeaders = ["Display Name", "User Principal Name", "Email"];

	const devicesHeaders = [
		"Device Name",
		"FortiClient Version",
		"Managed by EMS",
	];

	const navigate = useNavigate();
	const userResults = users?.results;
	const devicesResults = devices?.results;

	const handleRowClick = (id) => {
		if (openTab === "users") {
			navigate(`/user-details/${id}`);
		} else {
			navigate(`/device-details/${id}`);
		}
	};

	const dispatch = useDispatch();

	useEffect(() => {
		const delayDbounceFn = setTimeout(() => {
			if (openTab === "devices") {
				dispatch(searchDashboardByDevice(currentPage, searchParams.search));
			} else {
				dispatch(searchDashboardByUser(currentPage, searchParams.search));
			}
		}, [250]);
		return () => clearTimeout(delayDbounceFn);
	}, [currentPage, dispatch, openTab, searchParams]);

	return (
		<Layout>
			<SearchStyle>
				<div className="top-wrapper">
					<div className="search">
						<Field
							inputType="text"
							placeholder={`Search by ${openTab}`}
							handleChange={handleSearch}
							formData={searchParams}
							name="search"
						/>
					</div>
					<Toggle
						toggle={tabToggle}
						toggleOptions={["users", "devices"]}
						tab={openTab}
					/>
				</div>
				<div className="table-wrapper">
					<Table
						data={
							openTab === "devices"
								? structureData("devices", devicesResults)
								: structureData("users", userResults)
						}
						headers={openTab === "devices" ? devicesHeaders : usersHeaders}
						loading={
							openTab === "devices" ? searchingDevices : searchingForUsers
						}
						currentPage={currentPage}
						pageSize={
							openTab === "devices" ? devices?.pageSize : users?.pageSize
						}
						setCurrentPage={setCurrentPage}
						totalCount={
							openTab === "devices" ? devices?.totalCount : users?.totalCount
						}
						handleRowClick={handleRowClick}
					/>
				</div>
			</SearchStyle>
		</Layout>
	);
};

export default Search;
