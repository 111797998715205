import {
	GET_DROPDOWN_INFO_FAILED,
	GET_DROPDOWN_INFO_SUCCESS,
	GET_DROPDOWN_INFO_START,
	UPDATE_THREATS_FAILED,
	UPDATE_THREATS_START,
	UPDATE_THREATS_SUCCESS,
	GET_THREATSOURCE_BREAKDOWN_FAILED,
	GET_THREATSOURCE_BREAKDOWN_START,
	GET_THREATSOURCE_BREAKDOWN_SUCCESS,
	GET_THREATSOURCE_ENTRIES_FAILED,
	GET_THREATSOURCE_ENTRIES_START,
	GET_THREATSOURCE_ENTRIES_SUCCESS,
	GET_THREATSOURCE_FAILED,
	GET_THREATSOURCE_START,
	GET_THREATSOURCE_SUCCESS,
	RESET_THREATSOURCE_BREAKDOWN,
} from "./ThreatDashboardActionsTypes";

const initialState = {
	fetchingDropDown: false,
	fetchingDropDownError: false,
	dropDown: [],
	updatingThreats: false,
	isUpdateSuccessful: false,
	fetchingThreatBreakdown: false,
	fetchingThreatBreakdownError: false,
	threatBreakdown: {},
	threatEntries: {},
	fetchingThreatEntries: false,
	fetchingThreatEntriesError: false,
	threatSource: [],
	fetchingThreatSource: false,
	fetchingThreatSourceError: false,
};

const threatDashboardReducer = (state=initialState,{type,payload})=>{
    switch (type) {
			case GET_DROPDOWN_INFO_START:
				return {
					...state,
					fetchingDropDown: true,
					fetchingDropDownError: false,
					dropDown: [],
				};
			case GET_DROPDOWN_INFO_SUCCESS:
				return {
					...state,
					fetchingDropDown: false,
					fetchingDropDownError: false,
					dropDown: payload,
				};
			case GET_DROPDOWN_INFO_FAILED:
				return {
					...state,
					fetchingDropDown: false,
					fetchingDropDownError: true,
					dropDown: [],
				};
			case GET_THREATSOURCE_BREAKDOWN_START:
				return {
					...state,
					fetchingThreatBreakdown: true,
					fetchingThreatBreakdownError: false,
					threatBreakdown: {},
				};
			case GET_THREATSOURCE_BREAKDOWN_SUCCESS:
				return {
					...state,
					fetchingThreatBreakdown: false,
					fetchingThreatBreakdownError: false,
					threatBreakdown: payload,
				};
			case GET_THREATSOURCE_BREAKDOWN_FAILED:
				return {
					...state,
					fetchingThreatBreakdown: false,
					fetchingThreatBreakdownError: true,
					threatBreakdown: {},
				};
			case RESET_THREATSOURCE_BREAKDOWN:
				return {
					...state,
					fetchingThreatBreakdown: false,
					fetchingThreatBreakdownError: false,
					threatBreakdown: {},
				};
			case UPDATE_THREATS_START:
				return {
					...state,
					updatingThreats: true,
					isUpdateSuccessful: false,
				};
			case UPDATE_THREATS_SUCCESS:
				return {
					...state,
					updatingThreats: false,
					isUpdateSuccessful: true,
				};
			case UPDATE_THREATS_FAILED:
				return {
					...state,
					updatingThreats: false,
					isUpdateSuccessful: false,
				};
			case GET_THREATSOURCE_ENTRIES_START:
				return {
					...state,
					fetchingThreatEntries: true,
					fetchingThreatEntriesError: false,
					threatEntries: {},
				};
			case GET_THREATSOURCE_ENTRIES_SUCCESS:
				return {
					...state,
					fetchingThreatEntries: false,
					fetchingThreatEntriesError: false,
					threatEntries: payload,
				};
			case GET_THREATSOURCE_ENTRIES_FAILED:
				return {
					...state,
					fetchingThreatEntries: false,
					fetchingThreatEntriesError: true,
					threatEntries: {},
				};
			case GET_THREATSOURCE_START:
				return {
					...state,
					fetchingThreatSource: true,
					fetchingThreatSourceError: false,
					threatSource: [],
				};
			case GET_THREATSOURCE_SUCCESS:
				return {
					...state,
					fetchingThreatSource: false,
					fetchingThreatSourceError: false,
					threatSource: payload,
				};
			case GET_THREATSOURCE_FAILED:
				return {
					...state,
					fetchingThreatSource: false,
					fetchingThreatSourceError: true,
					threatSource: [],
				};
			default:
				return state;
		}
}

export default threatDashboardReducer