import API from "../axio";

export const getActiveUsersPerService = async () => {
	try {
		const { data } = await API.get(`/ZtnaDashboard/users-active-per-service`);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const getAssignedUsersPerService = async () => {
	try {
		const { data } = await API.get(`/ZtnaDashboard/users-assigned-per-service`);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const getActiveVsAssignedUsers = async () => {
	try {
		const { data } = await API.get(
			`/ZtnaDashboard/active-users-vs-assigned-users-per-service`
		);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const averageConnectionLength = async () => {
	try {
		const { data } = await API.get(
			`/ZtnaDashboard/average-connection-length-information`
		);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const activeUsersByMonth = async () => {
	try {
		const { data } = await API.get(
			`ZtnaDashboard/active-users-count-by-month`
		);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const concurrentConnection = async () => {
	try {
		const { data } = await API.get(
			`ZtnaDashboard/active-users-count-by-month`
		);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const weeklyConcurrentConnection = async (year) => {
	try {
		const { data } = await API.get(
			`ZtnaDashboard/weekly-average-concurrent-unique-connections?year=${year}`
		);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const dailyConcurrentConnection = async (year) => {
	try {
		const { data } = await API.get(
			`ZtnaDashboard/average-concurrent-unique-connections-by-day-of-week?year=${year}`
		);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const activeMonthlyUser = async () => {
	try {
		const { data } = await API.get(`ZtnaDashboard/active-users-by-month`);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const services = async () => {
	try {
		const { data } = await API.get(`/Ztna/list-services`);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
export const countryActiveMonthlyUsers = async (year) => {
	try {
		const { data } = await API.get(`/ZtnaDashboard/country-active-users-by-month?year=${year}`);
		return {
			data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error,
		};
	}
};
