import { FilterStyle } from "./SortFilter.Style";

const FilterItemsDate = ({ setFilterValue, labelTitle }) => {
  return (
    <FilterStyle>
      <div className="date-filters">
        <label>{labelTitle}</label>
        <input
          placeholder="Filter"
          id={labelTitle}
          type="datetime-local"
          name={labelTitle}
          onChange={(e) => setFilterValue(e.target.value)}
        />
      </div>
    </FilterStyle>
  );
};

export default FilterItemsDate;
