import {
  GET_ACTIVE_USER_REGION_HISTORY_FAILURE,
  GET_ACTIVE_USER_REGION_HISTORY_SUCCESS,
  GET_ACTIVE_USER_SERVICE_HISTORY_FAILURE,
  GET_ACTIVE_USER_SERVICE_HISTORY_SUCCESS,
  GET_SERVICES_FAILURE,
} from "./UsageActionTypes";
import { GET_SERVICES_SUCCESS } from "./UsageActionTypes";
import {
  GET_ACTIVE_VS_ASSIGNED_USERS_START,
  GET_ACTIVE_USERS_PER_SERVICE_START,
  GET_ASSIGNED_USERS_PER_SERVICE_START,
  GET_ACTIVE_USERS_PER_SERVICE_SUCCESS,
  GET_ACTIVE_USERS_PER_SERVICE_FAILURE,
  GET_ASSIGNED_USERS_PER_SERVICE_SUCCESS,
  GET_ASSIGNED_USERS_PER_SERVICE_FAILURE,
  GET_ACTIVE_VS_ASSIGNED_USERS_SUCCESS,
  GET_ACTIVE_VS_ASSIGNED_USERS_FAILURE,
  GET_ACTIVE_CONNECTION_LENGTH_SUCCESS,
  GET_ACTIVE_CONNECTION_LENGTH_FAILURE,
  GET_ACTIVE_MONTHLY_USERS_SUCCESS,
  GET_WEEKLY_CONCURRENT_CONNECTION_SUCCESS,
  GET_WEEKLY_CONCURRENT_CONNECTION_FAILURE,
  GET_WEEKLY_CONCURRENT_CONNECTION_START,
  GET_DAILY_CONCURRENT_CONNECTION_SUCCESS,
  GET_DAILY_CONCURRENT_CONNECTION_FAILURE,
  GET_DAILY_CONCURRENT_CONNECTION_START,
  GET_ACTIVE_MONTHLY_USERS_FAILURE,
  GET_ACTIVE_MONTHLY_USERS_START,
  AVERAGE_ZTNA_USAGE_TIME_FAILURE,
  AVERAGE_ZTNA_USAGE_TIME_SUCCESS,
  AVERAGE_ZTNA_USAGE_TIME_START,
  MONTHLY_ACTIVE_USERS_FAILURE,
  MONTHLY_ACTIVE_USERS_START,
  MONTHLY_ACTIVE_USERS_SUCCESS,
  GET_COUNTRY_MONTHLY_ACTIVE_USERS_FAILURE,
  GET_COUNTRY_MONTHLY_ACTIVE_USERS_START,
  GET_COUNTRY_MONTHLY_ACTIVE_USERS_SUCCESS,
  GET_HOURLY_CONNECTION_TREND_START,
  GET_HOURLY_CONNECTION_TREND_FAILURE,
  GET_HOURLY_CONNECTION_TREND_SUCCESS,
} from "./UsageActionTypes";

const initialState = {
  activeUsers: [],
  isLoadingActiveUsers: false,
  activeUsersError: null,
  isLoadingAssignedUsers: false,
  assignedUsers: [],
  assignedUsersError: null,
  activeVsAssignedUsers: [],
  isLoadingActiveVsAssignedUsers: false,
  activeVsAssignedUsersError: null,
  activeConnectionLength: [],
  isLoadingactiveConnectionLength: false,
  activeConnectionLengthError: null,
  isLoadingMonthlyActiveUsers: false,
  monthlyActiveUsers: [],
  mothlyActiveUsersError: null,
  isLoadingWeeklyConcurrent: false,
  weeklyConcurrent: [],
  weeklyConcurrentError: null,
  isLoadingDailyConcurrent: false,
  dailyConcurrent: {},
  dailyConcurrentError: null,
  averageUsageTime: [],
  isLoadingAverageUsageTime: false,
  averageUsageTimeError: null,
  monthlyActiveUser: [],
  isLoadingMonthlyActiveUser: false,
  monthlyActiveUserError: null,
  services: [],
  serivcesError: null,
  countryMonthlyActiveUser: {},
  isLoadingCountryMonthlyActiveUser: false,
  countryMonthlyActiveUserError: null,
  serviceHistoryError: null,
  regionHistoryError: null,
  serviceHistory: {},
  regionHistory: {},
  hourlyConnectionTrendData: {},
  fetchingHourlyConnectionTrendDataError: null,
  fetchingHourlyConnectionTrendData: false,
};

const usageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_USERS_PER_SERVICE_START:
      return {
        ...state,
        isLoadingActiveUsers: true,
        activeUsers: [],
        activeUsersError: null,
      };
    case GET_ACTIVE_USERS_PER_SERVICE_SUCCESS:
      return {
        ...state,
        isLoadingActiveUsers: false,
        activeUsers: action.payload,
        activeUsersError: null,
      };
    case GET_ACTIVE_USERS_PER_SERVICE_FAILURE:
      return {
        ...state,
        isLoadingActiveUsers: false,
        activeUsers: [],
        activeUsersError: action.payload,
      };
    case GET_ASSIGNED_USERS_PER_SERVICE_START:
      return {
        ...state,
        isLoadingAssignedUsers: true,
        assignedUsers: action.payload,
        assignedUsersError: null,
      };
    case GET_ASSIGNED_USERS_PER_SERVICE_SUCCESS:
      return {
        ...state,
        isLoadingAssignedUsers: false,
        assignedUsers: action.payload,
        assignedUsersError: null,
      };
    case GET_ASSIGNED_USERS_PER_SERVICE_FAILURE:
      return {
        ...state,
        isLoadingAssignedUsers: false,
        assignedUsers: [],
        assignedUsersError: action.payload,
      };
    case GET_ACTIVE_VS_ASSIGNED_USERS_START:
      return {
        ...state,
        isLoadingActiveVsAssignedUsers: true,
        activeVsAssignedUsers: action.payload,
        activeVsAssignedUsersError: null,
      };
    case GET_ACTIVE_VS_ASSIGNED_USERS_SUCCESS:
      return {
        ...state,
        isLoadingActiveVsAssignedUsers: false,
        activeVsAssignedUsers: action.payload,
        activeVsAssignedUsersError: null,
      };
    case GET_ACTIVE_VS_ASSIGNED_USERS_FAILURE:
      return {
        ...state,
        isLoadingActiveVsAssignedUsers: false,
        activeVsAssignedUsers: [],
        activeVsAssignedUsersError: action.payload,
      };
    case GET_ACTIVE_CONNECTION_LENGTH_SUCCESS:
      return {
        ...state,
        activeConnectionLength: action.payload,
        activeConnectionLengthError: null,
      };
    case GET_ACTIVE_CONNECTION_LENGTH_FAILURE:
      return {
        ...state,
        activeConnectionLength: [],
        activeConnectionLengthError: action.payload,
      };
    case GET_ACTIVE_MONTHLY_USERS_START:
      return {
        ...state,
        isLoadingMonthlyActiveUsers: true,
        monthlyActiveUsers: [],
        mothlyActiveUsersError: null,
      };
    case GET_ACTIVE_MONTHLY_USERS_SUCCESS:
      return {
        ...state,
        isLoadingMonthlyActiveUsers: false,
        monthlyActiveUsers: action.payload,
        mothlyActiveUsersError: null,
      };
    case GET_ACTIVE_MONTHLY_USERS_FAILURE:
      return {
        ...state,
        isLoadingMonthlyActiveUsers: false,
        monthlyActiveUsers: [],
        mothlyActiveUsersError: action.payload,
      };
    case GET_WEEKLY_CONCURRENT_CONNECTION_START:
      return {
        ...state,
        isLoadingWeeklyConcurrent: true,
        weeklyConcurrent: [],
        weeklyConcurrentError: null,
      };
    case GET_WEEKLY_CONCURRENT_CONNECTION_SUCCESS:
      return {
        ...state,
        isLoadingWeeklyConcurrent: false,
        weeklyConcurrent: action.payload,
        weeklyConcurrentError: null,
      };
    case GET_WEEKLY_CONCURRENT_CONNECTION_FAILURE:
      return {
        ...state,
        isLoadingWeeklyConcurrent: false,
        weeklyConcurrent: [],
        weeklyConcurrentError: action.payload,
      };
    case GET_DAILY_CONCURRENT_CONNECTION_START:
      return {
        ...state,
        isLoadingDailyConcurrent: true,
        dailyConcurrent: [],
        dailyConcurrentError: null,
      };
    case GET_DAILY_CONCURRENT_CONNECTION_SUCCESS:
      return {
        ...state,
        isLoadingDailyConcurrent: false,
        dailyConcurrent: action.payload,
        dailyConcurrentError: null,
      };
    case GET_DAILY_CONCURRENT_CONNECTION_FAILURE:
    case AVERAGE_ZTNA_USAGE_TIME_START:
      return {
        ...state,
        isLoadingAverageUsageTime: true,
        averageUsageTime: [],
        averageUsageTimeError: null,
      };
    case AVERAGE_ZTNA_USAGE_TIME_SUCCESS:
      return {
        ...state,
        isLoadingAverageUsageTime: false,
        averageUsageTime: action.payload,
        averageUsageTimeError: null,
      };
    case AVERAGE_ZTNA_USAGE_TIME_FAILURE:
      return {
        ...state,
        isLoadingAverageUsageTime: false,
        averageUsageTime: [],
        averageUsageTimeError: action.payload,
      };
    case MONTHLY_ACTIVE_USERS_START:
      return {
        ...state,
        isLoadingMonthlyActiveUser: true,
        monthlyActiveUser: [],
        monthlyActiveUserError: null,
      };
    case MONTHLY_ACTIVE_USERS_SUCCESS:
      return {
        ...state,
        isLoadingMonthlyActiveUser: false,
        monthlyActiveUser: action.payload,
        monthlyActiveUserError: null,
      };
    case MONTHLY_ACTIVE_USERS_FAILURE:
      return {
        ...state,
        isLoadingMonthlyActiveUser: false,
        monthlyActiveUser: [],
        monthlyActiveUserError: action.payload,
      };
    case GET_COUNTRY_MONTHLY_ACTIVE_USERS_START:
      return {
        ...state,
        isLoadingCountryMonthlyActiveUser: true,
        countryMonthlyActiveUser: [],
        countryMonthlyActiveUserError: null,
      };
    case GET_COUNTRY_MONTHLY_ACTIVE_USERS_SUCCESS:
      return {
        ...state,
        isLoadingCountryMonthlyActiveUser: false,
        countryMonthlyActiveUser: action.payload,
        countryMonthlyActiveUserError: null,
      };
    case GET_COUNTRY_MONTHLY_ACTIVE_USERS_FAILURE:
      return {
        ...state,
        isLoadingCountryMonthlyActiveUser: false,
        countryMonthlyActiveUser: [],
        countryMonthlyActiveUserError: action.payload,
      };
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        serivcesError: null,
      };
    case GET_SERVICES_FAILURE:
      return {
        ...state,
        services: [],
        serivcesError: action.payload,
      };
    case GET_ACTIVE_USER_SERVICE_HISTORY_SUCCESS:
      return {
        ...state,
        serviceHistory: action.payload,
        serviceHistoryError: null,
      };
    case GET_ACTIVE_USER_SERVICE_HISTORY_FAILURE:
    case GET_ACTIVE_USER_REGION_HISTORY_SUCCESS:
      return {
        ...state,
        regionHistory: action.payload,
        regionHistoryError: null,
      };
    case GET_ACTIVE_USER_REGION_HISTORY_FAILURE:
      return {
        ...state,
        regionHistory: {},
        regionHistoryError: action.payload,
      };
    case GET_HOURLY_CONNECTION_TREND_START:
      return {
        ...state,
        fetchingHourlyConnectionTrendData: true,
        fetchingHourlyConnectionTrendDataError: null,
      };
    case GET_HOURLY_CONNECTION_TREND_SUCCESS:
      return {
        ...state,
        fetchingHourlyConnectionTrendData: false,
        fetchingHourlyConnectionTrendDataError: null,
        hourlyConnectionTrendData: action.payload.data,
      };
    case GET_HOURLY_CONNECTION_TREND_FAILURE:
      return {
        ...state,
        fetchingHourlyConnectionTrendData: false,
        fetchingHourlyConnectionTrendDataError: action.payload,
        hourlyConnectionTrendData: [],
      };
    default:
      return state;
  }
};

export default usageReducer;
