import React from "react";

import { ButtonStyle } from "./Atoms.Style";

const Button = ({ children, fill, large, onclick, disabled, id }) => {
	return (
        <ButtonStyle fill={fill} large={large} onClick={onclick} disabled={disabled} id={id}>
	 {children} 
        </ButtonStyle>
	);
};

export default Button;
