import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../atoms";
import { AdminCardStyles } from "./AdminCard.style";
import SearchDropDown from "./SearchDropdown";
import { getUsersCountries } from "../../../Redux/Zte/ZteUsers/ZteUsersActions";
import {
  getAllLocations,
  getOwnedIps,
  addIpPost,
  updateAllOwnedIps,
} from "../../../Redux/Zte/Ztna/ZtnaActions";
import { toast } from "react-toastify";

const IPManagementCard = () => {
  const dispatch = useDispatch();
  const [ip, setIp] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [location, setLocation] = useState("");
  const [ownedIps, setOwnedIps] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const{ allCountries, fetchingUsersCountries }= useSelector((state) => state.users);
  const {allLocations, fetchingAllLocations, fetchingAllOwnedIps, allOwnedIps
}= useSelector((state) => state.ztna);

  useEffect(() => {
    dispatch(getUsersCountries());
    dispatch(getAllLocations());
    dispatch(getOwnedIps());
  }, [dispatch]);

  const addIpPayload = {
    countryId: country,
    locationTypeId: location,
    ipAddress: ip,
    city: city,
  };

  const removeIpPayload = {
    id: ownedIps,
    dateRemoved: selectedDate,
  };

  const fieldsCompletedAdd =
    addIpPayload.countryId &&
    addIpPayload.locationTypeId &&
    addIpPayload.ipAddress &&
    addIpPayload.city;

  const fieldsCompletedRemove =
    removeIpPayload.ownedIps && removeIpPayload.dateRemoved;

  const handleAddIp = () => {
    fieldsCompletedAdd
      ? dispatch(addIpPost(addIpPayload))
      : toast.error("All fields are required!");
  };

  const handleRemoveIp = () => {
    fieldsCompletedRemove
      ? dispatch(updateAllOwnedIps(removeIpPayload))
      : toast.error("All fields are required!");
  };
  return (
    <AdminCardStyles>
      <div className="ztna-container">
        <div>
          <p>Add IP</p>
          <div className="group-container">
            <SearchDropDown
              title={"Country"}
              type="select"
              data={allCountries}
              group={"services"}
              setData={setCountry}
              loading={fetchingUsersCountries}
            />
            <SearchDropDown
              title={"Location Type"}
              type="select"
              data={allLocations}
              setData={setLocation}
              group={"services"}
              loading={fetchingAllLocations}
            />
          </div>
        </div>
        <input
          placeholder="IP"
          className="input-text input-service"
          onChange={(e) => setIp(e.target.value)}
        />
        <input
          placeholder="City"
          className="input-text input-service"
          onChange={(e) => setCity(e.target.value)}
        />
        <div className="button-style-dropdown">
          <Button fill="fill" onclick={handleAddIp}>
            Add
          </Button>
        </div>
      </div>
      <div className="ztna-container">
        <div>
          <p>Remove IP</p>
          <div className="group-container">
            <SearchDropDown
              title={"Owned Ips"}
              type="select"
              data={allOwnedIps}
              group={"allIps"}
              setData={setOwnedIps}
              loading={fetchingAllOwnedIps}
            />
            <input
              name="date"
              type="datetime-local"
              className="input-text input-button"
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>
        </div>
        <div className="button-style-ip input-button">
          <Button fill="fill" onclick={handleRemoveIp}>
            Add
          </Button>
        </div>
      </div>
    </AdminCardStyles>
  );
};

export default IPManagementCard;
