import { AdminCardStyles } from "./AdminCard.style";
import SearchDropDown from "./SearchDropdown";
import { Button } from "../../atoms";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDevicePost,
  initiateDeviceUpdatePost,
  uploadDevicesPost,
  moveDevicesPost,
  addEmsGroupPost
} from "../../../Redux/Zte/Device/DeviceActions";

const DeviceManagementCard = () => {
  const dispatch = useDispatch();
  const [device, setDevice] = useState("");
  const [groupId, setGroupId] = useState();
  const [groupType, setGroupType] = useState();
  const [groupName, setGroupName] = useState("");
  const [emsGroupId, setEmsGroupId] = useState();
  const [isShared, setIsShared] = useState(false);
  const [checkDevices, setCheckDevices] = useState([]);
  const [checkMoveDevices, setCheckMoveDevices] = useState([]);

  const [file, setFile] = useState();
  const handleChecked = () => {
    setIsShared((isShared) => !isShared);
  };
  const groupTypes = [
    { name: "Workgroup", id: 0 },
    { name: "Domain", id: 1},
  ];

  const deviceDetails = {
    deviceName: device,
    isShared: isShared,
  };
  const deviceNames = {
    deviceNames: checkDevices,
  };

  const moveDevicePayload = {
    devicesToMove: checkMoveDevices,
    groupId: groupId,
  };

  const emsGroupPayload = {
    name: groupName,
    groupType: groupType,
    emsGroupId: Number(emsGroupId),
  }
  const handleAddDeviceName = () => {
    dispatch(addDevicePost(deviceDetails));
  };
  const handleUpdate = () => {
    dispatch(initiateDeviceUpdatePost(deviceNames));
  };

  const handleMoveDevices = () => {
    dispatch(moveDevicesPost(moveDevicePayload));
  };
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleOnSubmit = (e) => {
    const data = new FormData();
    const fileData = file;
    data.append("file", fileData);
    dispatch(uploadDevicesPost(data));
  };

  const handleAddEmsGroup = () => {
    dispatch(addEmsGroupPost(emsGroupPayload))
  }
  const {
    emsGroups,
    fetchingEmsGroups,
    allDevicesBasic,
    fetchingAllDevicesBasic,
  } = useSelector((state) => state.devices);
  return (
    <AdminCardStyles>
      <div className="admin-input">
        <p>Device</p>
        <div className="group-container">
          <input
            placeholder="Device Name"
            className="input-text"
            onChange={(e) => setDevice(e.target.value)}
          />

          <div className="heading-style">
            <p>Is Shared</p>
            <input type="checkbox" onChange={handleChecked} className="check" />
          </div>
          <Button
            className="button-style"
            fill="fill"
            onclick={handleAddDeviceName}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="admin-input">
        <div className="group-container">
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv, .xlsx, .xls"}
            onChange={handleOnChange}
          />
          <Button
            className="button-style button-style-dropdown"
            fill="fill"
            onclick={handleOnSubmit}
          >
            Upload
          </Button>
        </div>
        <p className="small-label">Accepts .csv, .xls and .xlsx</p>
      </div>
      <div>
        <div className="group-container">
          <SearchDropDown
            title={"Devices"}
            type="checkbox"
            max={10}
            group={"devices"}
            data={allDevicesBasic}
            setUserGroupIds={setCheckDevices}
            heading={"Update Devices"}
            loading={fetchingAllDevicesBasic}
          />
          <div className="button-style-ip">
            <Button fill="fill" onclick={handleUpdate}>
              Update
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="group-container">
          <SearchDropDown
            title={"Devices"}
            type="checkbox"
            max={10}
            group={"devicesId"}
            data={allDevicesBasic}
            setUserGroupIds={setCheckMoveDevices}
            heading={"Move Devices"}
            loading={fetchingAllDevicesBasic}
          />
          <div className="no-heading-style">
            <SearchDropDown
              title={"EMS Groups"}
              type="select"
              group={"groups"}
              data={emsGroups?.data}
              setData={setGroupId}
              loading={fetchingEmsGroups}
            />
          </div>
          <div className="button-style-ip">
            <Button fill="fill" onclick={handleMoveDevices}>
              Move
            </Button>
          </div>
        </div>
      </div>
      <div className="ztna-container">
        <div>
          <p>Add EMS Groups</p>
          <div className="group-container">
            <div className="dropdown-input">
              <input
                placeholder="Name"
                className="input-text"
                onChange={(e) => setGroupName(e.target.value)}
              />
              <input
                placeholder="Group ID"
                type="number"
                className="input-text"
                onChange={(e) => setEmsGroupId(e.target.value)}
              />
            </div>
            <SearchDropDown
              title={"Group Type"}
              type="select"
              data={groupTypes}
              setData={setGroupType}
              group={"services"}
            />
          </div>
          <div></div>
        </div>
        <div className="button-style-ip input-button">
          <Button
            className="button-style"
            fill="fill"
            onclick={handleAddEmsGroup}
          >
            Add
          </Button>
        </div>
      </div>
    </AdminCardStyles>
  );
};

export default DeviceManagementCard;
