import {
  AppCardStyle,
  LegendStyle,
  SectionLayout,
  AppStyles,
} from "./AppCard.style";

const AppCard = ({ appDetails, setSelectedData, setOpen }) => {
  const showModal = (data) => {
    setSelectedData(data);
    setOpen(true);
  };
  const prodApps = appDetails?.filter((app) => {
    return app.environment === 0;
  });
  const testApps = appDetails?.filter((app) => {
    return app.environment === 1;
  });
  const devApps = appDetails?.filter((app) => {
    return app.environment === 2;
  });

  return (
    <AppStyles>
      {appDetails ? (
        <LegendStyle>
          <div className="container">
            <div className="prod square"></div>
            <p>Production</p>
          </div>
          <div className="container">
            <div className="test square"></div>
            <p>Test</p>
          </div>
          <div className="container">
            <div className="dev square"></div>
            <p>Development</p>
          </div>
        </LegendStyle>
      ) : null}
      {appDetails ? (
        <SectionLayout>
          <div className="section-container">
            <hr />
            <AppCardStyle>
              {prodApps?.length ? (
                prodApps.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="item-card"
                      onClick={() => showModal(data)}
                    >
                      <div className={`${"bg-white"}`}>
                        <img src={data.logoUrl} alt="logo" />
                      </div>
                      <p>{data.ztnaServiceName}</p>
                    </div>
                  );
                })
              ) : (
                <p className="text-container">No Apps in Production</p>
              )}
            </AppCardStyle>
          </div>
          <div className="section-container">
            <hr />

            <AppCardStyle>
              {testApps?.length ? (
                testApps.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="item-card"
                      onClick={() => showModal(data)}
                    >
                      <div className={`bg-green`}>
                        <img src={data.logoUrl} alt="logo" />
                      </div>
                      <p>{data.ztnaServiceName}</p>
                    </div>
                  );
                })
              ) : (
                <p className="text-container">No Apps in Test</p>
              )}
            </AppCardStyle>
          </div>
          <div className="section-container">
            <hr />

            <AppCardStyle>
              {devApps?.length ? (
                devApps.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="item-card"
                      onClick={() => showModal(data)}
                    >
                      <div className={`bg-yellow`}>
                        <img src={data.logoUrl} alt="logo" />
                      </div>
                      <p>{data.ztnaServiceName}</p>
                    </div>
                  );
                })
              ) : (
                <p className="text-container">No Apps in Development</p>
              )}
            </AppCardStyle>
          </div>
        </SectionLayout>
      ) : (
        <p className="no-data-text">No Data Found</p>
      )}
    </AppStyles>
  );
};

export default AppCard;
