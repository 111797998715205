import { Input } from "../../atoms";
import { FaAngleDown } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { useState, useRef } from "react";
import { useComponentVisible } from "../../../utils/customHooks/useComponentVisible";

const SearchDropDown = ({
  title,
  data,
  type,
  max,
  group,
  setData,
  setUserGroupIds,
  heading,
  loading,
  user,
}) => {
  const cardRef = useRef(null);
  const [cardVisible, setcardVisible] = useState(false);
  useComponentVisible(cardRef, setcardVisible);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(title);
  const [checked, setChecked] = useState([]);
  const handleVisibility = () => {
    setcardVisible((cardVisible) => !cardVisible);
  };
  const handleClick = (value, id) => {
    setData(id);
    setSelected(value);
    setSearch("");
    setcardVisible(false);
  };

  document.querySelectorAll(".check").forEach((item) => {
    item.addEventListener("click", function (e) {
      if (document.querySelectorAll(".check:checked").length > max) {
        return false;
      }
    });
  });

  const handleCheck = (e, item) => {
    var updatedList = [...checked];
    if (e.target.checked) {
      updatedList = [...checked, item];
    } else {
      updatedList.splice(checked.indexOf(item), 1);
    }
    setChecked(updatedList);
    setUserGroupIds(updatedList);
  };

  return (
    <div className="wrapper" ref={cardRef}>
      <p className={user === "form" ? `heading-style` : null}>{heading}</p>
      <div>
        <div className={"select-btn"} onClick={handleVisibility}>
          <span>{type === "select" ? selected : title}</span>
          <div className="down-arrow">
            <FaAngleDown />
          </div>
        </div>

        {cardVisible ? (
          <div className="content">
            <div className={"search"}>
              <div className="search-icon"></div>
              <FaSearch className="icon" />
              <Input
                type="text"
                placeholder="Search"
                handleChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {loading ? (
              <p className="small-text">Loading...</p>
            ) : !data?.length ? (
              <p className="small-text">No data found</p>
            ) : (
              <div>
                {type === "select" ? (
                  <ul className="options">
                    {data
                      ?.sort()
                      .filter((li) =>
                        group === "users"
                          ? li.displayName
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          : group === "services"
                          ? li.name.toLowerCase().includes(search.toLowerCase())
                          : group === "groups"
                          ? li.value
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          : group === "allIps"
                          ? li.ipAddress.includes(search.toLowerCase())
                          : group === "detail"
                          ? li.title
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          : group === "reason"
                          ? li.reason
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          : li.deviceName
                              .toLowerCase()
                              .includes(search.toLowerCase())
                      )
                      .map((item, index) =>
                        group === "users" ? (
                          <li
                            key={index}
                            id={item.objectID}
                            onClick={() =>
                              handleClick(item.displayName, item.objectID)
                            }
                          >
                            {item.displayName}
                          </li>
                        ) : group === "services" ? (
                          <li
                            key={index}
                            id={item.id}
                            onClick={() => handleClick(item.name, item.id)}
                          >
                            {item.name}
                          </li>
                        ) : group === "groups" ? (
                          <li
                            key={index}
                            id={item.id}
                            onClick={() => handleClick(item.value, item.id)}
                          >
                            {item.value}
                          </li>
                        ) : group === "allIps" ? (
                          <li
                            key={index}
                            id={item.id}
                            onClick={() => handleClick(item.ipAddress, item.id)}
                          >
                            {item.ipAddress}
                          </li>
                        ) : group === "detail" ? (
                          <li
                            key={index}
                            id={item.id}
                            onClick={() => handleClick(item.title, item.id)}
                          >
                            {item.title}
                          </li>
                        ) : group === "reason" ? (
                          <li
                            key={index}
                            id={item.id}
                            onClick={() => handleClick(item.reason, item.id)}
                          >
                            {item.reason}
                          </li>
                        ) : (
                          <li
                            key={index}
                            id={item.id}
                            onClick={() =>
                              handleClick(item.deviceName, item.id)
                            }
                          >
                            {item.deviceName}
                          </li>
                        )
                      )}
                  </ul>
                ) : type === "checkbox" ? (
                  <div className="options">
                    {data
                      ?.sort()
                      .filter((li) =>
                        group === "devices" || group === "devicesId"
                          ? li.deviceName
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                          : li?.name
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                      )
                      .map((item, index) =>
                        group === "devices" || group === "devicesId" ? (
                          <div
                            className="group-container label-input"
                            key={index}
                          >
                            <input
                              key={index}
                              id={item.id}
                              name={item.deviceName}
                              onChange={
                                group === "devices"
                                  ? (e) => handleCheck(e, item.deviceName)
                                  : (e) => handleCheck(e, item.id)
                              }
                              type="checkbox"
                              className="check"
                            />
                            <label htmlFor={item.id}>{item.deviceName}</label>
                          </div>
                        ) : (
                          <div
                            className="group-container label-input"
                            key={index}
                          >
                            <input
                              key={index}
                              id={item.id}
                              name={item.name}
                              onChange={(e) => handleCheck(e, item.id)}
                              type="checkbox"
                              className="check"
                            />
                            <label htmlFor={item.id}>{item.name}</label>
                          </div>
                        )
                      )}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchDropDown;
