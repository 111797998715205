import { Button } from "../../atoms";
import { AdminCardStyles } from "./AdminCard.style";
import {
  uploadZenPost,
  uploadFazPost,
  postSaveActiveUsers,
  postSaveActiveUsersByCountry,
  postSaveUpdateConnections,
  postSaveUpdateConnectionsByDate,
} from "../../../Redux/Zte/Ztna/ZtnaActions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ConnectionManagementCard = () => {
  const dispatch = useDispatch();
  const [zenFile, setZenFile] = useState();
  const [fazFile, setFazFile] = useState();
  const [selectedDateActiveUsers, setSelectedDateActiveUsers] = useState("");
  const [selectedDateCountry, setSelectedDateCountry] = useState("");
  const [selectedFromDateConnections, setSelectedFromDateConnections] =
    useState("");
  const [selectedToDateConnections, setSelectedToDateConnections] =
    useState("");

  const handleFazUpload = () => {
    const data = new FormData();
    const fileData = fazFile;
    data.append("file", fileData);
    dispatch(uploadFazPost(data));
  };

  const handleZenUpload = () => {
    const data = new FormData();
    const fileData = zenFile;
    data.append("file", fileData);
    dispatch(uploadZenPost(data));
  };

  const handleZenOnChange = (e) => {
    setZenFile(e.target.files[0]);
  };

  const handleFazOnChange = (e) => {
    setFazFile(e.target.files[0]);
  };

  const handleUpdateActiveUsers = () => {
    dispatch(postSaveActiveUsers(selectedDateActiveUsers));
  };

  const handleUpdateByCountry = () => {
    dispatch(postSaveActiveUsersByCountry(selectedDateCountry));
  };

  const timeFrame = {
    fromDate: selectedFromDateConnections,
    toDate: selectedToDateConnections,
  };

  const handleUpdateConnections = () => {
    if (selectedFromDateConnections && selectedToDateConnections) {
      dispatch(postSaveUpdateConnectionsByDate(timeFrame));
    } else if (!selectedFromDateConnections && !selectedToDateConnections) {
      dispatch(postSaveUpdateConnections());
    } else {
      toast.error("Select both From and To Date or leave empty");
    }
  };

  return (
    <AdminCardStyles>
      <div className="button-groups">
        <div className="group-container">
          <div>
            <input
              type={"file"}
              id={"fazFileInput"}
              accept={".csv, .xlsx, .xls"}
              onChange={handleFazOnChange}
            />
          </div>

          <Button fill="fill" onclick={handleFazUpload}>
            FortiAnalyzer Log Upload
          </Button>
        </div>

        <div className="group-container">
          <input
            type={"file"}
            id={"zenFileInput"}
            accept={".csv, .xlsx, .xls"}
            onChange={handleZenOnChange}
          />
          <Button fill="fill" onclick={handleZenUpload}>
            ZScaler Log Upload
          </Button>
        </div>
      </div>
      <p className="small-label">Accepts .csv, .xls and .xlsx</p>
      <div className="button-groups">
        <div className="group-container">
          <div>
            <input
              name="date"
              type="date"
              onChange={(e) => setSelectedDateActiveUsers(e.target.value)}
            />
          </div>
          <Button fill="fill" onclick={handleUpdateActiveUsers}>
            Update Active Users
          </Button>
        </div>
        <div className="group-container">
          <div>
            <input
              name="date"
              type="date"
              onChange={(e) => setSelectedDateCountry(e.target.value)}
            />
          </div>
          <Button fill="fill" onclick={handleUpdateByCountry}>
            Update by Country
          </Button>
        </div>
      </div>
      <div className="group-container">
        <div>
          <p>From Date</p>
          <input
            name="date"
            type="datetime-local"
            onChange={(e) => setSelectedFromDateConnections(e.target.value)}
          />
        </div>
        <div>
          <p>To Date</p>
          <div className="group-container">
            <input
              name="date"
              type="datetime-local"
              onChange={(e) => setSelectedToDateConnections(e.target.value)}
            />
            <Button fill="fill" onclick={handleUpdateConnections}>
              Update Connections
            </Button>
          </div>
        </div>
      </div>
    </AdminCardStyles>
  );
};

export default ConnectionManagementCard;
