

export const fetchLinks = (role) => {
    const navLinks = [
			{
				name: "Home",
				path: "/",
			},
			{
				name: "Dashboard",
				path: "/dashboard",
			},
		];
		const serviceDeskLinks = [
			{
				name: "Home",
				path: "/",
			},
			{
				name: "Search",
				path: "/search",
			},
			{
				name: "Issues",
				path: "/issues",
			},
			{
				name: "Dashboard",
				path: "/dashboard",
			},
		];
		const adminLinks = [
			{
				name: "Home",
				path: "/",
			},
			{
				name: "Search",
				path: "/search",
			},
			{
				name: "Issues",
				path: "/issues",
			},
			{
				name: "Dashboard",
				path: "/dashboard",
			},
			{
				name: "Admin",
				path: "/admin",
			},
		];
	return role?.includes("Administrator")
		? adminLinks
		: role?.includes("ServiceDeskUser")
		? serviceDeskLinks
		: navLinks;
};
