import { useEffect } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import { Table } from "../../atoms";
import { structureData } from "../../../utils/FormatData";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobsLastRuntime
} from "../../../Redux/Zte/Ztna/ZtnaActions"


const JobsInformationTable = () => {
  const headers = ["Job Title", "Successful", "Last Runtime"];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getJobsLastRuntime())
  }, [dispatch]);

  const {
    jobslastRuntime,
    fetchingJobslastRuntime,
  } = useSelector((state) => state.ztna);
 
  return (
    <AdminCardStyles>
      <div className="table-style">
        <Table
          headers={headers}
          data={structureData("jobInformation", jobslastRuntime)}
          loading={fetchingJobslastRuntime}
        />
      </div>
    </AdminCardStyles>
  );
};

export default JobsInformationTable;
