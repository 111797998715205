import { useRef } from "react";
import UserManagementCard from "./UserManagementCard";
import DeviceManagementCard from "./DeviceManagementCard";
import ThreatManagementCard from "./ThreatManagementCard";
import ConnectionManagementCard from "./ConnectionManagementCard";
import IPManagementCard from "./IPManagementCard";
import IssueManagementCard from "./IssueManagementCard";
import ZtnaManagementCard from "./ZtnaManagementCard";
import InPageNavigation from "../InPageNavigation";

const ManagementTab = ({ open, setOpen}) => {
  const sectionsRef = useRef([]);
  const sections = [
    "User & Group Management",
    "Device Management",
    "Threat Data Management",
    "Connection Update Management",
    "Owned IP Management",
    "Issue Management",
    "ZTNA Management",
  ];

  return (
    <>
      <div className="page-section">
        <div ref={(el) => (sectionsRef.current[0] = el)}>
          <h1
            className="admin-headers"
            id="user"
          >
            User & Group Management
          </h1>
          <UserManagementCard />
        </div>
        <div ref={(el) => (sectionsRef.current[1] = el)}>
          <h1 className="admin-headers" id="device">
            Device Management
          </h1>
          <DeviceManagementCard />
        </div>
        <div ref={(el) => (sectionsRef.current[2] = el)}>
          <h1 className="admin-headers" id="threat">
            Threat Data Management
          </h1>
          <ThreatManagementCard open={open} setOpen={setOpen} />
        </div>
        <div ref={(el) => (sectionsRef.current[3] = el)}>
          <h1 className="admin-headers" id="connection">
            Connection Update Management
          </h1>
          <ConnectionManagementCard />
        </div>
        <div ref={(el) => (sectionsRef.current[4] = el)}>
          <h1 className="admin-headers" id="ownedIp">
            Owned IP Management
          </h1>
          <IPManagementCard />
        </div>
        <div ref={(el) => (sectionsRef.current[5] = el)}>
          <h1 className="admin-headers" id="issue">
            Issue Management
          </h1>
          <IssueManagementCard />
        </div>
        <div ref={(el) => (sectionsRef.current[6] = el)}>
          <h1 className="admin-headers" id="ztna">
            ZTNA Management
          </h1>
          <ZtnaManagementCard />
        </div>
      </div>
      <InPageNavigation sectionsRef={sectionsRef} sections={sections} />
    </>
  );
};

export default ManagementTab;
