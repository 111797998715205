import React from 'react'
import { Button } from '../components/atoms'

const Login = () => {
  return (
    <div onClick={()=> localStorage.setItem("token", "chartApp123" )}>
        <Button>Login</Button>
    </div>
  )
}

export default Login