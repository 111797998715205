import { SectionToggleStyles } from "./SectionToggle.style";

const SectionToggle = ({ tabList, setActiveTab, activeTab, children }) => {
    const handleShowTab = (e) => {
    setActiveTab(e.target.textContent);
  };
  return (
    <SectionToggleStyles>
      <div className="tab-slider--nav">
        <ul className="tab-slider--tabs">
          {tabList.map((value, index) => (
            <li className={`tab-slider--trigger ${activeTab === value ? "active": null}`} onClick={handleShowTab} value={value} key={index}>
              {value}
            </li>
          ))}
        </ul>
      </div>
      {children}
    </SectionToggleStyles>
  );
};

export default SectionToggle;
