import { useEffect } from "react";
import { SortCardStyle } from "./SortFilter.Style";
import { IoSwapVerticalSharp } from "react-icons/io5";

const SortCardMoveHistory = ({
  detailsList,
  title,
  setFilterValue,
  filterValue,
  setSortParams,
  setSortOrder,
  visible,
}) => {

  const handleSortChange = (value) => {
    setFilterValue(value);
  };

  useEffect(() => {
    if (filterValue === "Move Date (Earliest to Latest)") {
      setSortOrder(0);
      setSortParams(0);
    } else if (filterValue === "Move Date (Latest to Earliest)") {
      setSortOrder(1);
      setSortParams(0);
    } else if (filterValue === "Device Name (A-Z)") {
      setSortOrder(0);
      setSortParams(1);
    } else if (filterValue === "Device Name (Z-A)") {
      setSortOrder(1);
      setSortParams(1);
    } else if (filterValue === "EMS Host (A-Z)") {
      setSortOrder(0);
      setSortParams(2);
    } else if (filterValue === "EMS Host (Z-A)") {
      setSortOrder(1);
      setSortParams(2);
    } else if (filterValue === "EMS Message (A-Z)") {
      setSortOrder(0);
      setSortParams(3);
    } else if (filterValue === "EMS Message (Z-A)") {
      setSortOrder(1);
      setSortParams(3);
    }
    // eslint-disable-next-line
  }, [filterValue]);
  return (
    <div>
      <div className="container">
        <div className="item-container">
          <span className="title">{filterValue ? filterValue : title}</span>
        </div>
        <div className="icon-style">
          <IoSwapVerticalSharp />
        </div>
      </div>

      {visible ? (
        <div>
          <SortCardStyle>
            {detailsList.map((value, index) => (
              <div
                className="list-item"
                key={index}
                onClick={() => handleSortChange(value)}
              >
                {value}
              </div>
            ))}
          </SortCardStyle>
        </div>
      ) : null}
    </div>
  );
};

export default SortCardMoveHistory;
