export const GET_ALL_ISSUES_START = "GET_ALL_ISSUES_START";
export const GET_ALL_ISSUES_SUCCESS = "GET_ALL_ISSUES_SUCCESS";
export const GET_ALL_ISSUES_FAILURE = "GET_ALL_ISSUES_FAILURE";
export const GET_ISSUE_DETAIL_START = "GET_ISSUE_DETAIL_START";
export const GET_ISSUE_DETAIL_SUCCESS = "GET_ISSUE_DETAIL_SUCCESS";
export const GET_ISSUE_DETAIL_FAILURE = "GET_ISSUE_DETAIL_FAILURE";
export const ADD_ISSUE_DETAIL_START = "ADD_ISSUE_DETAIL_START";
export const ADD_ISSUE_DETAIL_SUCCESS = "ADD_ISSUE_DETAIL_SUCCESS";
export const ADD_ISSUE_DETAIL_FAILURE = "ADD_ISSUE_DETAIL_FAILURE";
export const ADD_HOLD_REASON_START = "ADD_HOLD_REASON_START";
export const ADD_HOLD_REASON_SUCCESS = "ADD_HOLD_REASON_SUCCESS";
export const ADD_HOLD_REASON_FAILURE = "ADD_HOLD_REASON_FAILURE";
export const GET_ISSUE_TYPE_START = "GET_ISSUE_TYPE_START";
export const GET_ISSUE_TYPE_SUCCESS = "GET_ISSUE_TYPE_SUCCESS";
export const GET_ISSUE_TYPE_FAILURE = "GET_ISSUE_TYPE_FAILURE";
export const ADD_ISSUE_TYPE_START = "ADD_ISSUE_TYPE_START";
export const ADD_ISSUE_TYPE_SUCCESS = "ADD_ISSUE_TYPE_SUCCESS";
export const ADD_ISSUE_TYPE_FAILURE = "ADD_ISSUE_TYPE_FAILURE";
export const GET_HOLD_REASON_START = "GET_HOLD_REASON_START";
export const GET_HOLD_REASON_SUCCESS = "GET_HOLD_REASON_SUCCESS";
export const GET_HOLD_REASON_FAILURE = "GET_HOLD_REASON_FAILURE";
export const SET_HOLD_REASON_START = "SET_HOLD_REASON_START";
export const SET_HOLD_REASON_SUCCESS = "SET_HOLD_REASON_SUCCESS";
export const SET_HOLD_REASON_FAILURE = "SET_HOLD_REASON_FAILURE";
export const RESOLVE_ISSUE_START = "RESOLVE_ISSUE_START";
export const RESOLVE_ISSUE_SUCCESS = "RESOLVE_ISSUE_SUCCESS";
export const RESOLVE_ISSUE_FAILURE = "RESOLVE_ISSUE_FAILURE";
export const SET_UNRESOLVED_ISSUE_START = "SET_UNRESOLVED_ISSUE_START";
export const SET_UNRESOLVED_ISSUE_SUCCESS = "SET_UNRESOLVED_ISSUE_SUCCESS";
export const SET_UNRESOLVED_ISSUE_FAILURE = "SET_UNRESOLVED_ISSUE_FAILURE";
export const GET_ISSUE_TYPE_BY_ID_START = "GET_ISSUE_TYPE_BY_ID_START";
export const GET_ISSUE_TYPE_BY_ID_SUCCESS = "GET_ISSUE_TYPE_BY_ID_SUCCESS";
export const GET_ISSUE_TYPE_BY_ID_FAILURE = "GET_ISSUE_TYPE_BY_ID_FAILURE";
export const ADD_ISSUE_START = "ADD_ISSUE_START";
export const ADD_ISSUE_SUCCESS = "ADD_ISSUE_SUCCESS";
export const ADD_ISSUE_FAILURE = "ADD_ISSUE_FAILURE";
