import styled from 'styled-components'

export const LineStyle = styled.div`
	width: 100%;
	overflow-x: auto;
	padding: 0 1rem 1rem;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	position: relative;
	p {
		text-align: center;
	}
	.chart {
		width: 100%;
	}
	

	@media (max-width: 640px) {
		.chart {
			width: 420px;
		}
	}
`;

export const LegendToolTip = styled.div`
	position: absolute;
	z-index: 100;
	border: 1px solid #7ca212;
	bottom: 3rem;
	right: 50%;
	transform: translateX(50%);
	background-color: #404040;
	color: #fff;
	padding: 0.5rem 1rem;
	border-radius: 6px;
	p,
	span {
		padding: 0;
		margin: 0;
	}
	span {
		color: ${(props) => (props.color ? props.color : "#7ca212")};
	}
`;