import {
  GET_ALL_ISSUES_FAILURE,
  GET_ALL_ISSUES_START,
  GET_ALL_ISSUES_SUCCESS,
  GET_ISSUE_DETAIL_FAILURE,
  GET_ISSUE_DETAIL_START,
  GET_ISSUE_DETAIL_SUCCESS,
  ADD_ISSUE_DETAIL_START,
  ADD_ISSUE_DETAIL_SUCCESS,
  ADD_ISSUE_DETAIL_FAILURE,
  ADD_HOLD_REASON_START,
  ADD_HOLD_REASON_SUCCESS,
  ADD_HOLD_REASON_FAILURE,
  GET_ISSUE_TYPE_START,
  GET_ISSUE_TYPE_SUCCESS,
  GET_ISSUE_TYPE_FAILURE,
  ADD_ISSUE_TYPE_START,
  ADD_ISSUE_TYPE_SUCCESS,
  ADD_ISSUE_TYPE_FAILURE,
  GET_HOLD_REASON_START,
  GET_HOLD_REASON_SUCCESS,
  GET_HOLD_REASON_FAILURE,
  SET_HOLD_REASON_START,
  SET_HOLD_REASON_SUCCESS,
  SET_HOLD_REASON_FAILURE,
  RESOLVE_ISSUE_START,
  RESOLVE_ISSUE_SUCCESS,
  RESOLVE_ISSUE_FAILURE,
  SET_UNRESOLVED_ISSUE_START,
  SET_UNRESOLVED_ISSUE_SUCCESS,
  SET_UNRESOLVED_ISSUE_FAILURE,
  ADD_ISSUE_START,
  ADD_ISSUE_FAILURE,
  ADD_ISSUE_SUCCESS,
  GET_ISSUE_TYPE_BY_ID_START,
  GET_ISSUE_TYPE_BY_ID_SUCCESS,
  GET_ISSUE_TYPE_BY_ID_FAILURE,
} from "./IssueActionTypes";
import {
  getAllIssues,
  getIssueDetail,
  addIssueType,
  getIssueTypeBasic,
  addIssueDetail,
  addHoldReason,
  setUnresolvedIssue,
  resolveIssue,
  setHoldReason,
  getHoldReason,
  addIssue,
  getIssueTypeById,
} from "../../../api/zte/issue";
import { toast } from "react-toastify";

export const getAllIssuesStart = () => {
  return {
    type: GET_ALL_ISSUES_START,
  };
};
export const getAllIssuesFailed = (payload) => {
  return {
    type: GET_ALL_ISSUES_FAILURE,
    payload,
  };
};
export const getAllIssuesSuccess = (payload) => {
  return {
    type: GET_ALL_ISSUES_SUCCESS,
    payload,
  };
};

export const fetchAllIssues =
  (
    currentPage,
    status,
    sortParams,
    sortOrder,
    reportingFilter,
    reportingFilterDateFrom,
    reportingFilterDateTo,
    resolutionFilter,
    resolutionFilterDateFrom,
    resolutionFilterDateTo
  ) =>
  async (dispatch) => {
    dispatch(getAllIssuesStart());
    const { data, error } = await getAllIssues(
      currentPage,
      status,
      sortParams,
      sortOrder,
      reportingFilter,
      reportingFilterDateFrom,
      reportingFilterDateTo,
      resolutionFilter,
      resolutionFilterDateFrom,
      resolutionFilterDateTo
    );
    if (data) {
      dispatch(getAllIssuesSuccess(data));
    } else {
      dispatch(getAllIssuesFailed(error));
      toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
    }
  };

export const getIssueDetailStart = () => {
  return {
    type: GET_ISSUE_DETAIL_START,
  };
};
export const getIssueDetailFailed = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_FAILURE,
    payload,
  };
};
export const getIssueDetailSuccess = (payload) => {
  return {
    type: GET_ISSUE_DETAIL_SUCCESS,
    payload,
  };
};

export const getIssueDetailById = (id) => async (dispatch) => {
  dispatch(getIssueDetailStart());
  const { data, error } = await getIssueDetail(id);
  if (data && data?.statusCode === "90000") {
    dispatch(getIssueDetailSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getIssueDetailFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addIssueDetailStart = () => {
  return {
    type: ADD_ISSUE_DETAIL_START,
  };
};
export const addIssueDetailFailed = (payload) => {
  return {
    type: ADD_ISSUE_DETAIL_FAILURE,
    payload,
  };
};
export const addIssueDetailSuccess = (payload) => {
  return {
    type: ADD_ISSUE_DETAIL_SUCCESS,
    payload,
  };
};

export const addNewIssueDetail = (payload) => async (dispatch) => {
  dispatch(addIssueDetailStart());
  const { data, error } = await addIssueDetail(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(addIssueDetailSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(addIssueDetailFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssueTypeStart = () => {
  return {
    type: GET_ISSUE_TYPE_START,
  };
};
export const getIssueTypeFailed = (payload) => {
  return {
    type: GET_ISSUE_TYPE_FAILURE,
    payload,
  };
};
export const getIssueTypeSuccess = (payload) => {
  return {
    type: GET_ISSUE_TYPE_SUCCESS,
    payload,
  };
};

export const getIssueType = () => async (dispatch) => {
  dispatch(getIssueTypeStart());
  const { data, error } = await getIssueTypeBasic();
  if (data && data?.statusCode === "90000") {
    dispatch(getIssueTypeSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getIssueTypeFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addHoldReasonStart = () => {
  return {
    type: ADD_HOLD_REASON_START,
  };
};
export const addHoldReasonFailed = (payload) => {
  return {
    type: ADD_HOLD_REASON_FAILURE,
    payload,
  };
};
export const addHoldReasonSuccess = (payload) => {
  return {
    type: ADD_HOLD_REASON_SUCCESS,
    payload,
  };
};

export const addHoldReasonPost = (payload) => async (dispatch) => {
  dispatch(addHoldReasonStart());
  const { data, error } = await addHoldReason(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(addHoldReasonSuccess(data));
    toast.success(data?.messsage);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(addHoldReasonFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const addIssueTypeStart = () => {
  return {
    type: ADD_ISSUE_TYPE_START,
  };
};
export const addIssueTypeFailed = (payload) => {
  return {
    type: ADD_ISSUE_TYPE_FAILURE,
    payload,
  };
};
export const addIssueTypeSuccess = (payload) => {
  return {
    type: ADD_ISSUE_TYPE_SUCCESS,
    payload,
  };
};

export const addIssueTypePost = (payload) => async (dispatch) => {
  dispatch(addIssueTypeStart());
  const { data, error } = await addIssueType(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(addIssueTypeSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(addIssueTypeFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getHoldReasonStart = () => {
  return {
    type: GET_HOLD_REASON_START,
  };
};
export const getHoldReasonFailed = (payload) => {
  return {
    type: GET_HOLD_REASON_FAILURE,
    payload,
  };
};
export const getHoldReasonSuccess = (payload) => {
  return {
    type: GET_HOLD_REASON_SUCCESS,
    payload,
  };
};

export const getHoldReasons = () => async (dispatch) => {
  dispatch(getHoldReasonStart());
  const { data, error } = await getHoldReason();
  if (data && data?.statusCode === "90000") {
    dispatch(getHoldReasonSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getHoldReasonFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const addIssueStart = () => {
  return {
    type: ADD_ISSUE_START,
  };
};
export const addIssueFailed = (payload) => {
  return {
    type: ADD_ISSUE_FAILURE,
    payload,
  };
};
export const addIssueSuccess = (payload) => {
  return {
    type: ADD_ISSUE_SUCCESS,
    payload,
  };
};

export const setHoldReasonStart = () => {
  return {
    type: SET_HOLD_REASON_START,
  };
};
export const setHoldReasonFailed = (payload) => {
  return {
    type: SET_HOLD_REASON_FAILURE,
    payload,
  };
};
export const setHoldReasonSuccess = (payload) => {
  return {
    type: SET_HOLD_REASON_SUCCESS,
    payload,
  };
};
export const setHoldReasonPost = (payload) => async (dispatch) => {
  dispatch(setHoldReasonStart());
  const { data, error } = await setHoldReason(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(setHoldReasonSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(setHoldReasonFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const addNewIssue = (payload) => async (dispatch) => {
  dispatch(addIssueStart());
  const { data, error } = await addIssue(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(addIssueSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(addIssueFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getIssueTypeIdStart = () => {
  return {
    type: GET_ISSUE_TYPE_BY_ID_START,
  };
};
export const getIssueTypeIdFailed = (payload) => {
  return {
    type: GET_ISSUE_TYPE_BY_ID_FAILURE,
    payload,
  };
};
export const getIssueTypeIdSuccess = (payload) => {
  return {
    type: GET_ISSUE_TYPE_BY_ID_SUCCESS,
    payload,
  };
};
export const getIssueDetailTypeId = (id) => async (dispatch) => {
  dispatch(getIssueTypeIdStart());
  const { data, error } = await getIssueTypeById(id);
  if (data && data?.statusCode === "90000") {
    dispatch(getIssueTypeIdSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(getIssueTypeIdFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const resolveIssueStart = () => {
  return {
    type: RESOLVE_ISSUE_START,
  };
};
export const resolveIssueFailed = (payload) => {
  return {
    type: RESOLVE_ISSUE_FAILURE,
    payload,
  };
};
export const resolveIssueSuccess = (payload) => {
  return {
    type: RESOLVE_ISSUE_SUCCESS,
    payload,
  };
};

export const resolveIssuePost = (payload) => async (dispatch) => {
  dispatch(resolveIssueStart());
  const { data, error } = await resolveIssue(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(resolveIssueSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(resolveIssueFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const setUnresolveIssueStart = () => {
  return {
    type: SET_UNRESOLVED_ISSUE_START,
  };
};
export const setUnresolveIssueFailed = (payload) => {
  return {
    type: SET_UNRESOLVED_ISSUE_FAILURE,
    payload,
  };
};
export const setUnresolveIssueSuccess = (payload) => {
  return {
    type: SET_UNRESOLVED_ISSUE_SUCCESS,
    payload,
  };
};

export const setUnresolveIssue = (payload) => async (dispatch) => {
  dispatch(setUnresolveIssueStart());
  const { data, error } = await setUnresolvedIssue(payload);
  if (data && data?.statusCode === "90000") {
    dispatch(setUnresolveIssueSuccess(data));
    toast.success(data?.message);
  } else if (data?.statusCode === "10001") {
    toast.error(data?.message);
  } else {
    dispatch(setUnresolveIssueFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
