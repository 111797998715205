import { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "../../atoms";
import { FilterWrapper } from "../../../pages/Pages.style";
import InPageNavigation from "../../molecules/InPageNavigation";
import Loader from "../../atoms/Loader";

import {
  formatUserIssueData,
  formatIssueTypeData,
  formatIssueDetailData,
  formatDurationLineChartData,
  formatDurationLineChartDates,
  formatDetailDurationLineChartData,
  formatBarChartData,
  formatBarChartDate,
  formatBarChartDataStack,
  formatBarChartDateDetail,
  formatBarChartDataDetail,
  formatBarChartDataType,
  formatAverageChartDates,
  formatAverageChartCountryDates,
  formatAverageChartData,
  formatAverageChartDateRegion,
  formatAverageChartDataRegion,
  formatDetailDurationLineChartDates,
  formatAverageEouData,
  formatUserExpChartDateRegion,
  formatUserExpChartDataRegion,
  formatOverallExpDates,
  formatOverallExpData,
  getRegions,
  getDataByRegion,
} from "../../../utils/formatZtnaChartData";
import {
  getUserIssue,
  getIssueType,
  getIssueDetail,
  getIssueTypeByDuration,
  getIssueDetailByDuration,
  getIssueTypeByResolutionSpeed,
  getIssuesDetailByResolutionSpeed,
  getIssueTypeCountry,
  getIssueTypeByResolutionSpeedByDuration,
  getIssuesDetailByResolutionSpeedByDuration,
  getAverageRating,
  getAverageUserServiceExpRating,
  getAverageUserServiceExpRatingRegion,
  getAverageOverallRatingCountry,
  getAverageOverallRatingRegion,
  getAverageGenericEou,
  getAverageGenericEouCountry,
} from "../../../Redux/Zte/ZtnaDashboard/ZtnaDashboardActions";
import ZtnaPieChart from "../pieCharts/ZtnaPieCharts";
import ZtnaLineChart from "../lineCharts/ZtnaLineCharts";
import ZtnaBarChart from "../barCharts/ZtnaBarCharts";
import { UsageWrapper } from "../../../pages/Pages.style";

const UserExperienceDashboard = () => {
  const dispatch = useDispatch();
  const sectionsRef = useRef([]);
  const sections = [
    "User Issue Summary",
    "Issue Type Summary",
    "Issue Detail Summary",
    "Issue Type Summary by Duration",
    "Issue Detail Summary by Duration",
    "Issue Type by Resolution Speed",
    "Issue Detail by Resolution Speed",
    "Period-based Issue Detail Summary by Resolution Speed",
    "Period-based Issue Type by Resolution Speed",
    "Issue Type by Country",
    "Ease of Use Rating",
    "Overall Experience Rating",
    "Overall Experience Rating by Region",
    "Overall Experience Rating by Country",
    "App Experience Rating",
    "Ease of Use Rating by Country",
  ];
  const [isCardVisible, setIsCardVisible] = useState(false);

  const [duration, setDuration] = useState(0);
  const [typeDuration, setTypeDuration] = useState(0);
  const [detailDuration, setDetailDuration] = useState(0);
  const [interval, setInterval] = useState(0);
  const [durationText, setDurationText] = useState("Week");
  const [typeDurationText, setTypeDurationText] = useState("Week");
  const [detailDurationText, setDetailDurationText] = useState("Week");
  const [intervalText, setIntervalText] = useState("Week");
  const filterOptions = ["Week", "Month", "Year"];

  const handleFilterChange = (e) => {
    if (e.target.value === "week") {
      setDuration(0);
      setDurationText(e.target.value);
    } else if (e.target.value === "month") {
      setDuration(1);
      setDurationText(e.target.value);
    } else {
      setDuration(2);
      setDurationText(e.target.value);
    }
  };
  const handleFilterChangeType = (e) => {
    if (e.target.value === "week") {
      setTypeDuration(0);
      setTypeDurationText(e.target.value);
    } else if (e.target.value === "month") {
      setTypeDuration(1);
      setTypeDurationText(e.target.value);
    } else {
      setTypeDuration(2);
      setTypeDurationText(e.target.value);
    }
  };
  const handleFilterChangeDetail = (e) => {
    if (e.target.value === "week") {
      setDetailDuration(0);
      setDetailDurationText(e.target.value);
    } else if (e.target.value === "month") {
      setDetailDuration(1);
      setDetailDurationText(e.target.value);
    } else {
      setDetailDuration(2);
      setDetailDurationText(e.target.value);
    }
  };

  const handleIntervalChange = (e) => {
    if (e.target.value === "week") {
      setInterval(0);
      setIntervalText(e.target.value);
    } else if (e.target.value === "month") {
      setInterval(1);
      setIntervalText(e.target.value);
    } else {
      setInterval(2);
      setIntervalText(e.target.value);
    }
  };

  useEffect(() => {
    dispatch(getUserIssue());
    dispatch(getIssueType());
    dispatch(getIssueDetail());
    dispatch(getIssueTypeByResolutionSpeed());
    dispatch(getIssuesDetailByResolutionSpeed());
    dispatch(getIssueTypeCountry());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getIssueTypeByDuration(duration));
  }, [dispatch, duration]);

  useEffect(() => {
    dispatch(getIssueTypeByResolutionSpeedByDuration(typeDuration));
  }, [dispatch, typeDuration]);

  useEffect(() => {
    dispatch(getIssuesDetailByResolutionSpeedByDuration(detailDuration));
  }, [dispatch, detailDuration]);

  useEffect(() => {
    dispatch(getIssueDetailByDuration(interval));
  }, [dispatch, interval]);

  useEffect(() => {
    dispatch(getUserIssue());
    dispatch(getIssueType());
    dispatch(getIssueDetail());
    dispatch(getAverageRating());
    dispatch(getAverageUserServiceExpRating());
    dispatch(getAverageOverallRatingCountry());
    dispatch(getAverageOverallRatingRegion());
    dispatch(getAverageGenericEou());
    dispatch(getAverageGenericEouCountry());
    dispatch(getAverageUserServiceExpRatingRegion());
  }, [dispatch]);

  const {
    fetchingAvgUserServiceExp,
    avgUserServiceExpCountry,
    fetchingAvgRatingCountry,
    avgRatingCountry,
    fetchingAvgRatingRegion,
    avgRatingRegion,
    fetchingAvgEaseofUse,
    avgEaseofUse,
    fetchingAvgEaseofUseCountry,
    avgEaseofUseCountry,
    fetchingAvgUserServiceExpRegion,
    avgUserServiceExpRegion,
    fetchingAvgRating,
    avgRating,
    userIssue,
    fetchingUserIssue,
    issueType,
    fetchingIssueType,
    issueDetail,
    fetchingIssueDetail,
    fetchingIssueTypeDuration,
    issueTypeDuration,
    fetchingIssueDetailDuration,
    issueDetailDuration,
    fetchingIssueTypeResolution,
    issueTypeResolution,
    fetchingIssueTypeCountry,
    issueTypeCountry,
    fetchingIssueTypeResolutionDuration,
    issueTypeResolutionDuration,
    fetchingIssueDetailResolutionSpeedDuration,
    issueDetailResolutionSpeeduration,
    fetchingIssueDetailResolutionSpeed,
    issueDetailResolutionSpeed,
  } = useSelector((state) => state.ztnaDashboard);

  const filterOptionsRatings = getRegions(avgUserServiceExpRegion);
  const [region, setRegion] = useState(filterOptions[0]);
  const [userExpRegion, setUserExpRegion] = useState(avgUserServiceExpRegion);
  const handleFilterChangeRatings = (e) => {
    setRegion(e.target.value);
  };
  useEffect(() => {
    let value = getDataByRegion(avgUserServiceExpRegion, region);
    setUserExpRegion(value);
  }, [region, avgUserServiceExpRegion]);

  const issueSummary = useCallback(() => {
    return formatUserIssueData(userIssue);
  }, [userIssue]);

  const typeSummary = useCallback(() => {
    return formatIssueTypeData(issueType);
  }, [issueType]);

  const detailSummary = useCallback(() => {
    return formatIssueDetailData(issueDetail);
  }, [issueDetail]);

  const durationLineChartData = useCallback(() => {
    return formatDurationLineChartData(issueTypeDuration);
  }, [issueTypeDuration]);

  const durationLineChartDates = useCallback(() => {
    return formatDurationLineChartDates(issueTypeDuration);
  }, [issueTypeDuration]);

  const detailDurationLineChartData = useCallback(() => {
    return formatDetailDurationLineChartData(issueDetailDuration);
  }, [issueDetailDuration]);

  const detailDurationLineChartDates = useCallback(() => {
    return formatDurationLineChartDates(issueDetailDuration);
  }, [issueDetailDuration]);

  const issueTypeResolutionData = useCallback(() => {
    return formatBarChartData(issueTypeResolution);
  }, [issueTypeResolution]);

  const issueDetailResolutionSpeedData = useCallback(() => {
    return formatBarChartData(issueDetailResolutionSpeed, true);
  }, [issueDetailResolutionSpeed]);

  const issueDetailResolutionSpeedurationDate = useCallback(() => {
    return formatBarChartDateDetail(
      issueDetailResolutionSpeeduration
    )
  }, [issueDetailResolutionSpeeduration]);

  const issueDetailResolutionSpeedurationData = useCallback(() => {
    return formatBarChartDataDetail(
      issueDetailResolutionSpeeduration
    )
  }, [issueDetailResolutionSpeeduration]);

  return (
    <>
      <UsageWrapper>
        <div className="section-selector">
          <p>issues</p>
        </div>
        <div className="chart" ref={(el) => (sectionsRef.current[0] = el)}>
          <p className="title-text">User Issue Summary</p>
          {fetchingUserIssue ? (
            <Loader />
          ) : Object.keys(userIssue).length ? (
            <ZtnaPieChart data={issueSummary()} />
          ) : (
            <p className="empty-text">No Data to Display</p>
          )}
        </div>
        <div className="container">
          <div ref={(el) => (sectionsRef.current[1] = el)}>
            <p className="title-text">Issue Type Summary</p>
            {fetchingIssueType ? (
              <Loader />
            ) : Object.keys(issueType).length ? (
              <ZtnaPieChart data={typeSummary()} />
            ) : (
              <p className="empty-text">No Data to Display</p>
            )}
          </div>
          <div className="chart" ref={(el) => (sectionsRef.current[2] = el)}>
            <p className="title-text">Issue Detail Summary</p>
            {fetchingIssueDetail ? (
              <Loader />
            ) : Object.keys(issueDetail).length ? (
              <ZtnaPieChart data={detailSummary()} />
            ) : (
              <p className="empty-text">No Data to Display</p>
            )}
          </div>
        </div>
        <div className="line-chart" ref={(el) => (sectionsRef.current[3] = el)}>
          <p className="title-text">Issue Type Summary by Duration</p>
          {fetchingIssueTypeDuration ? (
            <Loader />
          ) : Object.keys(issueTypeDuration).length ? (
            <>
              <FilterWrapper>
                <p>Filter by:</p>
                <div>
                  <Select
                    name="IssueTypeDuration"
                    data={filterOptions}
                    handleChange={handleFilterChange}
                    placeholder={"Choose duration"}
                    defaulValue={durationText}
                    // formData={{name:durationText}}
                  />
                </div>
              </FilterWrapper>

              <ZtnaLineChart
                data={durationLineChartData()}
                xAxis={durationLineChartDates()}
                yAxis={"Count"}
              />
            </>
          ) : (
            <p className="empty-text">No Data to Display</p>
          )}
        </div>
        <div className="line-chart" ref={(el) => (sectionsRef.current[4] = el)}>
          <p className="title-text">Issue Detail Summary by Duration</p>
          {fetchingIssueDetailDuration ? (
            <Loader />
          ) : Object.keys(userIssue).length ? (
            <>
              <FilterWrapper>
                <p>Filter by:</p>
                <div>
                  <Select
                    name="IssueTypeDuration"
                    data={filterOptions}
                    handleChange={handleIntervalChange}
                    placeholder={"Choose Duration"}
                    defaulValue={intervalText}
                  />
                </div>
              </FilterWrapper>
              <ZtnaLineChart
                data={detailDurationLineChartData()}
                xAxis={detailDurationLineChartDates()}
                yAxis={"Count"}
              />
            </>
          ) : (
            <p className="empty-text">No Data to Display</p>
          )}
        </div>
        <div className="container">
          <div ref={(el) => (sectionsRef.current[5] = el)}>
            <p className="title-text">Issue Type by Resolution Speed</p>{" "}
            {fetchingIssueTypeResolution ? (
              <Loader />
            ) : Object.keys(userIssue).length ? (
              <>
                <ZtnaBarChart
                  yAxis={"Resolution Speed (Hours)"}
                  xAxis={["Issue Detail"]}
                  data={issueTypeResolutionData()}
                />
              </>
            ) : (
              <p className="empty-text">No Data to Display</p>
            )}
          </div>
          <div ref={(el) => (sectionsRef.current[6] = el)}>
            <p className="title-text">Issue Detail by Resolution Speed</p>
            {fetchingIssueDetailResolutionSpeed ? (
              <Loader />
            ) : Object.keys(userIssue).length ? (
              <>
                <ZtnaBarChart
                  yAxis={"Resolution Speed (Hours)"}
                  xAxis={["Issue Detail"]}
                  data={issueDetailResolutionSpeedData()}
                />
              </>
            ) : (
              <p className="empty-text">No Data to Display</p>
            )}
          </div>
        </div>
        <div className="line-chart" ref={(el) => (sectionsRef.current[7] = el)}>
          <p className="title-text">
            Period-based Issue Detail Summary by Resolution Speed
          </p>

          {fetchingIssueDetailResolutionSpeedDuration ? (
            <Loader />
          ) : Object.keys(userIssue).length ? (
            <>
              {" "}
              <FilterWrapper>
                <p>Filter by:</p>
                <div>
                  <Select
                    name="IssueTypeDuration"
                    data={filterOptions}
                    handleChange={handleFilterChangeDetail}
                    placeholder={"Choose duration"}
                    defaulValue={detailDurationText}
                  />
                </div>
              </FilterWrapper>
              <ZtnaBarChart
                stack={true}
                xAxis={issueDetailResolutionSpeedurationDate()}
                yAxis={"Resolution Speed (Hours)"}
                data={issueDetailResolutionSpeedurationData()}
              />
            </>
          ) : (
            <p className="empty-text">No Data to Display</p>
          )}
        </div>
        <div className="line-chart" ref={(el) => (sectionsRef.current[8] = el)}>
          <p className="title-text">
            Period-based Issue Type by Resolution Speed
          </p>
          {fetchingIssueTypeResolutionDuration ? (
            <Loader />
          ) : Object.keys(userIssue).length ? (
            <>
              <FilterWrapper>
                <p>Filter by:</p>
                <div>
                  <Select
                    name="IssueTypeDuration"
                    data={filterOptions}
                    handleChange={handleFilterChangeType}
                    placeholder={"Choose Duration"}
                    defaulValue={typeDurationText}
                  />
                </div>
              </FilterWrapper>
              <ZtnaBarChart
                stack={true}
                yAxis={"Resolution Speed (Hours)"}
                xAxis={formatDurationLineChartDates(
                  issueTypeResolutionDuration
                )}
                data={formatBarChartDataType(issueTypeResolutionDuration)}
              />
            </>
          ) : (
            <p className="empty-text">No Data to Display</p>
          )}
        </div>
        <div className="line-chart" ref={(el) => (sectionsRef.current[9] = el)}>
          <p className="title-text"> Issue Type by Country</p>
          {fetchingIssueTypeCountry ? (
            <Loader />
          ) : Object.keys(userIssue).length ? (
            <ZtnaBarChart
              country={true}
              yAxis={"Resolution Speed (Hours)"}
              xAxis={formatBarChartDate(issueTypeCountry)}
              data={formatBarChartDataStack(issueTypeCountry)}
            />
          ) : (
            <p className="empty-text">No Data to Display</p>
          )}
        </div>

        <div className="section-selector">
          <p>ratings</p>
        </div>

        <div className="container">
          <div ref={(el) => (sectionsRef.current[10] = el)}>
            <p className="title-text">Ease of Use Rating</p>
            {fetchingAvgEaseofUse ? (
              <Loader />
            ) : Object.keys(avgEaseofUse).length ? (
              <>
                <ZtnaLineChart
                  data={formatAverageEouData(avgEaseofUse)}
                  // title={"Ease of Use Rating"}
                  xAxis={formatDetailDurationLineChartDates(avgEaseofUse)}
                  yAxis={"Rating"}
                  avg={true}
                />
              </>
            ) : (
              <p className="empty-text">No Data to Display</p>
            )}
          </div>
          <div ref={(el) => (sectionsRef.current[11] = el)}>
            <p className="title-text">Overall Experience Rating</p>
            {fetchingAvgRating ? (
              <Loader />
            ) : Object.keys(avgRating).length ? (
              <>
                <ZtnaLineChart
                  data={formatOverallExpData(avgRating)}
                  // title={"Overall Experience Rating"}
                  xAxis={formatOverallExpDates(avgRating)}
                  yAxis={"Rating"}
                  avg={true}
                />
              </>
            ) : (
              <p className="empty-text">No Data to Display</p>
            )}
          </div>
        </div>
        <div className="container">
          <div ref={(el) => (sectionsRef.current[12] = el)}>
            <p className="title-text">Overall Experience Rating by Region</p>
            {fetchingAvgRatingRegion ? (
              <Loader />
            ) : Object.keys(avgRatingRegion).length ? (
              <>
                <ZtnaLineChart
                  avg={true}
                  data={formatAverageChartDataRegion(avgRatingRegion)}
                  // title={"Overall Experience Rating by Region"}
                  xAxis={formatAverageChartDateRegion(avgRatingRegion)}
                  yAxis={"Rating"}
                />
              </>
            ) : (
              <p className="empty-text">No Data to Display</p>
            )}
          </div>
          <div ref={(el) => (sectionsRef.current[13] = el)}>
            <p className="title-text">Overall Experience Rating by Country</p>
            {fetchingAvgRatingCountry ? (
              <Loader />
            ) : Object.keys(avgRatingCountry).length ? (
              <>
                <ZtnaLineChart
                  data={formatAverageChartData(avgRatingCountry, true)}
                  // title={"Overall Experience Rating by Country"}
                  xAxis={formatAverageChartCountryDates(avgRatingCountry)}
                  yAxis={"Rating"}
                  avg={true}
                />
              </>
            ) : (
              <p className="empty-text">No Data to Display</p>
            )}
          </div>
        </div>
        <div className="" ref={(el) => (sectionsRef.current[14] = el)}>
          <p className="title-text">App Experience Rating</p>
          {fetchingAvgUserServiceExp ? (
            <Loader />
          ) : Object.keys(avgUserServiceExpCountry).length ? (
            <>
              <ZtnaLineChart
                data={formatAverageChartData(avgUserServiceExpCountry)}
                // title={"App Experience Rating"}
                xAxis={formatAverageChartDates(avgUserServiceExpCountry)}
                yAxis={"Rating"}
                avg={true}
              />
            </>
          ) : (
            <p className="empty-text">No Data to Display</p>
          )}
        </div>
        {/* <div className="">
          <p className="title-text">App Experience Rating by Region</p>
        {userExpRegion ? (
          <Loader />
        ) : Object.keys(avgUserServiceExpRegion).length   ? (
          <>
            <FilterWrapper>
              <p>Filter by:</p>
              <div>
                <Select
                  name="IssueTypeDuration"
                  data={filterOptionsRatings}
                  handleChange={handleFilterChangeRatings}
                  placeholder={"Choose region"}
                  defaulValue={region}
                />
              </div>
            </FilterWrapper>
            <ZtnaLineChart
              data={formatUserExpChartDataRegion(userExpRegion)}
              title={"App Experience Rating by Region"}
              xAxis={formatUserExpChartDateRegion(avgUserServiceExpRegion)}
              yAxis={"Rating"}
              avg={true}
            />
          </>
        ) : (
          <p className="empty-text">No Data to Display</p>
        )}
      </div> */}
        <div className="container" ref={(el) => (sectionsRef.current[15] = el)}>
          <div>
            <p className="title-text">Ease of Use Rating by Country</p>
            <p className="empty-text">No Data to Display</p>
          </div>

          {/* {fetchingAvgEaseofUseCountry ? (
          <Loader />
        ) : Object.keys(avgUserServiceExpCountry).length   ? (
          <>
            <ZtnaLineChart
              data={formatAverageChartData(avgEaseofUseCountry)}
              title={"Ease of Use Rating by Country"}
              xAxis={formatAverageChartDates(avgEaseofUseCountry)}
              yAxis={"Rating"}
              avg={true}
            />
          </>
        ) : (
          <p className="empty-text">No Data to Display</p>
        )} */}
        </div>
      </UsageWrapper>
      <InPageNavigation sectionsRef={sectionsRef} sections={sections} />
    </>
  );
};
export default UserExperienceDashboard;
