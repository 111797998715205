import React from "react";
import DetailsCard from "./DetailsCard";
import { UserDetailCardStyle } from "../Molecules.style";

const UserDetailCard = ({ userDetails }) => {
  const userDetailKeys = userDetails && Object.keys(userDetails);
  return userDetailKeys?.map((dataKey, index) => {
    return (
      userDetails?.[dataKey] && (
        <UserDetailCardStyle key={index}>
          <h2>{dataKey}</h2>
          <DetailsCard
            key={index}
            cardInfo={userDetails?.[dataKey]}
            title={dataKey}
          />
        </UserDetailCardStyle>
      )
    );
  });
};

export default UserDetailCard;
