import React, { useEffect, useState } from "react";
import { DeviceDetail, Layout } from "../components/molecules";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { structureData } from "../utils/FormatData";
import { HomeStyle } from "./Pages.style";
import { getDeviceDetailById } from "../Redux/Zte/Device/DeviceActions";
import { IoIosArrowBack } from "react-icons/io";
import Button from "../components/atoms/Button";
import SendNotificationModal from "../components/molecules/SendNotificationModal";

const DeviceDetails = () => {
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeviceDetailById(deviceId));
  }, [dispatch, deviceId]);

  const handleOpen = () => {
    setOpenNotificationModal(true);
  };
  const { deviceDetail, fetchingDeviceDetail } = useSelector(
    (state) => state.devices
  );

  return (
    <Layout>
      <SendNotificationModal
        open={openNotificationModal}
        setOpen={setOpenNotificationModal}
      />
      <HomeStyle>
        <div className="back-btn" onClick={() => navigate(-1)}>
          <IoIosArrowBack />
          <p>Back</p>
        </div>

        {!fetchingDeviceDetail ? (
          <DeviceDetail
            deviceDetail={structureData("deviceDetail", deviceDetail)}
          />
        ) : (
          <p>Loading...</p>
        )}
        <div className="buttons-container">
          <div className="send-notification-button">
            <Button fill="fill" onclick={handleOpen}>
              Send Notification
            </Button>
          </div>
        </div>
      </HomeStyle>
    </Layout>
  );
};

export default DeviceDetails;
