import { styled } from "styled-components";

export const AppCardStyle = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  padding: 1rem 1rem;
  width: 100%;

  p {
    font-size: 0.75rem;
    font-weight: 600;
    color: #62646d;
    margin-top: 1rem;
  }

  img {
    height: 100%;
    width: 100px;
    background: #ffffff;
  }
  .item-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    background: #f7f8f8;
    border: 1px solid #e5e8e9;
    border-radius: 0.25rem;
    cursor: pointer;
    box-shadow: 0 0 5px #e5e8e9;

    &:hover {
      background: #e5e8e9;
    }
  }
  .text-container {
    width: 80vw;
    letter-spacing: 1px;
    color: #62646d;
    font-size: 0.75rem;
  }
  .bg-white {
    border: 4px solid #ffffff;
  }
  .bg-green {
    border: 4px solid #96c115;
  }
  .bg-yellow {
    border: 4px solid #fffd8d;
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    img {
      height: 100%;
      width: 100%;
    }
  }
`;

export const LegendStyle = styled.div`
  float: right;
  padding-bottom: 1rem;
  .container {
    display: flex;
    align-items: center;
    font-weight: 400;
    letter-spacing: 1px;
    color: #3e3e3e;
    font-size: 0.75rem;
  }

  .square {
    height: 10px;
    width: 10px;
    border: 1px solid #3e3e3e;
    margin-right: 0.5rem;
  }
  .prod {
    background: #ffffff;
  }
  .test {
    background: #96c115;
  }
  .dev {
    background-color: #fffd8d;
  }
`;

export const SectionLayout = styled.div`
  margin-top: 5rem;
  .section-container {
    padding-bottom: 2rem;
  }
  hr {
    border: 0.5px solid #e5e8e9;
  }
`;

export const AppStyles = styled.div`
  .no-data-text {
    padding: 1rem;
    width: 80vw;
    box-shadow: 0 0 5px #e5e8e9;
    font-weight: 600;
    letter-spacing: 1px;
    color: #62646d;
    font-size: 0.75rem;
  }
`;
