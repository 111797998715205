import API from "../axio";

export const getAllIssues = async (
  currentPage,
  status,
  sortParams,
  sortOrder,
  reportingFilter,
  reportingFilterDateFrom,
  reportingFilterDateTo,
  resolutionFilter,
  resolutionFilterDateFrom,
  resolutionFilterDateTo
) => {
  try {
    const { data } = await API.get(
      `Issue/list?PageNumber=${currentPage}&PageSize=20&Status=${status}&SortParams=${sortParams}&SortOrder=${sortOrder}&ReportingFilter=${reportingFilter}&ReportingFilterDateFrom=${reportingFilterDateFrom}&ReportingFilterDateTo=${reportingFilterDateTo}&ResolutionFilter=${resolutionFilter}&ResolutionFilterDateFrom=${resolutionFilterDateFrom}&ResolutionFilterDateTo=${resolutionFilterDateTo}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueDetail = async (id) => {
  try {
    const { data } = await API.get(`Issue/id?issueId=${id}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addIssueType = async (payload) => {
  try {
    const { data } = await API.post(`/Issue/add-type`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueTypeBasic = async () => {
  try {
    const { data } = await API.get(`/Issue/list-types-basic`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addIssueDetail = async (payload) => {
  try {
    const { data } = await API.post(`/Issue/add-detail`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addHoldReason = async (payload) => {
  try {
    const { data } = await API.post(`/Issue/add-onHold-reason`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getHoldReason = async () => {
  try {
    const { data } = await API.get(`/Issue/list-onhold-reasons`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addIssue = async (payload) => {
  try {
    const { data } = await API.post(`/Issue/add`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const setHoldReason = async (payload) => {
  try {
    const { data } = await API.post(`/Issue/update-reason`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const resolveIssue = async (payload) => {
  try {
    const { data } = await API.post(`/Issue/resolve`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const setUnresolvedIssue = async (payload) => {
  try {
    const { data } = await API.post(`/Issue/unresolve`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getIssueTypeById = async (id) => {
  try {
    const { data } = await API.get(
      `/Issue/list-details-by-typeId?issueTypeId=${id}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
