import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  ManagementTab,
  InformationTab,
} from "../components/molecules/AdminCard";
import Layout from "../components/molecules/Layout";
import ThreatDataModal from "../components/molecules/ThreatDataModal";
import SectionToggle from "../components/molecules/SectionToggle/SectionToggle";
import { getListofEmsgroups } from "../Redux/Zte/Device/DeviceActions";


const Admin = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Information");
  const tabList = ["Information", "Management"];

  useEffect(() => {
    dispatch(getListofEmsgroups());
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Layout>
      <ThreatDataModal open={open} setOpen={setOpen} />
      <SectionToggle
        tabList={tabList}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      >
        {activeTab === "Management" ? (
          <ManagementTab open={open} setOpen={setOpen} />
        ) : (
          <InformationTab />
        )}
      </SectionToggle>
    </Layout>
  );
};

export default Admin;
