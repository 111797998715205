import { FormStyle } from "./Form.style";
import moment from "moment";

const InformationForm = ({ data, type , userDetails}) => {
  return (
    <FormStyle>
      {type === "messages" ? (
        <div>
          <div className="button-style">
            <h6>Message</h6>
            <p>{data.Message}</p>
          </div>
        </div>
      ) :type === "emsMessages" ? (
        <div>
          <div className="button-style">
            <h6>EMS Message</h6>
            <p>{data.Message}</p>
          </div>
        </div>
      ) : type === "app-info" ? (
        <div>
          <div className="button-style">
            <h6>Display Name</h6>
            <p>
              {userDetails?.basicInformation?.displayName}
            </p>
              </div>
              <div className="button-style">
            <h6>User Principal Name</h6>
            <p>
              {userDetails?.basicInformation?.userPrincipalName}
            </p>
              </div>
              <div className="button-style">
            <h6>App</h6>
            <p>
              {data?.ztnaServiceName}
            </p>
              </div>
              <div className="button-style">
            <h6>Date of Assignment</h6>
            <p>
              {data.dateAdded
                ? moment(data.dateAdded).format("DD-MM-YYYY")
                : "N/A"}
            </p>
          </div>
          <div className="button-style">
            <h6>First Use</h6>
            <p>
              {data.dateOfFirstUse
                ? moment(data.dateOfFirstUse).format("DD-MM-YYYY")
                : "N/A"}
            </p>
          </div>
          <div className="button-style">
            <h6>Last Use</h6>
            <p>
              {data.dateOfLastUse
                ? moment(data.dateOfLastUse).format("DD-MM-YYYY")
                : "N/A"}
            </p>
          </div>
          <div> 
            <h6>Active</h6>
            <p>
              {data.isActive ? "True"
                : "False"}
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="button-style">
            <h6>Description</h6>
            <p>{data.Description}</p>
          </div>
          <div>
            <h6>Modified date</h6>
            <p>{data.Modified}</p>
          </div>
        </div>
      )}
    </FormStyle>
  );
};

export default InformationForm;
