import React from "react";

const Input = ({ type, placeholder, handleChange, value, name, min, max, checked }) => {
	return (
		<div>
			<input
				type={type}
				placeholder={placeholder}
				onChange={handleChange}
				value={value}
				name={name}
				min={min}
				max={max}
				checked={checked}
			/>
		</div>
	);
};

export default Input;
