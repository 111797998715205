import styled from "styled-components";

export const DetailsCardStyle = styled.section`
	padding: 1rem 0;
	width: 100%;
	h2 {
		font-size: 1.2rem;
		color: #62646d;
		text-transform: uppercase;
	}

	.details-wrapper {
		padding: 0 1rem 1rem;
		box-shadow: 0 0 5px #e5e8e9;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		max-height: 14rem;
		overflow-y: auto;
		.not_found {
			margin: auto;
			padding: 2rem;
		}
		.info_border {
			border-bottom: 1px dotted #b9d948;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-bottom: 1rem;
		}

		.info {
			min-width: 30%;
			margin-top: 1rem;
			box-sizing: border-box;
			h3 {
				font-size: 0.75rem;
				color: #62646d;
				text-transform: capitalize;
			}
			p {
				color: rgb(73, 73, 73);
			}
		}
		.no_border {
			border-bottom: none;
			padding-bottom: 0;
		}
	}
	.no-padding {
		padding: 0;
		table {
			margin-top: 0;
		}
	}
`;
