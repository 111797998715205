import Modal from "@mui/material/Modal";
import { ModalFormWrapper } from "../../pages/Pages.style";
import { ReactComponent as CloseBtn } from "../../assets/icons/close.svg";
import { InformationForm } from "./form";
import { useState, useEffect } from "react";

const InformationModal = ({ open, setOpen, data, userDetails, type }) => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setIsSuccessful(true);
  };
  useEffect(() => {
    setIsSuccessful(false);
  }, [isSuccessful]);

  return (
    <Modal
      open={open ? open : false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalFormWrapper>
        <div className="top">
          <div className="close">
            <CloseBtn onClick={handleClose} />
          </div>
        </div>
        <div className="edit-container">
          <InformationForm setOpen={setOpen} data={data} type={type} userDetails={userDetails} />
        </div>
      </ModalFormWrapper>
    </Modal>
  );
};

export default InformationModal;
