export const GET_DEVICE_FAILURE = "GET_DEVICE_FAILURE";
export const GET_DEVICE_SUCCESS = "GET_DEVICE_SUCCESS";
export const GET_DEVICE_START = "GET_DEVICE_START";
export const GET_DEVICE_DETAIL_FAILURE = "GET_DEVICE_DETAIL_FAILURE";
export const GET_DEVICE_DETAIL_SUCCESS = "GET_DEVICE_DETAIL_SUCCESS";
export const GET_DEVICE_DETAIL_START = "GET_DEVICE_DETAIL_START";
export const GET_DEVICE_BASIC_FAILURE = "GET_DEVICE_BASIC_FAILURE";
export const GET_DEVICE_BASIC_SUCCESS = "GET_DEVICE_BASIC_SUCCESS";
export const GET_DEVICE_BASIC_START = "GET_DEVICE_BASIC_START";
export const ADD_DEVICE_FAILURE = "ADD_DEVICE_FAILURE";
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS";
export const ADD_DEVICE_START = "ADD_DEVICE_START";
export const ADD_DEVICE_UPDATE_START = "ADD_DEVICE_UPDATE_START";
export const ADD_DEVICE_UPDATE_FAILURE = "ADD_DEVICE_UPDATE_FAILURE";
export const ADD_DEVICE_UPDATE_SUCCESS = "ADD_DEVICE_UPDATE_SUCCESS";
export const UPLOAD_DEVICES_FAILURE = "UPLOAD_DEVICES_FAILURE";
export const UPLOAD_DEVICES_SUCCESS = "UPLOAD_DEVICES_SUCCESS";
export const UPLOAD_DEVICES_START = "UPLOAD_DEVICES_START";
export const DEVICE_SEND_MESSAGE_FAILURE = "DEVICE_SEND_MESSAGE_FAILURE";
export const DEVICE_SEND_MESSAGE_SUCCESS = "DEVICE_SEND_MESSAGE_SUCCESS";
export const DEVICE_SEND_MESSAGE_START = "DEVICE_SEND_MESSAGE_START";
export const GET_SENT_MESSAGES_FAILURE = "GET_SENT_MESSAGES_FAILURE";
export const GET_SENT_MESSAGES_SUCCESS = "GET_SENT_MESSAGES_SUCCESS";
export const GET_SENT_MESSAGES_START = "GET_SENT_MESSAGES_START";
export const GET_DEVICE_MOVEMENT_FAILURE = "GET_DEVICE_MOVEMENT_FAILURE";
export const GET_DEVICE_MOVEMENT_SUCCESS = "GET_DEVICE_MOVEMENT_SUCCESS";
export const GET_DEVICE_MOVEMENT_START = "GET_DEVICE_MOVEMENT_START";
export const DEVICE_MOVE_FAILURE = "DEVICE_MOVE_FAILURE";
export const DEVICE_MOVE_SUCCESS = "DEVICE_MOVE_SUCCESS";
export const DEVICE_MOVE_START = "DEVICE_MOVE_START";
export const DEVICE_ASSIGNMENT_SUGGESTIONS_FAILURE = "DEVICE_ASSIGNMENT_SUGGESTIONS_FAILURE";
export const DEVICE_ASSIGNMENT_SUGGESTIONS_SUCCESS = "DEVICE_ASSIGNMENT_SUGGESTIONS_SUCCESS";
export const DEVICE_ASSIGNMENT_SUGGESTIONS_START = "DEVICE_ASSIGNMENT_SUGGESTIONS_START";
export const GET_LIST_EMS_GROUP_FAILURE = "GET_LIST_EMS_GROUP_FAILURE";
export const GET_LIST_EMS_GROUP_SUCCESS = "GET_LIST_EMS_GROUP_SUCCESS";
export const GET_LIST_EMS_GROUP_START = "GET_LIST_EMS_GROUP_START";
export const ADD_EMS_GROUP_FAILURE = "ADD_EMS_GROUP_FAILURE";
export const ADD_EMS_GROUP_SUCCESS = "ADD_EMS_GROUP_SUCCESS";
export const ADD_EMS_GROUP_START = "ADD_EMS_GROUP_START";