import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../atoms";
import { FormStyle, FieldStyle } from "./Form.style";
import SearchDropDown from "../AdminCard/SearchDropdown";
import { getUsersDevicesById } from "../../../Redux/Zte/ZteUsers/ZteUsersActions";
import {
  getIssueType,
  getIssueDetailTypeId,
  addNewIssue,
} from "../../../Redux/Zte/Issue/IssueAction";
import { useParams } from "react-router-dom";

const CreateIssueForm = ({setOpen}) => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [comment, setComment] = useState("");
  const [selectedType, setSelectedType] = useState();
  const [selectedDetail, setSelectedDetail] = useState();
  const [selectedDevice, setSelectedDevices] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const handleChange = (event) => {
    setComment(event.target.value);
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  useEffect(() => {
    dispatch(getUsersDevicesById(userId));
    dispatch(getIssueType());
  }, [dispatch, userId]);

  useEffect(() => {
    selectedType && dispatch(getIssueDetailTypeId(selectedType));
  }, [dispatch, selectedType]);

  const { fetchingUserDevices, userDevices, addIssueStart } = useSelector(
    (state) => state.users
  );
  const { fetchingIssueType, issueType, fetchingIssueTypeId, issueTypeId } =
    useSelector((state) => state.issues);

  const handleSubmit = () => {
    dispatch(
      addNewIssue({
        deviceId: selectedDevice,
        userId: userId,
        issueDetailId: selectedDetail,
        comment: comment,
        dateAdded: selectedDate,
      })
    );
    setOpen(false)
  };

  return (
    <FormStyle>
      <div className="create-issue">
        <FieldStyle>
          <SearchDropDown
            heading={"Devices"}
            data={userDevices}
            title={"Select Device"}
            type="select"
            group={"devices"}
            setData={setSelectedDevices}
            user="form"
            loading={fetchingUserDevices}
          />
        </FieldStyle>
        <FieldStyle>
          <SearchDropDown
            heading={"Issue Type"}
            data={issueType}
            title={"Select Type"}
            type="select"
            group={"services"}
            setData={setSelectedType}
            user="form"
            loading={fetchingIssueType}
          />
        </FieldStyle>
        <FieldStyle>
          <SearchDropDown
            heading={"Issue Detail"}
            data={issueTypeId}
            title={"Select Detail"}
            group={"detail"}
            type="select"
            setData={setSelectedDetail}
            user="form"
            loading={fetchingIssueTypeId}
          />
        </FieldStyle>
        <FieldStyle>
          <label>Date Added</label>
          <input type="datetime-local" onChange={handleDateChange} id="dateAdded" />
        </FieldStyle>
        <FieldStyle>
          <div>
            <label>Comment</label>
            <textarea onChange={handleChange} placeholder="Type here" />
          </div>
        </FieldStyle>

        <div className="threat-btn">
          <Button fill="fill" onclick={handleSubmit}>
            {addIssueStart ? "Loading..." : "Add Issue"}
          </Button>
        </div>
      </div>
    </FormStyle>
  );
};

export default CreateIssueForm;
