import styled from "styled-components";

export const SectionToggleStyles = styled.div`
display: flex;
flex-direction: column;
.tab-slider--nav{
	margin: auto;
	margin-bottom: 20px;    
}
.tab-slider--tabs{
	display: block;
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	border: 1px solid #96c115;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
	&:after{
		content: "";
		width: 50%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transition: all 250ms ease-in-out;
		border-radius: 4px;
	}
}

.tab-slider--trigger {
	font-size: 14px;
	line-height: 1;
	font-weight: bold;
	color: #62646d;
	text-transform: uppercase;
	text-align: center;
	padding: 10px 20px;
	position: relative;
	z-index: 2;
	cursor: pointer;
	display: inline-block;
	transition: color 250ms ease-in-out;
	&.active {
        background: #96c115;
		color: #ffffff;
	}
}

@media (max-width: 640px) {
	padding-top: 1rem;
	.tab-slider--trigger {
		font-size: 0.7rem;
	}
}
`