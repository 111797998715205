import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import { ThreatEntryForm } from "../components/molecules";
import { ChartPageStyle, ModalFormWrapper, FilterWrapper } from "./Pages.style";
import { ReactComponent as CloseBtn } from "../assets/icons/close.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ThreatSourceLines from "../components/organism/lineCharts/ThreatSourceLines";
import { CategoryLines } from "../components/organism/lineCharts";
import ThreatSourcePie from "../components/organism/pieCharts/ThreatSourcePie";
import {
  getDropDownInfo,
  getSelectedThreatSourceBreakdown,
  getThreatSource,
  resetThreatBreakdown,
} from "../Redux/threats/ThreatDashboard/ThreatDashboardActions";
import { Select } from "../components/atoms";
import Layout from "../components/molecules/Layout";
import { generateYears } from "../utils/getYears";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function BasicModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [clickedThreatSourceId, setClickedThreatSourceId] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const selectedRef = useRef(null);
  const filterOptions = [
    "week",
    "quarter1",
    "quarter2",
    "quarter3",
    "quarter4",
    "annually",
  ];
  const year = generateYears();
  const [filterState, setFilterState] = useState({
    threatSourceDuration: "annually",
    region: { id: 2, name: "APAC" },
    year: new Date().getFullYear(),
  });
  const {
    dropDown,
    threatBreakdown,
    fetchingThreatEntries,
    threatSource,
    fetchingThreatEntriesError,
  } = useSelector((state) => state.threatDashboard);

  const handleFilterChange = (e) => {
    if (e.target.name === "region") {
      const node = Number(
        e.target.childNodes[e.target.selectedIndex].getAttribute("id")
      );
      setFilterState({
        ...filterState,
        region: { id: node, name: e.target.value.toUpperCase() },
      });
    } else if (e.target.name === "year") {
      setFilterState({
        ...filterState,
        [e.target.name]: Number(e.target.value),
      });
    } else {
      setFilterState({
        ...filterState,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsSuccessful(true);
  };

  useEffect(() => {
    dispatch(getThreatSource());
    dispatch(getDropDownInfo());
    // Delay the appearance of the card for a smooth animation
    const delay = setTimeout(() => {
      setIsCardVisible(true);
    }, 200);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    filterState.threatSourceDuration && clickedThreatSourceId
      ? dispatch(
          getSelectedThreatSourceBreakdown(
            clickedThreatSourceId,
            filterState.threatSourceDuration,
            filterState.year
          )
        )
      : dispatch(resetThreatBreakdown());
  }, [clickedThreatSourceId, filterState, dispatch]);

  useEffect(() => {}, [threatBreakdown, dispatch]);

  useEffect(() => {
    setIsSuccessful(false);
  }, [isSuccessful]);

  return (
    <Layout>
      <ChartPageStyle>
        {!fetchingThreatEntries && !fetchingThreatEntriesError && (
          <FilterWrapper>
            <p>Filter by:</p>
            <div>
              <Select
                name="threatSourceDuration"
                data={filterOptions}
                formData={filterState}
                handleChange={handleFilterChange}
                placeholder={"Choose duration"}
                defaulValue={filterState.threatSourceDuration}
              />
              <Select
                name="year"
                data={year}
                formData={filterState}
                handleChange={handleFilterChange}
                defaulValue={filterState.year}
                placeholder={"Choose year"}
              />
            </div>
          </FilterWrapper>
        )}
        {isCardVisible && (
          <ThreatSourceLines
            setId={setClickedThreatSourceId}
            filter={filterState}
          />
        )}
        {Object.keys(threatBreakdown)?.length > 0 ? (
          <div className={`selected-wrapper`} ref={selectedRef}>
            <div className="title-wrapper">
              <p>
                <span>{clickedThreatSourceId.name}</span> Threats breakdown by
                categories
              </p>
            </div>
            <div className="charts-breakdown">
              <div className={`selected-chart-wrapper`}>
                <CategoryLines />
              </div>
              <div className={`selected-chart-wrapper pie`}>
                <ThreatSourcePie />
              </div>
            </div>
          </div>
        ) : null}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalFormWrapper>
            <div className="top">
              <h2>Update threat data</h2>
              <div className="close">
                <CloseBtn onClick={handleClose} />
              </div>
            </div>
            <div className="edit-container">
              <ThreatEntryForm
                dropDownInfo={dropDown}
                handleClose={handleClose}
                threats={threatSource}
                setIsSuccessful={setIsSuccessful}
              />
            </div>
          </ModalFormWrapper>
        </Modal>
      </ChartPageStyle>
    </Layout>
  );
}
