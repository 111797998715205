import {
	Route,
	Routes,
	BrowserRouter as Router,
	Navigate,
} from "react-router-dom";
import { Welcome } from "./pages";
import "./App.css";

import { routes } from "./routes/Routes";
import NotFound from "./pages/NotFound";
import PrivateRoutes from "./routes/PrivateRoutes";
import { getUserLevel } from "./utils/helperFunction";
import UsageDashboard from "./pages/UsageDashboard";

function App() {
	let userRole = JSON.parse(localStorage.getItem("tkn"))?.role;
	let usersLevel = getUserLevel(userRole);
	
	
	return (
		<Router>
			<Routes>
				{userRole ? (
					<>
						{routes.map(
							(route, i) =>
								usersLevel >= route.level && (
									<Route
										key={i}
										path={route.path}
										element={
											<PrivateRoutes>
												<route.component />
											</PrivateRoutes>
										}></Route>
								)
						)}
						<Route path="/login" element={<Navigate to="/" />} />
						<Route path="*" element={<NotFound />} />
					</>
				) : (
					<>
						<Route path="/login" element={<Welcome />} />
						<Route path="*" element={<Navigate to="/login" />} />
					</>
				)}
			</Routes>
		</Router>
	);
}

export default App;
