import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import deviceReducer from "./Zte/Device/DeviceReducer";
import usersReducers from "./Zte/ZteUsers/ZteUsersReducerss";
import issueReducer from "./Zte/Issue/IssueReducer";
import ztnaReducer from "./Zte/Ztna/ZtnaReducerss";
import ztnaDashboardReducer from "./Zte/ZtnaDashboard/ZtnaDashboardReducers";
import threatDashboardReducer from "./threats/ThreatDashboard/ThreatDashboardReducer";
import usageReducer from "./Zte/Usage/UsageReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
	devices: deviceReducer,
	users: usersReducers,
	issues: issueReducer,
	ztna: ztnaReducer,
	ztnaDashboard: ztnaDashboardReducer,
	threatDashboard: threatDashboardReducer,
	usage: usageReducer
});

export default persistReducer(persistConfig, rootReducer);
