import React from 'react'
import Navbar from "./NavBar";
import { MainStyle } from './Molecules.style';
import { fetchLinks } from '../../utils/fetchLinks';

const Layout = ({children}) => {
		let userRole = JSON.parse(localStorage.getItem("tkn"))?.role;

  return (
		<>
			<Navbar navlinks={fetchLinks(userRole)}/>
			<MainStyle>{children}</MainStyle>
		</>
	);
}

export default Layout