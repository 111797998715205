import {
  getActiveUsersPerService,
  getAssignedUsersPerService,
  getActiveVsAssignedUsers,
  averageConnectionLength,
  activeUsersByMonth,
  weeklyConcurrentConnection,
  dailyConcurrentConnection,
  activeMonthlyUser,
  services,
  countryActiveMonthlyUsers,
} from "../../../api/zte/usage";
import {
  activeUserHistoryByRegion,
  activeUserHistoryByService,
  hourlyConnectionTrend,
} from "../../../api/zte/ztnaDashboard";
import {
  // GET_ACTIVE_USERS_PER_SERVICE_START,
  GET_ACTIVE_USERS_PER_SERVICE_FAILURE,
  GET_ACTIVE_USERS_PER_SERVICE_SUCCESS,
  GET_ASSIGNED_USERS_PER_SERVICE_FAILURE,
  GET_ASSIGNED_USERS_PER_SERVICE_SUCCESS,
  GET_ACTIVE_VS_ASSIGNED_USERS_FAILURE,
  GET_ACTIVE_VS_ASSIGNED_USERS_SUCCESS,
  GET_ACTIVE_CONNECTION_LENGTH_SUCCESS,
  GET_ACTIVE_CONNECTION_LENGTH_FAILURE,
  GET_ACTIVE_MONTHLY_USERS_START,
  GET_ACTIVE_MONTHLY_USERS_SUCCESS,
  GET_ACTIVE_MONTHLY_USERS_FAILURE,
  GET_WEEKLY_CONCURRENT_CONNECTION_START,
  GET_WEEKLY_CONCURRENT_CONNECTION_SUCCESS,
  GET_WEEKLY_CONCURRENT_CONNECTION_FAILURE,
  GET_DAILY_CONCURRENT_CONNECTION_SUCCESS,
  GET_DAILY_CONCURRENT_CONNECTION_FAILURE,
  GET_DAILY_CONCURRENT_CONNECTION_START,
  MONTHLY_ACTIVE_USERS_FAILURE,
  MONTHLY_ACTIVE_USERS_START,
  MONTHLY_ACTIVE_USERS_SUCCESS,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  GET_COUNTRY_MONTHLY_ACTIVE_USERS_START,
  GET_COUNTRY_MONTHLY_ACTIVE_USERS_SUCCESS,
  GET_COUNTRY_MONTHLY_ACTIVE_USERS_FAILURE,
  GET_ACTIVE_USER_SERVICE_HISTORY_SUCCESS,
  GET_ACTIVE_USER_SERVICE_HISTORY_FAILURE,
  GET_ACTIVE_USER_REGION_HISTORY_SUCCESS,
  GET_ACTIVE_USER_REGION_HISTORY_FAILURE,
  GET_HOURLY_CONNECTION_TREND_START,
  GET_HOURLY_CONNECTION_TREND_FAILURE,
  GET_HOURLY_CONNECTION_TREND_SUCCESS,
} from "./UsageActionTypes";
import { toast } from "react-toastify";

export const getUsersActivePerService = () => async (dispatch) => {
  const { data, error } = await getActiveUsersPerService();
  if (data) {
    dispatch({
      type: GET_ACTIVE_USERS_PER_SERVICE_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_ACTIVE_USERS_PER_SERVICE_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const getUsersAssignedPerService = () => async (dispatch) => {
  const { data, error } = await getAssignedUsersPerService();
  if (data) {
    dispatch({
      type: GET_ASSIGNED_USERS_PER_SERVICE_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_ASSIGNED_USERS_PER_SERVICE_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const getAverageConnectionLength = () => async (dispatch) => {
  const { data, error } = await averageConnectionLength();
  if (data) {
    dispatch({
      type: GET_ACTIVE_CONNECTION_LENGTH_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_ACTIVE_CONNECTION_LENGTH_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const getUsersActiveVsAssigned = () => async (dispatch) => {
  const { data, error } = await getActiveVsAssignedUsers();
  if (data) {
    dispatch({
      type: GET_ACTIVE_VS_ASSIGNED_USERS_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_ACTIVE_VS_ASSIGNED_USERS_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const getActiveUsersByMonth = () => async (dispatch) => {
  dispatch({
    type: GET_ACTIVE_MONTHLY_USERS_START,
  });
  const { data, error } = await activeUsersByMonth();
  if (data) {
    dispatch({
      type: GET_ACTIVE_MONTHLY_USERS_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_ACTIVE_MONTHLY_USERS_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const getWeeklyConcurrentConnection = (year) => async (dispatch) => {
  dispatch({
    type: GET_WEEKLY_CONCURRENT_CONNECTION_START,
  });
  const { data, error } = await weeklyConcurrentConnection(year);
  if (data) {
    dispatch({
      type: GET_WEEKLY_CONCURRENT_CONNECTION_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_WEEKLY_CONCURRENT_CONNECTION_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const getDailyConcurrentConnection = (year) => async (dispatch) => {
  dispatch({
    type: GET_DAILY_CONCURRENT_CONNECTION_START,
  });
  const { data, error } = await dailyConcurrentConnection(year);
  if (data) {
    dispatch({
      type: GET_DAILY_CONCURRENT_CONNECTION_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_DAILY_CONCURRENT_CONNECTION_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getMonthlyActiveUsers = () => async (dispatch) => {
  dispatch({
    type: MONTHLY_ACTIVE_USERS_START,
  });
  const { data, error } = await activeMonthlyUser();
  if (data) {
    dispatch({
      type: MONTHLY_ACTIVE_USERS_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: MONTHLY_ACTIVE_USERS_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getServices = () => async (dispatch) => {
  const { data, error } = await services();
  if (data) {
    dispatch({
      type: GET_SERVICES_SUCCESS,
      payload: data?.data.map((service) => service.name),
    });
  } else {
    dispatch({
      type: GET_SERVICES_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const getCountryActiveMonthlyUsers = (year) => async (dispatch) => {
  dispatch({ type: GET_COUNTRY_MONTHLY_ACTIVE_USERS_START });
  const { data, error } = await countryActiveMonthlyUsers(year);
  if (data) {
    dispatch({
      type: GET_COUNTRY_MONTHLY_ACTIVE_USERS_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_COUNTRY_MONTHLY_ACTIVE_USERS_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const getActiveUserHistoryByService = () => async (dispatch) => {
  const { data, error } = await activeUserHistoryByService();
  if (data) {
    dispatch({
      type: GET_ACTIVE_USER_SERVICE_HISTORY_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_ACTIVE_USER_SERVICE_HISTORY_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
export const getActiveUserHistoryByRegion = () => async (dispatch) => {
  const { data, error } = await activeUserHistoryByRegion();
  if (data) {
    dispatch({
      type: GET_ACTIVE_USER_REGION_HISTORY_SUCCESS,
      payload: data?.data,
    });
  } else {
    dispatch({
      type: GET_ACTIVE_USER_REGION_HISTORY_FAILURE,
      payload: error,
    });
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};

export const hourlyConnectionTrendStart = () => {
  return {
    type: GET_HOURLY_CONNECTION_TREND_START,
  };
};

export const hourlyConnectionTrendSuccess = (payload) => {
  return {
    type: GET_HOURLY_CONNECTION_TREND_SUCCESS,
    payload,
  };
};

export const hourlyConnectionTrendFailed = (payload) => {
  return {
    type: GET_HOURLY_CONNECTION_TREND_FAILURE,
    payload,
  };
};

export const getHourlyConnectionTrend = () => async (dispatch) => {
  dispatch(hourlyConnectionTrendStart());
  const { data, error } = await hourlyConnectionTrend();
  if (data && data?.statusCode === "90000") {
    dispatch(hourlyConnectionTrendSuccess(data));
  } else if (data?.statusCode === "10001") {
    toast.error(data.message);
  } else {
    dispatch(hourlyConnectionTrendFailed(error));
    toast.error(error?.errors[Object.keys(error?.errors)[0]][0]);
  }
};
