import { useEffect, useState } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import { Table } from "../../atoms";
import { structureData } from "../../../utils/FormatData";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceAssignmentSuggestions } from "../../../Redux/Zte/Device/DeviceActions";

const DeviceMoveHistory = () => {
  const headers = [
    "Device Name",
    "User Principal Name",
    "Reason",
    "Status Description",
  ];
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeviceAssignmentSuggestions());
    // eslint-disable-next-line
  }, [dispatch]);

  const { assignmentSuggestions, fetchingAssignmentSuggestions } = useSelector(
    (state) => state.devices
  );

  return (
    <AdminCardStyles>
      <div className="table-style">
        <Table
          headers={headers}
          data={structureData("assignmentSuggestions", assignmentSuggestions?.data)}
          loading={fetchingAssignmentSuggestions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={assignmentSuggestions?.totalCount}
          pageSize={10}
        />
      </div>
    </AdminCardStyles>
  );
};

export default DeviceMoveHistory;
