import API from "../axio";
import APIForm from "../axiosForm";

export const getZtnaServiceGroup = async () => {
  try {
    const { data } = await API.get(`/Ztna/list-service-groups`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getZtnaServices = async () => {
  try {
    const { data } = await API.get(`/Ztna/list-services`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addZtnaServiceGroup = async (payload) => {
  try {
    const { data } = await API.post(`/Ztna/add-service-group`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addZtnaService = async (payload) => {
  try {
    const { data } = await APIForm.post(`/Ztna/add-service`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addZtnaServiceIdentifier = async (payload) => {
  try {
    const { data } = await API.post(
      `/Ztna/add-service-identifier`,
      payload
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getZtnaServiceIdentifiers = async () => {
  try {
    const { data } = await API.get(`/Ztna/list-service-identifiers`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const uploadZenFile = async (payload) => {
  try {
    const { data } = await APIForm.post(`/Ztna/upload-zen-file`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const uploadFazFile = async (payload) => {
  try {
    const { data } = await APIForm.post(`/Ztna/upload-faz-file`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAllLocationTypes = async () => {
  try {
    const { data } = await API.get(`/Ztna/list-location-types`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const addIp = async (payload) => {
  try {
    const { data } = await API.post(`/Ztna/add-ip`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAllOwnedIps = async () => {
  try {
    const { data } = await API.get(`/Ztna/list-owned-ips`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const updateOwnedIps = async (payload) => {
  try {
    const { data } = await API.patch(
      `/Ztna/update-ownedip-date-removed`,
      payload
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const assignGroupToService = async (payload) => {
  try {
    const { data } = await API.post(
      `/Ztna/assign-group-to-identifier`,
      payload
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getJobsRuntime = async () => {
  try {
    const { data } = await API.get(`/Ztna/jobs-last-run-time`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getActiveSessions = async (currentPage, searchquery) => {
  const searchQuery = searchquery || "";

  try {
    const { data } = await API.get(
      `/Ztna/active-sessions?PageNumber=${currentPage}&PageSize=10&Name=${searchQuery}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const deauthenticateSessions = async (id, reasonId) => {
  try {
    const { data } = await API.post(`/Ztna/deauthenticate-session?id=${id}&reasonId=${reasonId}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getDeauthReasons = async () => {
  try {
    const { data } = await API.get(`/Ztna/list-deauth-reasons`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getLinkedAzureAdGroups = async (searchQuery) => {
  try {
    const { data } = await API.get(`/Ztna/linked-azuread-groups?ztnaServiceName=${searchQuery}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const saveActiveUsers = async (date) => {
  try {
    const { data } = await API.post(`/Ztna/save-active-users?date=${date}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const saveActiveUsersByCountry = async (date) => {
  try {
    const { data } = await API.post(`/Ztna/save-active-users-by-country?date=${date}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const saveUpdateConnections = async () => {
  try {
    const { data } = await API.post(`/Ztna/save-update-connections`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const saveUpdateConnectionsByDate = async (payload) => {
  try {
    const { data } = await API.post(`/Ztna/save-update-connections-by-date`, payload);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};