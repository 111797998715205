import React, { useState } from "react";
import { ChartPageStyle } from "./Pages.style";
import Layout from "../components/molecules/Layout";
import SectionToggle from "../components/molecules/SectionToggle/SectionToggle";
import UsageDashboard from "./UsageDashboard";
import UserExperienceDashboard from "../components/organism/ZtnaCharts/UserExperienceDashboard";
import GeoLocationDashboard from "../components/organism/ZtnaCharts/GeoLocationDashboard";

export default function BasicModal() {
  const [activeTab, setActiveTab] = useState("Usage");
  const tabList = ["Usage", "User Experience", "Geo"];

  return (
    <Layout>
      <SectionToggle
        tabList={tabList}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      >
        {activeTab === "Usage" ? (
          <ChartPageStyle>
            <UsageDashboard />
          </ChartPageStyle>
        ) : activeTab === "User Experience" ? (
          <ChartPageStyle>
            <UserExperienceDashboard />
          </ChartPageStyle>
        ) : activeTab === "Geo" ? (
          <ChartPageStyle>
            <GeoLocationDashboard />
          </ChartPageStyle>
        ) : null}
      </SectionToggle>
    </Layout>
  );
}
