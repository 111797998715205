import SearchDropDown from "./AdminCard/SearchDropdown";
import { SetOnHoldStyle } from "./Molecules.style";

const SetOnHold = ({ holdReasons, setSelectedReason }) => {
  return (
    <SetOnHoldStyle>
      <SearchDropDown
        title={"Select Reason"}
        type="select"
        data={holdReasons}
        group={"reason"}
        setData={setSelectedReason}
      />
    </SetOnHoldStyle>
  );
};

export default SetOnHold;
