export const GET_DROPDOWN_INFO_START = "GET_DROPDOWN_INFO_START";
export const GET_DROPDOWN_INFO_SUCCESS = "GET_DROPDOWN_INFO_SUCCESS";
export const GET_DROPDOWN_INFO_FAILED = "GET_DROPDOWN_INFO_FAILED";
export const UPDATE_THREATS_START = "UPDATE_THREATS_START";
export const UPDATE_THREATS_SUCCESS = "UPDATE_THREATS_SUCCESS";
export const UPDATE_THREATS_FAILED = "UPDATE_THREATS_FAILED";
export const GET_THREATSOURCE_BREAKDOWN_START = "GET_THREATSOURCE_BREAKDOWN_START";
export const GET_THREATSOURCE_BREAKDOWN_SUCCESS = "GET_THREATSOURCE_BREAKDOWN_SUCCESS";
export const GET_THREATSOURCE_BREAKDOWN_FAILED = "UPDATE_THREATSOURCE_BREAKDOWN_FAILED";
export const GET_THREATSOURCE_ENTRIES_START = "GET_THREATSOURCE_ENTRIES_START";
export const GET_THREATSOURCE_ENTRIES_SUCCESS = "GET_THREATSOURCE_ENTRIES_SUCCESS";
export const GET_THREATSOURCE_ENTRIES_FAILED = "UPDATE_THREATSOURCE_ENTRIES_FAILED";
export const GET_THREATSOURCE_START = "GET_THREATSOURCE_START";
export const GET_THREATSOURCE_SUCCESS = "GET_THREATSOURCE_SUCCESS";
export const GET_THREATSOURCE_FAILED = "UPDATE_THREATSOURCE_FAILED";
export const RESET_THREATSOURCE_BREAKDOWN = " RESET_THREATSOURCE_BREAKDOWN";