import React from "react";
import logo from "../assets/images/logo.png";
import { Button } from "../components/atoms";
import { WelcomePageStyle } from "./Pages.style";
import { useMsal } from "@azure/msal-react";

const { REACT_APP_REDIRECT_URL } = process.env;
const { REACT_APP_API_LOGIN_URL } = process.env;

const Welcome = () => {
	const { instance } = useMsal();

	const handleLogin = () => {
		instance
			.loginPopup({
				redirectUri: REACT_APP_REDIRECT_URL, // Make sure this matches your Azure AD configuration
			})
			.then((result) => {
				if (result) {
					let role = result?.idTokenClaims.roles;
					let token = result?.accessToken;
					let lastLoggedIn = new Date().toISOString();
					localStorage.setItem("tkn", JSON.stringify({ token, role, lastLoggedIn }));
					// API authentication and cookies retrieval
				}
				// COOKIES AUTH
					window.location.href  = REACT_APP_API_LOGIN_URL;
			})
			.catch((e) => {
				console.log(e);
			});
	};
	return (
		<WelcomePageStyle>
			<div id="wrapper">
				<img src={logo} alt="Armacell's logo" />
			</div>
			<Button onclick={handleLogin} fill large>
				Click here to continue
			</Button>
		</WelcomePageStyle>
	);
};

export default Welcome;
