import React, { useEffect, useState } from "react";
import Layout from "../components/molecules/Layout";
import { SearchStyle } from "./Pages.style";
import { Table } from "../components/atoms";
import { structureData } from "../utils/FormatData";
import { fetchAllIssues } from "../Redux/Zte/Issue/IssueAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SortFilter from "../components/molecules/SortFilter/SortFilter";

const Issues = () => {
  const { issues, fetchingAllIssues } = useSelector((state) => state?.issues);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState([0]);
  const [sortParams, setSortParams] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [reportingFilter, setReportingFilter] = useState(false);
  const [reportingFilterDateFrom, setReportingFilterDateFrom] = useState(
    new Date().toJSON()
  );
  const [reportingFilterDateTo, setReportingFilterDateTo] = useState(
    new Date().toJSON()
  );
  const [resolutionFilter, setResolutionFilter] = useState(false);
  const [resolutionFilterDateFrom, setResolutionFilterDateFrom] = useState(
    new Date().toJSON()
  );
  const [resolutionFilterDateTo, setResolutionFilterDateTo] = useState(
    new Date().toJSON()
  );
  const headers = ["Detail", "Device", "Status", "Reported", "Resolved"];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/issue-details/${id}`);
  };

  useEffect(() => {
    dispatch(
      fetchAllIssues(
        currentPage,
        status,
        sortParams,
        sortOrder,
        reportingFilter,
        reportingFilterDateFrom,
        reportingFilterDateTo,
        resolutionFilter,
        resolutionFilterDateFrom,
        resolutionFilterDateTo
      )
    );
    // eslint-disable-next-line
  }, [dispatch, currentPage]);

  const handleApply = () => {
    dispatch(
      fetchAllIssues(
        currentPage,
        status,
        sortParams,
        sortOrder,
        reportingFilter,
        reportingFilterDateFrom,
        reportingFilterDateTo,
        resolutionFilter,
        resolutionFilterDateFrom,
        resolutionFilterDateTo
      )
    );
  };
  return (
    <Layout>
      <SortFilter
        setStatus={setStatus}
        setSortParams={setSortParams}
        setSortOrder={setSortOrder}
        setReportingFilter={setReportingFilter}
        setReportingFilterDateFrom={setReportingFilterDateFrom}
        setReportingFilterDateTo={setReportingFilterDateTo}
        resolutionFilter={resolutionFilter}
        setResolutionFiltersetResolutionFilter
        setResolutionFilter={setResolutionFilter}
        reportingFilter={reportingFilter}
        setResolutionFilterDateFrom={setResolutionFilterDateFrom}
        setResolutionFilterDateTo={setResolutionFilterDateTo}
        handleApply={handleApply}
      />

      <SearchStyle>
        <div className="table-wrapper">
          <Table
            data={structureData("issues", issues.results)}
            headers={headers}
            loading={fetchingAllIssues}
            handleRowClick={handleRowClick}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={issues?.totalCount}
            pageSize={20}
          />
        </div>
      </SearchStyle>
    </Layout>
  );
};

export default Issues;
