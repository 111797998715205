import {
	getThreatDropdownInfo,
	updateThreat,
	getThreatSourceBreakdown,
	getThreatEntries,
	getSource,
} from "../../../api/threats/threatsDashboard";
import { toast } from "react-toastify";
import {
	GET_DROPDOWN_INFO_FAILED,
	GET_DROPDOWN_INFO_START,
	GET_DROPDOWN_INFO_SUCCESS,
	UPDATE_THREATS_FAILED,
	UPDATE_THREATS_START,
	UPDATE_THREATS_SUCCESS,
	GET_THREATSOURCE_BREAKDOWN_FAILED,
	GET_THREATSOURCE_BREAKDOWN_START,
	GET_THREATSOURCE_BREAKDOWN_SUCCESS,
	GET_THREATSOURCE_ENTRIES_FAILED,
	GET_THREATSOURCE_ENTRIES_START,
	GET_THREATSOURCE_ENTRIES_SUCCESS,
	GET_THREATSOURCE_FAILED,
	GET_THREATSOURCE_START,
	GET_THREATSOURCE_SUCCESS,
	RESET_THREATSOURCE_BREAKDOWN,
} from "./ThreatDashboardActionsTypes";

const getDropdownInfoStart = () =>{
    return {
        type:GET_DROPDOWN_INFO_START
    }
}
const getDropdownInfoSuccess = (payload) =>{
    return {
        type:GET_DROPDOWN_INFO_SUCCESS,
        payload
    }
}
const getDropdownInfoFailure = (payload) =>{
    return {
        type:GET_DROPDOWN_INFO_FAILED,
        payload
    }
}

export const getDropDownInfo = ()=> async(dispatch)=>{
    dispatch(getDropdownInfoStart());
    const {data, error} = await getThreatDropdownInfo();
    if(data){
        dispatch(getDropdownInfoSuccess(data.data));
    }else{
        dispatch(getDropdownInfoFailure(error))
        toast.error("Ooops something went wrong!!!");
    }
}
export const updateThreatStart = ()=>{
    return {
        type: UPDATE_THREATS_START
    }
}
export const updateThreatSuccess = (payload)=>{
    return {
        type: UPDATE_THREATS_SUCCESS,
    }
}
export const updateThreatFailure = (payload)=>{
    return {
        type: UPDATE_THREATS_FAILED,
        payload
    }
}
export const updateThreatData = (payload) => async (dispatch) => {
	dispatch(updateThreatStart());
	const { data, error } = await updateThreat(payload);
	if (data) {
		dispatch(updateThreatSuccess(data));
        toast.success(data);
	} else {
		dispatch(updateThreatFailure(error));
        toast.error("Ooops something went wrong!!!");
	}
};

const getThreatBreakdownStart = () => {
	return {
		type: GET_THREATSOURCE_BREAKDOWN_START,
	};
};
const getThreatBreakdownSuccess = (payload) => {
	return {
		type: GET_THREATSOURCE_BREAKDOWN_SUCCESS,
		payload,
	};
};
export const resetThreatBreakdown = () => {
	return {
		type: RESET_THREATSOURCE_BREAKDOWN,
	};
};
const getThreatBreakdownFailure = (payload) => {
	return {
		type: GET_THREATSOURCE_BREAKDOWN_FAILED,
		payload,
	};
};
export const getSelectedThreatSourceBreakdown = (clickedThreatSourceId, duration, year) => async (dispatch) => {
		dispatch(getThreatBreakdownStart());
		const { data, error } = await getThreatSourceBreakdown(
			clickedThreatSourceId,
			duration,
			year
		);
		if (data) {
			dispatch(getThreatBreakdownSuccess(data));
		} else {
			dispatch(getThreatBreakdownFailure(error));
                toast.error("Ooops something went wrong!!!");
		}
};
const getThreatEntriesStart = () => {
	return {
		type: GET_THREATSOURCE_ENTRIES_START
	};
};
const getThreatEntriesSuccess = (payload) => {
	return {
		type: GET_THREATSOURCE_ENTRIES_SUCCESS,
		payload
	};
};
const getThreatEntriesFailure = (payload) => {
	return {
		type: GET_THREATSOURCE_ENTRIES_FAILED,
		payload
	};
};

export const getThreatSourceEntries = (duration, year)=> async(dispatch)=>{
dispatch(getThreatEntriesStart());
const {data, error} = await getThreatEntries(duration, year);
if(data){
    dispatch(getThreatEntriesSuccess(data));
}
else{
    dispatch(getThreatEntriesFailure(error));
    toast.error("Ooops something went wrong!!!");
}
}

const getThreatSourceStart = () => {
	return {
		type: GET_THREATSOURCE_START
	};
};
const getThreatSourceSuccess = (payload) => {
	return {
		type: GET_THREATSOURCE_SUCCESS,
		payload
	};
};
const getThreatSourceFailure = (payload) => {
	return {
		type: GET_THREATSOURCE_FAILED,
		payload
	};
};

export const getThreatSource = ()=> async(dispatch)=>{
dispatch(getThreatSourceStart());
const { data, error } = await getSource();
if(data){
    dispatch(getThreatSourceSuccess(data));
}
else{
    dispatch(getThreatSourceFailure(error));
    toast.error("Ooops something went wrong!!!")
}
}