import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { geoLocationData } from "../../../Redux/Zte/ZtnaDashboard/ZtnaDashboardActions";
import { formatMapData } from "../../../utils/formatZtnaChartData";
import { Select } from "../../atoms";
import { FilterWrapper, MapsWrapper } from "../../../pages/Pages.style";
import ZtnaMaps from "../maps/ZnaMaps";

export const GeoLocationDashboard = () => {
  const dispatch = useDispatch();
  const [interval, setInterval] = useState("");
  const [intervalText, setIntervalText] = useState("");
  const filterOptions = ["All time", "Month", "Year"];

  useEffect(() => {
    dispatch(geoLocationData(interval));
  }, [interval, dispatch]);

  const { geolocationData } = useSelector((state) => state.ztnaDashboard);
  const timeOptions = Object.keys(geolocationData);
  const initialTIme = timeOptions[0];
  const [allDataFormatted, setAllDataFormatted] = useState(
    formatMapData(geolocationData)
  );
  let newData = formatMapData(geolocationData);

  const handleTimeChange = (e) => {
    let val = newData.filter((item) => item.title === e.target.value);
    setAllDataFormatted(val);
  };

  useEffect(() => {
    let val = newData.filter((item) => item.title === initialTIme);
    setAllDataFormatted(val);
    // eslint-disable-next-line
  }, [initialTIme]);

  const handleFilterChange = (e) => {
    if (e.target.value === "month") {
      setInterval(0);
      setIntervalText(e.target.value);
    } else if (e.target.value === "year") {
      setInterval(1);
      setIntervalText(e.target.value);
    } else {
      // eslint-disable-next-line
      setInterval("");
      setIntervalText(e.target.value);
    }
  };

  return (
    <>
      {timeOptions ? (
        <div>
          <MapsWrapper className="filter-container">
            {" "}
            <FilterWrapper>
              <p>Filter by:</p>
              <div>
                <Select
                  name="interval"
                  data={filterOptions}
                  handleChange={handleFilterChange}
                  placeholder={"Choose interval"}
                  defaulValue={intervalText}
                />
              </div>
            </FilterWrapper>
            {interval === 0 ? (
              <FilterWrapper>
                <p>Month</p>
                <div>
                  <Select
                    name="interval"
                    data={timeOptions}
                    handleChange={handleTimeChange}
                    placeholder={"Choose month"}
                    defaulValue={intervalText}
                  />
                </div>
              </FilterWrapper>
            ) : interval === 1 ? (
              <FilterWrapper>
                <p>Year</p>
                <div>
                  <Select
                    name="interval"
                    data={timeOptions}
                    handleChange={handleTimeChange}
                    placeholder={"Choose year"}
                    defaulValue={intervalText}
                  />
                </div>
              </FilterWrapper>
            ) : null}
          </MapsWrapper>
          <ZtnaMaps mapData={allDataFormatted[0]} />
        </div>
      ) : (
        <p>Loading</p>
      )}
    </>
  );
};

export default GeoLocationDashboard;
