import moment from "moment";

const formatThreatDate = (info) => {
  let dateArry = Object.keys(info).sort((left, right) =>
    moment.utc(left.date).diff(moment.utc(right.date))
  );
  let newFormat = dateArry.map((data) => {
    let newData = {
      threatSourceId: info[data]?.threatSourceId,
      frequency: info[data]?.frequency ?? 0,
      date: data,
    };
    return newData;
  });

  return newFormat;
};

export const formattedThreatSourceChartData = (info) => {
  const { Email, Endpoint, Internet } = info;
  let formatedEndpoint = Endpoint && formatThreatDate(Endpoint);
  let formatedEmail = Email && formatThreatDate(Email);
  let formatedInternet = Internet && formatThreatDate(Internet);
  return {
    email: formatedEmail,
    endpoint: formatedEndpoint,
    internet: formatedInternet,
  };
};
export const formattedCategoryChartData = (info) => {
  let returnObj = {};
  const keyArray = Object.keys(info);
  for (let i = 0; i < keyArray?.length; i++) {
    let dateArr = Object.keys(info[keyArray[i]]);
    let data = info[keyArray[i]];
    for (let dateIndex = 0; dateIndex < dateArr.length; dateIndex++) {
      returnObj[keyArray[i]] = formatThreatDate(data);
    }
  }
  return returnObj;
};

export const formatThreatEntriesForHighChart = (data, setId) => {
  const categories = [];
  const seriesData = {};
  // Iterate over each threat source

  Object.keys(data).forEach((threatSource) => {
    seriesData[threatSource] = [];

    // Iterate over each month for the current threat source
    Object.keys(data[threatSource]).forEach((month) => {
      // Push the month to the categories array if it's not already added
      if (!categories.includes(month)) {
        categories.push(month);
      }

      // Push the frequency data to the seriesData array for the current threat source
      seriesData[threatSource].push({
        name: month,
        y: data[threatSource][month]?.frequency || 0,
        threatSourceId: data[threatSource][month]?.threatSourceId,
      });
    });
  });

  return {
    chart: {
      type: "line",
    },
    title: {
      text: "",
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    xAxis: {
      categories: categories,
      title: {
        text: "Month",
      },
    },
    yAxis: {
      title: {
        text: "Threat Frequency",
      },
    },
    series: Object.keys(seriesData).map((threatSource) => ({
      name: threatSource,
      data: seriesData[threatSource],
    })),
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            xAxis: {
              labels: {
                formatter: function () {
                  return this.value.charAt(0);
                },
              },
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              return setId ? setId(this.threatSourceId) : null;
            },
          },
        },
      },
    },
  };
};

const top5 = (data, field) => {
  return data?.sort((a, b) => b[field] - a[field])?.slice(0, 5);
};
export const formatBarData = (
  data,
  title,
  xTitle,
  yTitle,
  xField,
  yField,
  setModal,
  modalData
) => {
  const highestValue = setModal && data?.length > 0 && top5([...data], yField);
  return [
    {
      chart: {
        type: "column",
        events: {
          click: function () {
            setModal(modalData);
          },
        },
      },
      title: {
        text: title,
        align: "left",
      },
      xAxis: {
        categories: data?.length > 0 && data?.map((data) => data?.[xField]),
        crosshair: true,
        accessibility: {
          description: xTitle,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Count",
        },
      },
      tooltip: {
        // valueSuffix: " (1000 MT)",
        format: "{series.name}: {x}<br/> Total: {y}",
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: xTitle,
          data: data?.length > 0 && data?.map((data) => data?.[yField]),
        },
      ],
    },
    {
      chart: {
        type: "column",
        // backgroundColor: "#ccc000",
        events: {
          click: function () {
            setModal(modalData);
          },
        },
      },
      title: {
        text: title,
        align: "left",
      },
      xAxis: {
        categories: highestValue && highestValue?.map((data) => data?.[xField]),
        crosshair: true,
        accessibility: {
          description: xTitle,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Count",
        },
      },
      tooltip: {
        format: "{series.name}: {x}<br/> Total: {y}",
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: xTitle,
          data: highestValue && highestValue?.map((data) => data?.[yField]),
        },
      ],
      credits: {
        enabled: false,
      },
    },
  ];
};

export const formatStackedBarChart = (
  data,
  title,
  yTitle,
  stack,
  xField,
  stack1Title,
  stack2Title,
  stackField1,
  stackField2,
  setModal,
  modalData,
  setShowFilter
) => {
  const highestValue =
    setModal && data.length > 0 && top5([...data], stackField1);
  const stackItem1Data =
    data.length > 0 && data?.map((item) => item?.[stackField1]);
  const stackItem2Data =
    data.length > 0 && data?.map((item) => item?.[stackField2]);
  const topItem1Data =
    data.length > 0 && highestValue?.map((item) => item?.[stackField1]);
  const topItem2Data =
    data.length > 0 && highestValue?.map((item) => item?.[stackField2]);

  return setModal
    ? [
        {
          chart: {
            type: "column",
            events: {
              click: function () {
                setModal(modalData);
              },
            },
          },

          title: {
            text: title,
            align: "left",
          },

          xAxis: {
            categories:
              data?.length > 0 &&
              data?.map((dataPoint) => dataPoint?.serviceName),
          },

          yAxis: {
            // allowDecimals: false,
            min: 0,
            title: {
              text: "Count",
            },
          },

          tooltip: {
            format:
              "<b>{key}</b><br/>{series.name}: {y}<br/>" +
              "Total: {point.stackTotal}",
          },

          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
          series: [
            {
              name: stack1Title,
              data: stackItem1Data,
              stack: stack,
            },
            {
              name: stack2Title,
              data: stackItem2Data,
              stack: stack,
            },
          ],
        },
        {
          chart: {
            type: "column",
            events: {
              click: function () {
                setModal && setModal(modalData);
                setShowFilter && setShowFilter(true);
              },
            },
          },

          title: {
            text: title,
            align: "left",
          },

          xAxis: {
            categories:
              highestValue &&
              highestValue?.map((dataPoint) => dataPoint?.[xField]),
          },

          yAxis: {
            // allowDecimals: false,
            min: 0,
            title: {
              text: "Count",
            },
          },

          tooltip: {
            format:
              "<b>{key}</b><br/>{series.name}: {y}<br/>" +
              "Total: {point.stackTotal}",
          },

          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
          series: [
            {
              name: stack1Title,
              data: topItem1Data,
              stack: stack,
            },
            {
              name: stack2Title,
              data: topItem2Data,
              stack: stack,
            },
          ],
          credits: {
            enabled: false,
          },
        },
      ]
    : {
        chart: {
          type: "column",
          events: {
            click: function () {
              setModal && setModal(modalData);
              setShowFilter && setShowFilter(true);
            },
          },
        },

        title: {
          text: title,
          align: "left",
        },

        xAxis: {
          categories:
            data.length > 0 && data?.map((dataPoint) => dataPoint?.[xField]),
        },

        yAxis: {
          // allowDecimals: false,
          min: 0,
          title: {
            text: yTitle,
          },
        },

        tooltip: {
          format:
            "<b>{key}</b><br/>{series.name}: {y}<br/>" +
            "Total: {point.stackTotal}",
        },

        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        series: [
          {
            name: stack1Title,
            data: stackItem1Data,
            stack: stack,
          },
          {
            name: stack2Title,
            data: stackItem2Data,
            stack: stack,
          },
        ],
        credits: {
          enabled: false,
        },
      };
};

export const formatCategoryBreakdown = (
  data,
  setId,
  setDescription,
  setShowTip
) => {
  const categories = [];
  const seriesData = {};
  // Iterate over each threat source
  Object.keys(data).forEach((threatSource) => {
    seriesData[threatSource] = [];

    // Iterate over each month for the current threat source
    Object.keys(data[threatSource]).forEach((month) => {
      // Push the month to the categories array if it's not already added
      if (!categories.includes(month)) {
        categories.push(month);
      }

      // Push the frequency data to the seriesData array for the current threat source
      seriesData[threatSource].push({
        name: month,
        y: data[threatSource][month]?.frequency || 0,
        threatSourceId: data[threatSource][month]?.threatSourceId,
      });
    });
  });

  return {
    chart: {
      type: "line",
      width: null,
    },
    title: {
      text: "Category Frequency Chart",
    },

    xAxis: {
      categories: categories,
      title: {
        text: "Month",
      },
    },
    yAxis: {
      title: {
        text: "Threat Frequency",
      },
    },
    series: Object.keys(seriesData).map((threatSource) => ({
      name: threatSource,
      data: seriesData[threatSource],
    })),
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              return setId ? setId(this.threatSourceId) : null;
            },
          },
        },
        events: {
          mouseOver: function () {
            setDescription({
              tip: this.name,
              description: data[this.name][0]?.categoryDescription,
            });
            setShowTip(true);
          },
          mouseOut: function () {
            setShowTip(false);
          },
        },
      },
    },
    legend: {
      itemHoverStyle: {
        cursor: "pointer",
        color: "#3e3e3e",
      },
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            xAxis: {
              labels: {
                formatter: function () {
                  return this.value.charAt(0);
                },
              },
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
};
export const formatCategoryPieBreakdown = (data, title) => {
  const seriesData = [];
  // Iterate over each threat source
  Object.keys(data).forEach((threatSource) => {
    const result = Object.keys(data[threatSource]).reduce(
      (accumulator, current) => {
        return accumulator + (data[threatSource][current]?.frequency || 0);
      },
      0
    );
    seriesData.push({
      name: threatSource,
      y: result,
    });
  });

  return {
    chart: {
      type: "pie",
    },
    title: {
      text: title,
    },
    series: [{ name: "Total", data: seriesData, colorByPoint: true }],

    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 20,
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.y:.1f}",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            xAxis: {
              labels: {
                formatter: function () {
                  return this.value.charAt(0);
                },
              },
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
};

function getDataByType(data, type, type2) {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const result = [];
  const dayObj = {};

  Object.keys(data).forEach((week, i) => {
    days.forEach((day) => {
      const {
        concurrent,
        minConcurrent,
        maxConcurrent,
        unique,
        minUnique,
        maxUnique,
      } = data[week]?.[day] || {};
      if (type) {
        if (dayObj[day]) {
          dayObj[day].push(
            !concurrent &&
              !minConcurrent &&
              !maxConcurrent &&
              !unique &&
              !minUnique &&
              !maxUnique
              ? 0
              : type?.toLowerCase() === "concurrent"
              ? parseFloat(concurrent)
              : type?.toLowerCase() === "unique"
              ? parseFloat(unique)
              : type?.toLowerCase() === "minunique"
              ? parseFloat(minUnique)
              : type?.toLowerCase() === "maxunique"
              ? parseFloat(maxUnique)
              : type?.toLowerCase() === "minconcurrent"
              ? parseFloat(minConcurrent)
              : parseFloat(maxConcurrent)
          );

          // 		!minUnique &&
          // 		!maxUnique
          // 		? 0
          // 		: type2 === "unique"
          // 		? parseFloat(unique)
          // 		: type2 === "minUnique"
          // 		? parseFloat(minUnique)
          // 		: parseFloat(maxUnique)
          // );
        } else {
          dayObj[day] =
            !concurrent && !minConcurrent && !maxConcurrent
              ? [0]
              : type.toLowerCase() === "concurrent"
              ? [parseFloat(concurrent)]
              : type.toLowerCase() === "minconcurrent"
              ? [parseFloat(minConcurrent)]
              : type.toLowerCase() === "unique"
              ? [parseFloat(unique)]
              : type?.toLowerCase() === "minunique"
              ? [parseFloat(minUnique)]
              : type.toLowerCase() === "maxunique"
              ? [parseFloat(maxUnique)]
              : [parseFloat(maxConcurrent)];
        }
      }
    });
  });
  Object.keys(dayObj).forEach((day) => {
    result.push({
      name: day,
      data: dayObj[day],
    });
  });
  return result;
}
export const formatDailyConcurrentBars = (
  data,
  title,
  setModal,
  modalData,
  type,
  type2,
  stack
) => {
  const categories = data && data !== null ? Object.keys(data) : [];
  const seriesData = data && data !== null && getDataByType(data, type, type2);
  const firstTen =
    categories.length > 1
      ? getDataByType(
          {
            [categories[0]]: data[categories[0]],
            [categories[1]]: data[categories[1]],
          },
          type,
          type2
        )
      : categories.length > 0
      ? getDataByType(
          {
            [categories[0]]: data[categories[0]],
          },
          type,
          type2
        )
      : [];
  return [
    {
      chart: {
        type: "column",
      },
      title: {
        text: title,
        align: "left",
      },

      xAxis: {
        categories: categories,
        crosshair: true,
        accessibility: {
          description: "Weeks",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Count",
        },
      },
      // tooltip: {
      // 	valueSuffix: " (1000 MT)",
      // },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: seriesData,
      credits: {
        enabled: false,
      },
    },
    {
      chart: {
        type: "column",
        events: {
          click: function () {
            setModal && setModal(modalData);
            // setShowFilter && setShowFilter(true);
          },
        },
      },
      title: {
        text: title,
        align: "left",
      },
      xAxis: {
        categories: [categories[0], categories[1]],
        crosshair: true,
        accessibility: {
          description: "Weeks",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Count",
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: firstTen,
      credits: {
        enabled: false,
      },
    },
  ];
};
const getWeeklyConcurrentyType = (data, typeField1, typeField2) => {
  const seriesObj = {};
  if (!Array.isArray(data) && data !== null) {
    Object.keys(data).forEach((record) => {
      if (JSON.stringify(seriesObj) === "{}" && typeField1 && typeField2) {
        seriesObj[typeField1] = [+data[record][typeField1]];
        seriesObj[typeField2] = [+data[record][typeField2]];
      } else {
        seriesObj[typeField1].push(+data[record][typeField1]);
        seriesObj[typeField2].push(+data[record][typeField2]);
      }
    });
  }
  return Object.keys(seriesObj).map((type) => {
    return {
      name: type,
      data: seriesObj[type],
    };
  });
};
export const formatWeeklyConcurrentLines = (
  data,
  title,
  setModal,
  modalData,
  typeField1,
  typeField2
) => {
  const series = getWeeklyConcurrentyType(data, typeField1, typeField2);
  return {
    title: {
      text: title,
      align: "left",
    },

    yAxis: {
      title: {
        text: "Count",
      },
    },

    xAxis: {
      categories: data ? Object.keys(data) : [],
    },

    legend: {
      layout: "horizontal",
      // align: "right",
      verticalAlign: "bottom",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: series,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
  };
};

function sortMonthlyActive(data, type) {
  const time = [];
  const result = [];
  const store = {
    AMER: [],
    APAC: [],
    EMEA: [],
  };
  data?.forEach((record) => {
    if (!time?.includes()) {
      time.push(record.timeIdentifier);
    }
    let serviceNameCheck = false;
    record?.serviceGroups?.forEach((group) => {
      if (group?.serviceName.toLowerCase() === type?.toLowerCase()) {
        serviceNameCheck = true;
        store.AMER.push(group?.numberOfActiveUsersAmericas);
        store.EMEA.push(group?.numberOfActiveUsersEurope);
        store.APAC.push(group?.numberOfActiveUsersAsia);
      }
    });
    if (!serviceNameCheck) {
      store.AMER.push(0);
      store.EMEA.push(0);
      store.APAC.push(0);
    }
  });
  Object.keys(store).forEach((group) =>
    result.push({ name: group, data: store[group], stack: type })
  );
  return {
    time,
    result,
  };
}
export const formatStackedMonthlyUsers = (data, type, title) => {
  const { time, result } = sortMonthlyActive(data, type);
  return {
    chart: {
      type: "column",
    },

    title: {
      text: title,
      align: "left",
    },

    xAxis: {
      categories: [...time],
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: "Count",
      },
    },

    tooltip: {
      format:
        "<b>{key}</b><br/>{series.name}: {y}<br/>" +
        "Total: {point.stackTotal}",
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
    },

    series: [...result],
    credits: {
      enabled: false,
    },
  };
};

function sortCountryActive(data, type) {
  const xAxis = [];
  const store = {};

  for (const record in data) {
    xAxis.push(record);
    for (const service in data[record]) {
      if (service && typeof service === "string") {
        if (service === type) {
          for (const country in data[record][service]) {
            let index = 0;
            if (!store[country] && index <= 0) {
              store[country] = [data?.[record][service][country]];
              index += 1;
            } else if (!store[country]) {
              store[country] = [0, data?.[record][service][country]];
            } else {
              store[country]?.push(data?.[record][service][country]);
            }
          }
        }
      }
    }
  }
  return {
    xAxis,
    series: Object.keys(store).map((country) => {
      return { name: country, data: store[country], stack: type };
    }),
  };
}
export const formatCountryMonthly = (data, type, title) => {
  const { xAxis, series } = sortCountryActive(data, type);
  return {
    chart: {
      type: "column",
    },

    title: {
      text: title,
      align: "left",
    },

    xAxis: {
      categories: xAxis,
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: "Count",
      },
    },

    tooltip: {
      format:
        "<b>{key}</b><br/>{series.name}: {y}<br/>" +
        "Total: {point.stackTotal}",
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
    },

    series: series,
    credits: {
      enabled: false,
    },
  };
};
function sortUsersServiceHistory(data) {
  const seriesObj = {};
  const xAxis = [];
  let series = [];
  for (const record in data) {
    let splitRecord = record.split("-");
    let month = splitRecord[0];
    let year = splitRecord[1];
    // Create a Date object with the extracted month and year
    let recordedDate = new Date(year, month - 1).toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
    xAxis.push(recordedDate);
    for (const service in data[record]) {
      if (!seriesObj[service])
        seriesObj[service] = [data[record][service][0].numberOfActiveUsers];
      else {
        seriesObj[service].push(data[record][service][0].numberOfActiveUsers);
      }
    }
  }
  for (const record in seriesObj) {
    series.push({
      name: record,
      data: seriesObj[record],
    });
  }
  return { xAxis, series };
}
export const formatLine = (data, title) => {
  const { xAxis, series } = sortUsersServiceHistory(data);
  return {
    title: {
      text: title,
      align: "left",
    },

    yAxis: {
      title: {
        text: "Count",
      },
    },

    xAxis: {
      categories: data ? xAxis : [],
    },

    legend: {
      layout: "horizontal",
      // align: "right",
      verticalAlign: "bottom",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: series,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
  };
};

function sortUserRegionHistory(data, type) {
  const xAxis = [];
  const seriesObj = { AMER: [], APAC: [], EMEA: [] };
  let series = [];
  for (const record in data) {
    let splittedDate = record.split("-");
    let month = splittedDate[0];
    let year = splittedDate[1];
    let recordedDate = new Date(year, month - 1).toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
    xAxis.push(recordedDate);
    data[record][type]?.forEach((record) => {
      switch (record?.["region"]) {
        case "Europe":
          seriesObj.EMEA.push(record["numberOfActiveUsers"]);
          break;
        case "Asia":
          seriesObj.APAC.push(record["numberOfActiveUsers"]);
          break;
        case "Americas":
          seriesObj.AMER.push(record["numberOfActiveUsers"]);
          break;

        default:
          return;
      }
    });
  }
  for (const record in seriesObj) {
    series.push({
      name: record,
      data: seriesObj[record],
    });
  }
  return { xAxis, series };
}
export const formatRegionHistory = (data, title, type) => {
  const { xAxis, series } = sortUserRegionHistory(data, type);
  return {
    title: {
      text: title,
      align: "left",
    },

    yAxis: {
      title: {
        text: "Count",
      },
    },

    xAxis: {
      categories: data ? xAxis : [],
    },

    legend: {
      layout: "horizontal",
      // align: "right",
      verticalAlign: "bottom",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: series,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
  };
};
export const formatConnectionsMonths = (data) => {
  const months = [];
  for (let i in data) {
    months.push({id: i, name: data[i].month});
  }
  return months;
};

export const formatConnectionsData = (data, type) => {
  const dataSet = data?.averageConnections;
  const resultData = [];

  dataSet?.forEach((day) => {
    const result = [];
    let dataObject = { name: day.day, data: result };
    let dailyAvg = day.hourlyAverages;
    dailyAvg?.forEach((avg) => {
      result.push(avg[type]);
    });
    resultData.push(dataObject);
  });
  return resultData;
};
