import React, { useEffect, useState } from "react";
import Layout from "../components/molecules/Layout";
import { HomeStyle } from "./Pages.style";
import { useDispatch, useSelector } from "react-redux";
import { UserDetailCard } from "../components/molecules";
import { structureData } from "../utils/FormatData";
import RateServicesBanner from "../components/molecules/RatingsCard/RateServicesBanner";
import {
  fetchUserRatedAllServices,
  fetchUserRatedGeneric,
  getLoggedInUserRole,
  getUserDeviceInfoById,
  getUserIssueInfoById,
  getUserAppInfoById,
} from "../Redux/Zte/ZteUsers/ZteUsersActions";
import AppCard from "../components/molecules/AppSection/AppCard";
import InformationModal from "../components/molecules/InformationModal";
import SectionToggle from "../components/molecules/SectionToggle/SectionToggle";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openTab, setOpenTab] = useState();
  const [selectedData, setSelectedData] = useState();
  const [activeTab, setActiveTab] = useState("User");
  const tabList = ["User", "Apps"];
  let userRole = JSON.parse(localStorage.getItem("tkn"))?.role;

  useEffect(() => {
    dispatch(getLoggedInUserRole(userRole));
    // eslint-disable-next-line
  }, []);

  const {
    userInfo,
    fetchingUserInfo,
    userDeviceInfo,
    userIssueInfo,
    userAppInfo,
  } = useSelector((state) => state.users);

  const userId = userInfo?.basicInformation?.userId;

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserRatedAllServices(userId));
      dispatch(fetchUserRatedGeneric(userId));
      dispatch(getUserDeviceInfoById(userId));
      dispatch(getUserIssueInfoById(userId));
      dispatch(getUserAppInfoById(userId));
    }
  }, [dispatch, userId]);

  const { userRatedGeneric, userRatedAllServices } = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    if (userRatedAllServices === false || userRatedGeneric === false) {
      setOpen(true);
    }
  }, [userId, userRatedAllServices, userRatedGeneric]);

  const userData = {
    basicInformation: userInfo?.basicInformation,
    deviceInformation: userDeviceInfo,
    userIssues: userIssueInfo,
  };
  return (
    <Layout>
      {open ? <RateServicesBanner setOpen={setOpen} /> : null}
      <HomeStyle>
        <SectionToggle
          tabList={tabList}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        >
          {activeTab === "User" ? (
            <>
              {!fetchingUserInfo ? (
                <UserDetailCard
                  userDetails={structureData("userDetail", userData)}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faSpinner}
                  size={"xl"}
                  color={"#62646d"}
                />
              )}
            </>
          ) : activeTab === "Apps" ? (
            <>
              <AppCard
                appDetails={userAppInfo?.ztnaInformation}
                setSelectedData={setSelectedData}
                setOpen={setOpenTab}
              />
              <InformationModal
                open={openTab}
                setOpen={setOpenTab}
                data={selectedData}
                userDetails={userData}
                type="app-info"
              />
            </>
          ) : null}
        </SectionToggle>
      </HomeStyle>
    </Layout>
  );
};

export default Home;
