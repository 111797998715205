export const GET_ZTNA_SERVICES_FAILURE = "GET_ZTNA_SERVICES_FAILURE";
export const GET_ZTNA_SERVICES_SUCCESS = "GET_ZTNA_SERVICES_SUCCESS";
export const GET_ZTNA_SERVICES_START = "GET_ZTNA_SERVICES_START";
export const GET_ZTNA_SERVICES_GROUP_START = "GET_ZTNA_SERVICES_GROUP_START";
export const GET_ZTNA_SERVICES_GROUP_FAILURE = "GET_ZTNA_SERVICES_GROUP_FAILURE";
export const GET_ZTNA_SERVICES_GROUP_SUCCESS = "GET_ZTNA_SERVICES_GROUP_SUCCESS";
export const ADD_ZTNA_SERVICE_FAILURE = "ADD_ZTNA_SERVICE_FAILURE";
export const ADD_ZTNA_SERVICE_SUCCESS = "ADD_ZTNA_SERVICE_SUCCESS";
export const ADD_ZTNA_SERVICE_START = "ADD_ZTNA_SERVICE_START";
export const ADD_ZTNA_SERVICE_IDENTIFIER_FAILURE = "ADD_ZTNA_SERVICE_IDENTIFIER_FAILURE";
export const ADD_ZTNA_SERVICE_IDENTIFIER_SUCCESS = "ADD_ZTNA_SERVICE_IDENTIFIER_SUCCESS";
export const ADD_ZTNA_SERVICE_IDENTIFIER_START = "ADD_ZTNA_SERVICE_IDENTIFIER_START";
export const ADD_ZTNA_SERVICES_GROUP_START = "ADD_ZTNA_SERVICES_GROUP_START";
export const ADD_ZTNA_SERVICES_GROUP_FAILURE = "ADD_ZTNA_SERVICES_GROUP_FAILURE";
export const ADD_ZTNA_SERVICES_GROUP_SUCCESS = "ADD_ZTNA_SERVICES_GROUP_SUCCESS";
export const UPLOAD_ZEN_FAILURE = "UPLOAD_ZEN_FAILURE";
export const UPLOAD_ZEN_SUCCESS = "UPLOAD_ZEN_SUCCESS";
export const UPLOAD_ZEN_START = "UPLOAD_ZEN_START";
export const UPLOAD_FAZ_FAILURE = "UPLOAD_FAZ_FAILURE";
export const UPLOAD_FAZ_SUCCESS = "UPLOAD_FAZ_SUCCESS";
export const UPLOAD_FAZ_START = "UPLOAD_FAZ_START";
export const GET_LOCATION_TYPES_FAILURE = "GET_LOCATION_TYPES_FAILURE";
export const GET_LOCATION_TYPES_SUCCESS = "GET_LOCATION_TYPES_SUCCESS";
export const GET_LOCATION_TYPES_START = "GET_LOCATION_TYPES_START";
export const ADD_IP_FAILURE = "ADD_IP_FAILURE";
export const ADD_IP_SUCCESS = "ADD_IP_SUCCESS";
export const ADD_IP_START = "ADD_IP_START";
export const GET_OWNED_IPS_FAILURE = "GET_OWNED_IPS_FAILURE";
export const GET_OWNED_IPS_SUCCESS = "GET_OWNED_IPS_SUCCESS";
export const GET_OWNED_IPS_START = "GET_OWNED_IPS_START";
export const UPDATE_OWNED_IPS_FAILURE = "UPDATE_OWNED_IPS_FAILURE";
export const UPDATE_OWNED_IPS_SUCCESS = "UPDATE_OWNED_IPS_SUCCESS";
export const UPDATE_OWNED_IPS_START = "UPDATE_OWNED_IPS_START";
export const GET_ZTNA_SERVICE_IDENTIFIERS_FAILURE = "GET_ZTNA_SERVICE_IDENTIFIERS_FAILURE";
export const GET_ZTNA_SERVICE_IDENTIFIERS_SUCCESS = "GET_ZTNA_SERVICE_IDENTIFIERS_SUCCESS";
export const GET_ZTNA_SERVICE_IDENTIFIERS_START = "GET_ZTNA_SERVICE_IDENTIFIERS_START";
export const ASSIGN_GROUP_TO_SERVICE_FAILURE = "ASSIGN_GROUP_TO_SERVICE_FAILURE";
export const ASSIGN_GROUP_TO_SERVICE_SUCCESS = "ASSIGN_GROUP_TO_SERVICE_SUCCESS";
export const ASSIGN_GROUP_TO_SERVICE_START = "ASSIGN_GROUP_TO_SERVICE_START";
export const GET_JOBS_RUNTIME_FAILURE = "GET_JOBS_RUNTIME_FAILURE";
export const GET_JOBS_RUNTIME_SUCCESS = "GET_JOBS_RUNTIME_SUCCESS";
export const GET_JOBS_RUNTIME_START = "GET_JOBS_RUNTIME_START";
export const GET_ACTIVE_SESSIONS_FAILURE = "GET_ACTIVE_SESSIONS_FAILURE";
export const GET_ACTIVE_SESSIONS_SUCCESS = "GET_ACTIVE_SESSIONS_SUCCESS";
export const GET_ACTIVE_SESSIONS_START = "GET_ACTIVE_SESSIONS_START";
export const DEAUTHENTICATE_SESSIONS_FAILURE = "DEAUTHENTICATE_SESSIONS_FAILURE";
export const DEAUTHENTICATE_SESSIONS_SUCCESS = "DEAUTHENTICATE_SESSIONS_SUCCESS";
export const DEAUTHENTICATE_SESSIONS_START = "DEAUTHENTICATE_SESSIONS_START";
export const GET_DEAUTH_REASONS_FAILURE = "GET_DEAUTH_REASONS_FAILURE";
export const GET_DEAUTH_REASONS_SUCCESS = "GET_DEAUTH_REASONS_SUCCESS";
export const GET_DEAUTH_REASONS_START = "GET_DEAUTH_REASONS_START";
export const GET_LINKED_AZUREAD_GROUPS_FAILURE = "GET_LINKED_AZUREAD_GROUPS_FAILURE";
export const GET_LINKED_AZUREAD_GROUPS_SUCCESS = "GET_LINKED_AZUREAD_GROUPS_SUCCESS";
export const GET_LINKED_AZUREAD_GROUPS_START = "GET_LINKED_AZUREAD_GROUPS_START";
export const SAVE_ACTIVE_USERS_START = "SAVE_ACTIVE_USERS_START";
export const SAVE_ACTIVE_USERS_SUCCESS = "SAVE_ACTIVE_USERS_SUCCESS";
export const SAVE_ACTIVE_USERS_FAILURE = "SAVE_ACTIVE_USERS_FAILURE";
export const SAVE_ACTIVE_USERS_BY_COUNTRY_START = "SAVE_ACTIVE_USERS_BY_COUNTRY_START";
export const SAVE_ACTIVE_USERS_BY_COUNTRY_SUCCESS = "SAVE_ACTIVE_USERS_SUCCESS_BY_COUNTRY_SUCCESS";
export const SAVE_ACTIVE_USERS_BY_COUNTRY_FAILURE = "SAVE_ACTIVE_USERS_FAILURE_BY_COUNTRY_FAILURE";
export const SAVE_UPDATE_CONNECTIONS_START = "SAVE_UPDATE_CONNECTIONS_START";
export const SAVE_UPDATE_CONNECTIONS_SUCCESS = "SAVE_UPDATE_CONNECTIONS_SUCCESS";
export const SAVE_UPDATE_CONNECTIONS_FAILURE = "SAVE_UPDATE_CONNECTIONS_FAILURE";
export const SAVE_UPDATE_CONNECTIONS_BY_DATE_START = "SAVE_UPDATE_CONNECTIONS_BY_DATE_START";
export const SAVE_UPDATE_CONNECTIONS_BY_DATE_SUCCESS = "SAVE_UPDATE_CONNECTIONS_BY_DATE_SUCCESS";
export const SAVE_UPDATE_CONNECTIONS_BY_DATE_FAILURE = "SAVE_UPDATE_CONNECTIONS_BY_DATE_FAILURE";
