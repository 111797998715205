import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminCardStyles } from "./AdminCard.style";
import { Button } from "../../atoms";
import SearchDropDown from "./SearchDropdown";
import {
  addIssueTypePost,
  addHoldReasonPost,
  getIssueType,
  addNewIssueDetail,
} from "../../../Redux/Zte/Issue/IssueAction";
import { toast } from "react-toastify";

const IssueManagementCard = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [issueType, setIssueType] = useState("");
  const [issueTypeName, setIssueTypeName] = useState("");
  const [reason, setReason] = useState("");

  const typePayload = {
    name: type,
  };
  const reasonPayload = {
    reason: reason,
  };
  const addIssuePayload = {
    title: issueTypeName,
    issueTypeId: issueType,
  };

  const fieldsCompleted =
  addIssuePayload.title && addIssuePayload.issueTypeId; 
  
  const handleAddType = () => {
    dispatch(addIssueTypePost(typePayload));
  };
  const handleAddReason = () => {
    dispatch(addHoldReasonPost(reasonPayload));
  };
  const handleAddDetail = () => {
    fieldsCompleted ?
      dispatch(addNewIssueDetail(addIssuePayload))
      : toast.error("All fields are required!");
  };

  useEffect(() => {
    dispatch(getIssueType());
  }, [dispatch]);

  const allIssueType = useSelector((state) => state.issues.issueType);
  const loading = useSelector((state) => state.issues.fetchingIssueType)
  return (
    <AdminCardStyles>
      <p>Type</p>
      <div className="group-container">
        <input
          type="text"
          placeholder="Name"
          className="input-text"
          onChange={(e) => setType(e.target.value)}
        />
        <Button fill="fill" onclick={handleAddType}>
          Add
        </Button>
      </div>
      <div className="ztna-container">
        <div>
          <p>Detail</p>
          <div className="group-container">
            <SearchDropDown
              title={"Issue Type"}
              type="select"
              data={allIssueType}
              group={"services"}
              setData={setIssueType}
              loading={loading}
            />
            <input
              className="input-text input-button"
              type="text"
              placeholder="Name"
              onChange={(e) => setIssueTypeName(e.target.value)}
            />
          </div>
        </div>
        <div className="button-style-ip input-button"  >
          <Button fill="fill" onclick={handleAddDetail}>
            Add
          </Button>
        </div>
      </div>
      <p>On Hold Reason</p>
      <div className="group-container">
        <input
          className="input-text"
          type="text"
          placeholder="Name"
          onChange={(e) => setReason(e.target.value)}
        />
        <Button fill="fill" onclick={handleAddReason}>
          Add
        </Button>
      </div>
    </AdminCardStyles>
  );
};

export default IssueManagementCard;
