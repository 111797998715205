import { LinkCardStyle } from "./LinkCard.Style";
import { NavLink } from "react-router-dom";

const LinkCard = ({ detailsList }) => {
  return (
    <LinkCardStyle>
      {detailsList.map((value, index) => (
        <div className="list-item" key={index}>
          <NavLink className="link-style" to={value.path}>
            {value.name}
          </NavLink>
        </div>
      ))}
    </LinkCardStyle>
  );
};

export default LinkCard;
