import axios from "axios";

const { REACT_APP_BASE_URL, REACT_APP_API_AUTH_REFRESH } = process.env;

const API = axios.create({
	baseURL: REACT_APP_BASE_URL,
	//baseURL: "https://dev.api.infosec.armacell.com/api",
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
	withCredentials: true,
});
const refreshCookies = async () => {
	try {
		// Make a request to your backend server to refresh cookies
		const response = await axios.get(REACT_APP_API_AUTH_REFRESH, {
			withCredentials: true,
		});
		return response;
	} catch (error) {
		throw new Error("Failed to refresh cookies");
	}
};

// Create an array to hold the request queue
const refreshAndRetryQueue = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

API.interceptors.request.use((config) => {
	return config;
});

const checkValidity = () => {
	const lastLoggedIn = JSON.parse(localStorage.getItem("tkn"))?.lastLoggedIn;
	const dayInMilliSeconds = 86400000;
	const maxSessionDurationInDays = 7;
	const sessionDurationInMilliseconds = new Date().getTime() - new Date(lastLoggedIn).getTime();
	const sessionDurationInDays = sessionDurationInMilliseconds/dayInMilliSeconds;
	const sessionDurationInDaysAbsolute = Math.abs(sessionDurationInDays);
	const status = sessionDurationInDaysAbsolute < maxSessionDurationInDays;
	return status;
};
API.interceptors.response.use(
	(res) => {
		return res;
	},
	async (error) => {
		const originalRequest = error.config;
		// If request failed, check if it's due to expired cookies
		if (error.response && error.response.status === 401) {
			if (!isRefreshing) {
				// start the refreshing process
				isRefreshing = true;
				// check lastLoggedIn
				if (checkValidity()) {
					try {
						const response = await refreshCookies();

						// Retry all requests in the queue with the new token
						refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
							API.request(config)
								.then((response) => resolve(response))
								.catch((err) => reject(err));
						});

						// Clear the queue
						refreshAndRetryQueue.length = 0;

						// Retry the original request
						return API(originalRequest);
						// Retry the original request with updated cookies
					} catch (refreshError) {
						// Handle refresh error
						console.error("Error refreshing cookies:", refreshError);
						// clear all storage
						localStorage.clear();
						//  redirect the user to the login page
						window.location.reload();
						throw refreshError;
					} finally {
						isRefreshing = false;
					}
				} else {
					localStorage.clear();
					//  redirect the user to the login page
					window.location.reload();
				}
			}
			 // Add the original request to the queue
      return new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
		}
		// If error is not due to expired cookies, rethrow it
		return Promise.reject(error);
	}
);

export default API;