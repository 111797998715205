import { AdminCardStyles } from "./AdminCard.style";
import { Button } from "../../atoms";

const ThreatManagementCard = ({open, setOpen}) => {
  const handleOpen = () => {
   setOpen(!open)
 }
  return (
    <AdminCardStyles>
      <Button fill="fill" onclick={handleOpen}>Update Threat Data</Button>
    </AdminCardStyles>
  );
};

export default ThreatManagementCard;
