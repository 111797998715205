import {
  GET_ZTNA_SERVICES_FAILURE,
  GET_ZTNA_SERVICES_SUCCESS,
  GET_ZTNA_SERVICES_START,
  GET_ZTNA_SERVICES_GROUP_START,
  GET_ZTNA_SERVICES_GROUP_FAILURE,
  GET_ZTNA_SERVICES_GROUP_SUCCESS,
  GET_LOCATION_TYPES_FAILURE,
  GET_LOCATION_TYPES_SUCCESS,
  GET_LOCATION_TYPES_START,
  GET_OWNED_IPS_FAILURE,
  GET_OWNED_IPS_SUCCESS,
  GET_OWNED_IPS_START,
  GET_ZTNA_SERVICE_IDENTIFIERS_FAILURE,
  GET_ZTNA_SERVICE_IDENTIFIERS_SUCCESS,
  GET_ZTNA_SERVICE_IDENTIFIERS_START,
  GET_JOBS_RUNTIME_FAILURE,
  GET_JOBS_RUNTIME_SUCCESS,
  GET_JOBS_RUNTIME_START,
  GET_ACTIVE_SESSIONS_FAILURE,
  GET_ACTIVE_SESSIONS_SUCCESS,
  GET_ACTIVE_SESSIONS_START,
  GET_DEAUTH_REASONS_FAILURE,
  GET_DEAUTH_REASONS_SUCCESS,
  GET_DEAUTH_REASONS_START,
  GET_LINKED_AZUREAD_GROUPS_FAILURE,
  GET_LINKED_AZUREAD_GROUPS_SUCCESS,
  GET_LINKED_AZUREAD_GROUPS_START,
} from "./ZtnaActionTypes";

const initialState = {
  fetchingZtnaServices: false,
  fetchingZtnaServicesError: null,
  ztnaServices: [],
  fetchingZtnaServicesGroup: false,
  fetchingZtnaServicesGroupError: null,
  ztnaServicesGroup: [],
  allLocations: [],
  fetchingAllLocations: false,
  fetchingAllLocationsError: null,
  allOwnedIps: [],
  fetchingAllOwnedIps: false,
  fetchingAllOwnedIpsError: null,
  fetchingZtnaServicesIdentifiers: false,
  fetchingZtnaServicesIdentifiersError: null,
  ztnaServicesIdentifiers: [],
  jobslastRuntime: [],
  fetchingJobslastRuntime: false,
  fetchingJobslastRuntimeError: null,
  activeSessions: [],
  fetchingActiveSessions: false,
  fetchingActiveSessionsError: null,
  deauthReasons: [],
  fetchingDeauthReasons: false,
  fetchingDeauthReasonsError: null,
  linkedAzureAdGroups: [],
  fetchingLinkedAzureAdGroups: false,
  fetchingLinkedAzureAdGroupsError: null
};

const ztnaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ZTNA_SERVICES_START:
      return {
        ...state,
        fetchingZtnaServices: true,
        fetchingZtnaServicesError: null,
      };
    case GET_ZTNA_SERVICES_SUCCESS:
      return {
        ...state,
        fetchingZtnaServices: false,
        fetchingZtnaServicesError: null,
        ztnaServices: payload.data,
      };
    case GET_ZTNA_SERVICES_FAILURE:
      return {
        ...state,
        fetchingZtnaServices: false,
        fetchingZtnaServicesError: payload,
        ztnaServices: [],
      };
    case GET_ZTNA_SERVICE_IDENTIFIERS_START:
      return {
        ...state,
        fetchingZtnaServicesIdentifiers: true,
        fetchingZtnaServicesIdentifiersError: null,
        ztnaServicesIdentifiers: [],
      };
    case GET_ZTNA_SERVICE_IDENTIFIERS_SUCCESS:
      return {
        ...state,
        fetchingZtnaServicesIdentifiers: false,
        fetchingZtnaServicesIdentifiersError: null,
        ztnaServicesIdentifiers: payload.data,
      };
    case GET_ZTNA_SERVICE_IDENTIFIERS_FAILURE:
      return {
        ...state,
        fetchingZtnaServicesIdentifiers: false,
        fetchingZtnaServicesIdentifiersError: payload,
        ztnaServicesIdentifiers: [],
      };
    case GET_ZTNA_SERVICES_GROUP_START:
      return {
        ...state,
        fetchingZtnaServicesGroup: true,
        fetchingZtnaServicesGroupError: null,
      };
    case GET_ZTNA_SERVICES_GROUP_SUCCESS:
      return {
        ...state,
        fetchingZtnaServicesGroup: false,
        fetchingZtnaServicesGroupError: null,
        ztnaServicesGroup: payload.data,
      };
    case GET_ZTNA_SERVICES_GROUP_FAILURE:
      return {
        ...state,
        fetchingZtnaServicesGroup: false,
        fetchingZtnaServicesGroupError: payload,
        ztnaServicesGroup: [],
      };
    case GET_LOCATION_TYPES_START:
      return {
        ...state,
        fetchingAllLocations: true,
        fetchingUsersCountriesError: null,
        allLocations: [],
      };
    case GET_LOCATION_TYPES_FAILURE:
      return {
        ...state,
        fetchingAllLocations: false,
        fetchingAllLocationsError: payload,
        allLocations: [],
      };
    case GET_LOCATION_TYPES_SUCCESS:
      return {
        ...state,
        fetchingAllLocations: false,
        fetchingAllLocationsError: null,
        allLocations: payload.data,
      };
    case GET_OWNED_IPS_START:
      return {
        ...state,
        fetchingAllOwnedIps: true,
        fetchingAllOwnedIpsError: null,
        allOwnedIps: [],
      };
    case GET_OWNED_IPS_FAILURE:
      return {
        ...state,
        fetchingAllOwnedIps: false,
        fetchingAllOwnedIpsError: payload,
        allOwnedIps: [],
      };
    case GET_OWNED_IPS_SUCCESS:
      return {
        ...state,
        fetchingAllOwnedIps: false,
        fetchingAllOwnedIpsError: null,
        allOwnedIps: payload.data,
      };
    case GET_JOBS_RUNTIME_START:
      return {
        ...state,
        fetchingJobslastRuntime: true,
        fetchingJobslastRuntimeError: null,
        jobslastRuntime: [],
      };
    case GET_JOBS_RUNTIME_FAILURE:
      return {
        ...state,
        fetchingJobslastRuntime: false,
        fetchingJobslastRuntimeError: payload,
        jobslastRuntime: [],
      };
    case GET_JOBS_RUNTIME_SUCCESS:
      return {
        ...state,
        fetchingJobslastRuntime: false,
        fetchingJobslastRuntimeError: null,
        jobslastRuntime: payload.data,
      };
    case GET_ACTIVE_SESSIONS_START:
      return {
        ...state,
        fetchingActiveSessions: true,
        fetchingActiveSessionsError: null,
        activeSessions: [],
      };
    case GET_ACTIVE_SESSIONS_FAILURE:
      return {
        ...state,
        fetchingActiveSessions: false,
        fetchingActiveSessionsError: payload,
        activeSessions: [],
      };
    case GET_ACTIVE_SESSIONS_SUCCESS:
      return {
        ...state,
        fetchingActiveSessions: false,
        fetchingActiveSessionsError: null,
        activeSessions: payload.data,
      };
    case GET_DEAUTH_REASONS_START:
      return {
        ...state,
        fetchingDeauthReasons: true,
        fetchingDeauthReasonsError: null,
        deauthReasons: [],
      };
    case GET_DEAUTH_REASONS_FAILURE:
      return {
        ...state,
        fetchingDeauthReasons: false,
        fetchingDeauthReasonsError: payload,
        deauthReasons: [],
      };
    case GET_DEAUTH_REASONS_SUCCESS:
      return {
        ...state,
        fetchingDeauthReasons: false,
        fetchingDeauthReasonsError: null,
        deauthReasons: payload.data,
      };
    
      case GET_LINKED_AZUREAD_GROUPS_START:
        return {
          ...state,
          fetchingLinkedAzureAdGroups: true,
          fetchingLinkedAzureAdGroupsError: null,
          linkedAzureAdGroups: [],
        };
      case GET_LINKED_AZUREAD_GROUPS_FAILURE:
        return {
          ...state,
          fetchingLinkedAzureAdGroups: false,
          fetchingLinkedAzureAdGroupsError: payload,
          linkedAzureAdGroups: [],
        };
      case GET_LINKED_AZUREAD_GROUPS_SUCCESS:
        return {
          ...state,
          fetchingLinkedAzureAdGroups: false,
          fetchingLinkedAzureAdGroupsError: null,
          linkedAzureAdGroups: payload.data,
        };
    default:
      return state;
  }
};

export default ztnaReducer;
