import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseBtn } from "../../../assets/icons/close.svg";
import { Button } from "../../atoms";
import { RatingsBannerStyle } from "./RatingsCard.style";

const RateServicesBanner = ({ setOpen }) => {
  const navigate = useNavigate();
  const handleClick = () => navigate("/rate-services");
    const handleClose = () => {
    setOpen(false);
  };

  return (
    <RatingsBannerStyle>
      <div className="top">
        <div className="close">
          <CloseBtn onClick={handleClose} />
        </div>
      </div>
      <div className="content">
        <h2>Please take out 2 minutes and rate these services</h2>
        <div className="action-button">
          <Button onclick={handleClick}>Rate Services</Button>
        </div>
      </div>
    </RatingsBannerStyle>
  );
};

export default RateServicesBanner;
