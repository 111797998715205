import {
  SEARCH_ZTE_USERS_START,
  SEARCH_ZTE_USERS_FAILURE,
  SEARCH_ZTE_USERS_SUCCESS,
  GET_USERS_DETAILS_FAILURE,
  GET_USERS_DETAILS_START,
  GET_USERS_DETAILS_SUCCESS,
  GET_LOGGED_IN_USER_ROLE_SUCCESS,
  GET_LOGGED_IN_USER_SUCCESS,
  GET_LOGGED_IN_USER_FAILURE,
  GET_LOGGED_IN_USER_START,
  GET_USERS_BASIC_FAILURE,
  GET_USERS_BASIC_SUCCESS,
  GET_USERS_BASIC_START,
  GET_AZURE_GROUP_FAILURE,
  GET_AZURE_GROUP_SUCCESS,
  GET_AZURE_GROUP_START,
  GET_AZURE_GROUP_BASIC_FAILURE,
  GET_AZURE_GROUP_BASIC_SUCCESS,
  GET_AZURE_GROUP_BASIC_START,
  GET_USERS_REGIONS_FAILURE,
  GET_USERS_REGIONS_SUCCESS,
  GET_USERS_REGIONS_START,
  GET_USERS_COUNTRIES_FAILURE,
  GET_USERS_COUNTRIES_SUCCESS,
  GET_USERS_COUNTRIES_START,
  GET_USER_DEVICES_FAILURE,
  GET_USER_DEVICES_SUCCESS,
  GET_USER_DEVICES_START,
  GET_RATE_SERVICES_START,
  GET_RATE_SERVICES_FAILURE,
  GET_RATE_SERVICES_SUCCESS,
  GET_USER_RATED_ALL_SERVICES_START,
  GET_USER_RATED_ALL_SERVICES_FAILURE,
  GET_USER_RATED_ALL_SERVICES_SUCCESS,
  GET_USER_RATED_GENERIC_START,
  GET_USER_RATED_GENERIC_FAILURE,
  GET_USER_RATED_GENERIC_SUCCESS,
  GET_USER_ACTIVE_CONNECTIONS_START,
  GET_USER_ACTIVE_CONNECTIONS_FAILURE,
  GET_USER_ACTIVE_CONNECTIONS_SUCCESS,
  GET_USERS_DEVICE_INFO_FAILURE,
  GET_USERS_DEVICE_INFO_SUCCESS,
  GET_USERS_DEVICE_INFO_START,
  GET_USERS_ISSUE_INFO_FAILURE,
  GET_USERS_ISSUE_INFO_SUCCESS,
  GET_USERS_ISSUE_INFO_START,
  GET_USERS_APP_INFO_FAILURE,
  GET_USERS_APP_INFO_SUCCESS,
  GET_USERS_APP_INFO_START,
  GET_USERS_IP_INFO_FAILURE,
  GET_USERS_IP_INFO_SUCCESS,
  GET_USERS_IP_INFO_START,
  GET_USERS_LOCATION_INFO_FAILURE,
  GET_USERS_LOCATION_INFO_SUCCESS,
  GET_USERS_LOCATION_INFO_START
} from "./ZteUsersActionTypes";

const initialState = {
  users: [],
  searchingForUsers: false,
  searchingForUsersError: null,
  fetchingUserDetail: false,
  fetchingUserDetailError: null,
  userDetail: {},
  userRole: [],
  userInfo: {},
  fetchingUserInfo: false,
  fetchingUserInfoError: null,
  fetchingAzureGroups: false,
  fetchingAzureGroupsError: null,
  fetchingAllAzureAdGroups: false,
  fetchingAllAzureAdError: null,
  fetchingUsersBasics: false,
  fetchingUsersBasicsError: null,
  azureGroupsBasic: {},
  allAzureAdGroups: [],
  usersBasic: {},
  allRegions: [],
  fetchingUsersRegions: false,
  fetchingUsersRegionsError: null,
  allCountries: [],
  fetchingUsersCountries: false,
  fetchingUsersCountriesError: null,
  userDevices: [],
  fetchingUserDevices: false,
  fetchingUserDevicesError: null,
  rateServices: [],
  fetchingRateServices: false,
  fetchingRateServicesError: null,
  userRatedAllServices: null,
  fetchingUserRatedAllServices: false,
  fetchingUserRatedAllServicesError: null,
  userRatedGeneric: null,
  fetchingUserRatedGeneric: false,
  fetchingUserRatedGenericError: null,
  userActiveConnections: [],
  fetchingUserActiveConnections: false,
  fetchingUserActiveConnectionsError: null,
  userDeviceInfo: [],
  fetchingUserDeviceInfo: false,
  fetchingUserDeviceInfoError: null,
  userIssueInfo: [],
  fetchingUserIssueInfo: false,
  fetchingUserIssueInfoError: null,
  userAppInfo: [],
  fetchingUserAppInfo: false,
  fetchingUserAppInfoError: null,
  userIpInfo: [],
  fetchingUserIpInfo: false,
  fetchingUserIpInfoError: null,
  userLocationInfo: [],
  fetchingUserLocationInfo: false,
  fetchingUserLocationInfoError: null,
};

const usersReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_ZTE_USERS_START:
      return {
        ...state,
        searchingForUsers: true,
        searchingForUsersError: null,
      };
    case SEARCH_ZTE_USERS_SUCCESS:
      return {
        ...state,
        searchingForUsers: false,
        searchingForUsersError: null,
        users: payload,
      };
    case SEARCH_ZTE_USERS_FAILURE:
      return {
        ...state,
        searchingForUsers: false,
        searchingForUsersError: payload,
        users: [],
      };
    case GET_USERS_DETAILS_START:
      return {
        ...state,
        fetchingUserDetail: true,
        fetchingUserDetailError: null,
        userDetail: {},
      };
    case GET_USERS_DETAILS_FAILURE:
      return {
        ...state,
        fetchingUserDetail: false,
        fetchingUserDetailError: payload,
        userDetail: {},
      };
    case GET_USERS_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingUserDetail: false,
        fetchingUserDetailError: null,
        userDetail: payload?.data,
      };
    case GET_LOGGED_IN_USER_ROLE_SUCCESS:
      return {
        ...state,
        userRole: payload,
      };

    case GET_AZURE_GROUP_BASIC_START:
      return {
        ...state,
        fetchingAzureGroups: true,
        fetchingAzureGroupsError: null,
        azureGroupsBasic: {},
      };
    case GET_AZURE_GROUP_BASIC_FAILURE:
      return {
        ...state,
        fetchingAzureGroups: false,
        fetchingAzureGroupsError: payload,
        azureGroupsBasic: {},
      };
    case GET_AZURE_GROUP_BASIC_SUCCESS:
      return {
        ...state,
        fetchingAzureGroups: false,
        fetchingAzureGroupsError: null,
        azureGroupsBasic: payload.data,
      };
    case GET_AZURE_GROUP_START:
      return {
        ...state,
        fetchingAllAzureAdGroups: true,
        fetchingAllAzureAdError: null,
        allAzureAdGroups: [],
      };
    case GET_AZURE_GROUP_FAILURE:
      return {
        ...state,
        fetchingAllAzureAdGroups: false,
        fetchingAllAzureAdError: payload,
        allAzureAdGroups: [],
      };
    case GET_AZURE_GROUP_SUCCESS:
      return {
        ...state,
        fetchingAllAzureAdGroups: false,
        fetchingAllAzureAdError: null,
        allAzureAdGroups: payload,
      };
    case GET_USERS_BASIC_START:
      return {
        ...state,
        fetchingUsersBasics: true,
        fetchingUsersBasicsError: null,
        usersBasic: {},
      };
    case GET_USERS_BASIC_FAILURE:
      return {
        ...state,
        fetchingUsersBasics: false,
        fetchingUsersBasicsError: payload,
        usersBasic: {},
      };
    case GET_USERS_BASIC_SUCCESS:
      return {
        ...state,
        fetchingUsersBasics: false,
        fetchingUsersBasicsError: null,
        usersBasic: payload.data,
      };
    case GET_USERS_REGIONS_START:
      return {
        ...state,
        fetchingUsersRegions: true,
        fetchingUsersRegionsError: null,
        allRegions: [],
      };
    case GET_USERS_REGIONS_FAILURE:
      return {
        ...state,
        fetchingUsersRegions: false,
        fetchingUsersRegionsError: payload,
        allRegions: [],
      };
    case GET_USERS_REGIONS_SUCCESS:
      return {
        ...state,
        fetchingUsersRegions: false,
        fetchingUsersRegionsError: null,
        allRegions: payload.data,
      };
    case GET_USERS_COUNTRIES_START:
      return {
        ...state,
        fetchingUsersCountries: true,
        fetchingUsersCountriesError: null,
        allCountries: [],
      };
    case GET_USERS_COUNTRIES_FAILURE:
      return {
        ...state,
        fetchingUsersCountries: false,
        fetchingUsersCountriesError: payload,
        allCountries: [],
      };
    case GET_USERS_COUNTRIES_SUCCESS:
      return {
        ...state,
        fetchingUsersCountries: false,
        fetchingUsersCountriesError: null,
        allCountries: payload.data,
      };
    case GET_USER_DEVICES_START:
      return {
        ...state,
        fetchingUserDevices: true,
        fetchingUserDetailError: null,
        userDevices: [],
      };
    case GET_USER_DEVICES_FAILURE:
      return {
        ...state,
        fetchingUserDevices: false,
        fetchingUserDevicesError: payload,
        userDevices: [],
      };
    case GET_USER_DEVICES_SUCCESS:
      return {
        ...state,
        fetchingUserDevices: false,
        fetchingUserDevicesError: null,
        userDevices: payload.data,
      };
    case GET_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        fetchingUserInfo: false,
        fetchingUserInfoError: null,
        userInfo: payload.data,
      };
    case GET_LOGGED_IN_USER_FAILURE:
      return {
        ...state,
        fetchingUserInfo: false,
        fetchingUserInfoError: payload,
        userInfo: {},
      };
    case GET_LOGGED_IN_USER_START:
      return {
        ...state,
        fetchingUserInfo: true,
        fetchingUserInfoError: null,
        userInfo: {},
      };
    case GET_RATE_SERVICES_START:
      return {
        ...state,
        fetchingRateServices: true,
        fetchingRateServicesError: null,
        rateServices: [],
      };
    case GET_RATE_SERVICES_FAILURE:
      return {
        ...state,
        fetchingRateServices: false,
        fetchingRateServicesError: payload,
        rateServices: [],
      };
    case GET_RATE_SERVICES_SUCCESS:
      return {
        ...state,
        fetchingRateServices: false,
        fetchingRateServicesError: null,
        rateServices: payload.data,
      };
    case GET_USER_RATED_ALL_SERVICES_START:
      return {
        ...state,
        fetchingUserRatedAllServices: true,
        fetchingUserRatedAllServicesError: null,
        userRatedAllServices: [],
      };
    case GET_USER_RATED_ALL_SERVICES_FAILURE:
      return {
        ...state,
        fetchingUserRatedAllServices: false,
        fetchingUserRatedAllServicesError: payload,
        userRatedAllServices: null,
      };
    case GET_USER_RATED_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        fetchingUserRatedAllServices: false,
        fetchingUserRatedAllServicesError: null,
        userRatedAllServices: payload?.data.allRated,
      };
    case GET_USER_RATED_GENERIC_START:
      return {
        ...state,
        fetchingUserRatedGeneric: true,
        fetchingUserRatedGenericError: null,
        userRatedGeneric: null,
      };
    case GET_USER_RATED_GENERIC_FAILURE:
      return {
        ...state,
        fetchingUserRatedGeneric: false,
        fetchingUserRatedGenericError: payload,
        userRatedGeneric: null,
      };
    case GET_USER_RATED_GENERIC_SUCCESS:
      return {
        ...state,
        fetchingUserRatedGeneric: false,
        fetchingUserRatedGenericError: null,
        userRatedGeneric: payload?.data?.rated,
      };
    case GET_USER_ACTIVE_CONNECTIONS_START:
      return {
        ...state,
        fetchingUserActiveConnections: true,
        fetchingUserActiveConnectionsError: null,
        userActiveConnections: null,
      };
    case GET_USER_ACTIVE_CONNECTIONS_FAILURE:
      return {
        ...state,
        fetchingUserActiveConnections: false,
        fetchingUserActiveConnectionsError: payload,
        userActiveConnections: null,
      };
    case GET_USER_ACTIVE_CONNECTIONS_SUCCESS:
      return {
        ...state,
        fetchingUserActiveConnections: false,
        fetchingUserActiveConnectionsError: null,
        userActiveConnections: payload.data,
      };
    case GET_USERS_DEVICE_INFO_START:
      return {
        ...state,
        fetchingUserDeviceInfo: true,
        fetchingUserDeviceInfoError: null,
        userDeviceInfo: null,
      };
    case GET_USERS_DEVICE_INFO_FAILURE:
      return {
        ...state,
        fetchingUserDeviceInfo: false,
        fetchingUserDeviceInfoError: payload,
        userDeviceInfo: null,
      };
    case GET_USERS_DEVICE_INFO_SUCCESS:
      return {
        ...state,
        fetchingUserDeviceInfo: false,
        fetchingUserDeviceInfoError: null,
        userDeviceInfo: payload.data,
      };
    case GET_USERS_ISSUE_INFO_START:
      return {
        ...state,
        fetchingUserIssueInfo: true,
        fetchingUserIssueInfoError: null,
        userIssueInfo: null,
      };
    case GET_USERS_ISSUE_INFO_FAILURE:
      return {
        ...state,
        fetchingUserIssueInfo: false,
        fetchingUserIssueInfoError: payload,
        userIssueInfo: null,
      };
    case GET_USERS_ISSUE_INFO_SUCCESS:
      return {
        ...state,
        fetchingUserIssueInfo: false,
        fetchingUserIssueInfoError: null,
        userIssueInfo: payload.data,
      };
    case GET_USERS_APP_INFO_START:
      return {
        ...state,
        fetchingUserAppInfo: true,
        fetchingUserAppInfoError: null,
        userAppInfo: null,
      };
    case GET_USERS_APP_INFO_FAILURE:
      return {
        ...state,
        fetchingUserAppInfo: false,
        fetchingUserAppInfoError: payload,
        userAppInfo: null,
      };
    case GET_USERS_APP_INFO_SUCCESS:
      return {
        ...state,
        fetchingUserAppInfo: false,
        fetchingUserAppInfoError: null,
        userAppInfo: payload.data,
      };
    case GET_USERS_IP_INFO_START:
      return {
        ...state,
        fetchingUserIpInfo: true,
        fetchingUserIpInfoError: null,
        userIpInfo: null,
      };
    case GET_USERS_IP_INFO_FAILURE:
      return {
        ...state,
        fetchingUserIpInfo: false,
        fetchingUserIpInfoError: payload,
        userIpInfo: null,
      };
    case GET_USERS_IP_INFO_SUCCESS:
      return {
        ...state,
        fetchingUserIpInfo: false,
        fetchingUserIpInfoError: null,
        userIpInfo: payload.data,
      };
    case GET_USERS_LOCATION_INFO_START:
      return {
        ...state,
        fetchingUserLocationInfo: true,
        fetchingUserLocationInfoError: null,
        userLocationInfo: null,
      };
    case GET_USERS_LOCATION_INFO_FAILURE:
      return {
        ...state,
        fetchingUserLocationInfo: false,
        fetchingUserLocationInfoError: payload,
        userLocationInfo: null,
      };
    case GET_USERS_LOCATION_INFO_SUCCESS:
      return {
        ...state,
        fetchingUserLocationInfo: false,
        fetchingUserLocationInfoError: null,
        userLocationInfo: payload.data,
      };
    default:
      return state;
  }
};

export default usersReducers;
