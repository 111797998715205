export const formatDurationLineChartDates = (data) => {
  const issueDates = [];
  for (let i in data) {
    issueDates.push(data[i].timeRangeIdentifier);
  }
  return issueDates;
};

export const formatDurationLineChartData = (data) => {
  const issueTypes = [];
  const series = [];
  if (data.length) {
    for (let i in data[0].data) {
      issueTypes.push(data[0].data[i].issueType);
    }
    for (let i in issueTypes) {
      let count = [];
      let name = issueTypes[i];
      for (let j in data) {
        for (let k in data[j].data) {
          if (data[j].data[k].issueType === issueTypes[i]) {
            count.push(data[j].data[k].count);
          }
        }
      }
      series.push({
        name: name,
        data: count,
      });
    }
  }
  return series;
};

export const formatDetailDurationLineChartDates = (data) => {
  const issueDates = [];
  for (let i in data) {
    issueDates.push(i);
  }
  return issueDates;
};

export const formatDetailDurationLineChartData = (data) => {
  const issueTypesId = [];
  for (let i in data) {
    for (let j in data[i].data) {
      issueTypesId.push({
        name: data[i].data[j].issueDetail,
        data: [data[i].data[j].count],
      });
    }
  }
  const mergeAndCount = issueTypesId.reduce((a, c) => {
    const obj = a.find((obj) => obj.name === c.name);
    if (!obj) {
      a.push(c);
    } else {
      obj.data = [...obj.data, c.data[0]];
    }
    return a;
  }, []);
  return mergeAndCount;
};

export const formatAverageChartDates = (data) => {
  const ratingDates = [];
  for (let i in data) {
    for (let j in data[i].monthlyAverageRatings) {
      if (!ratingDates.includes(data[i].monthlyAverageRatings[j].month)) {
        ratingDates.push(data[i].monthlyAverageRatings[j].month);
      }
    }
  }
  return ratingDates;
};

export const formatAverageChartCountryDates = (data) => {
  const ratingDates = [];
  for (let i in data) {
    for (let j in data[i].monthlyAverageRatings) {
      if (!ratingDates.includes(data[i].monthlyAverageRatings[j].month)) {
        ratingDates.push(data[i].monthlyAverageRatings[j].month);
      }
    }
  }
  return ratingDates;
};

export const formatAverageChartData = (dataValue, country) => {
  const series = [];
  const result = {};

  for (let i in dataValue) {
    let name = country ? dataValue[i].coutryName : dataValue[i].serviceName;
    let data = [];
    for (let j in dataValue[i].monthlyAverageRatings) {
      let raters = {};
      raters.y = country
        ? dataValue[i].monthlyAverageRatings[j].averageOverallRating
        : dataValue[i].monthlyAverageRatings[j]
            .averageUserServiceExpericenceRating;
      raters.z = dataValue[i].monthlyAverageRatings[j].numberOfRaters;
      raters.a = dataValue[i].monthlyAverageRatings[j].numberOfActiveUsers;
      raters.b = dataValue[i].monthlyAverageRatings[j].percentage;
      data.push(raters);
    }
    series.push({
      name: name,
      data: data,
    });
    result.series = series;
  }
  return result;
};

export const formatAverageChartDateRegion = (dataValue) => {
  const ratingDates = [];
  for (let i in dataValue) {
    for (let j in Object.keys(dataValue[i])) {
      if (!ratingDates.includes(Object.keys(dataValue[i])[j])) {
        ratingDates.push(Object.keys(dataValue[i])[j]);
      }
    }
  }
  return ratingDates;
};
export const formatAverageChartDataRegion = (dataValue) => {
  const series = [];
  const result = {};
  for (let i in dataValue) {
    let name = i;
    let data = [];
    let values = Object.values(dataValue[i]);
    for (let j in values) {
      let raters = {};
      raters.y = values[j].averageOverallRating;
      raters.z = values[j].numberOfRaters;
      raters.a = values[j].numberOfActiveUsers;
      raters.b = values[j].percentageOfActiveUsers;
      data.push(raters);
    }
    series.push({
      name: name,
      data: data,
    });
    result.series = series;
  }
  return result;
};

export const formatUserExpChartDateRegion = (dataValue) => {
  const ratingDates = [];
  for (let i in dataValue) {
    for (let j in dataValue[i]) {
      for (let k in dataValue[i][j]) {
        if (!ratingDates.includes(dataValue[i][j][k].month)) {
          ratingDates.push(dataValue[i][j][k].month);
        }
      }
    }
  }
  return ratingDates;
};

export const getDataByRegion = (dataValue, region) => {
  const data = dataValue[region];
  return data;
};

export const getRegions = (dataValue) => {
  const regions = Object.keys(dataValue);
  return regions;
};

export const formatUserExpChartDataRegion = (dataValue) => {
  const series = [];
  const result = {};
  const services = Object.keys(dataValue);
  const data = Object.values(dataValue);
  let ratings = [];

  for (let k in data) {
    for (let l in data[k]) {
      let raters = {};
      raters.y = data[k][l].userServiceExpericenceRating;
      raters.z = data[k][l].numberOfRaters;
      raters.a = data[k][l].numberOfActiveUsers;
      raters.b = data[k][l].percentageOfActiveUsers;
      ratings.push(raters);
    }
  }
  for (let a in services) {
    series.push({
      name: services[a],
      data: [ratings[a]],
    });
  }

  result.series = series;
  return result;
};

export const formatUserIssueData = (userIssue) => {
  const numberofUsersWithoutIssue =
    userIssue.numberOfUsersInDb - userIssue.numberOfUsersWithAnIssue;
  const numberofUsersWithIssue = userIssue.numberOfUsersWithAnIssue;
  let userIssueData = [];

  if (userIssue) {
    userIssueData = [
      {
        name: `Users Who Reported An Issue (${numberofUsersWithIssue})`,
        y: (numberofUsersWithIssue / userIssue.numberOfUsersInDb) * 100,
      },
      {
        name: `Users With No Reported Issues (${numberofUsersWithoutIssue})`,
        y: (numberofUsersWithoutIssue / userIssue.numberOfUsersInDb) * 100,
      },
    ];
  } else {
    userIssueData = [];
  }
  return userIssueData;
};

export const formatIssueTypeData = (issueType) => {
  let issueTypeData = [];
  Object.keys(issueType).length
    ? issueType?.summary[0].map((item, index) => {
        issueTypeData.push({
          name: `${item.issueType}(${item.count})`,
          y: (item.count / issueType.total) * 100,
        });
        return issueTypeData;
      })
    : (issueTypeData = []);
  return issueTypeData;
};

export const formatIssueDetailData = (issueDetail) => {
  let issueDetailData = [];
  let totalIssueDetail = 0;
  Object.keys(issueDetail).length &&
    issueDetail.forEach((item) => {
      return (totalIssueDetail += item.count);
    });
  Object.keys(issueDetail).length
    ? issueDetail.map((item, index) => {
        issueDetailData.push({
          name: `${item.name}(${item.count})`,
          y: (item.count / totalIssueDetail) * 100,
        });
        return issueDetailData;
      })
    : (issueDetailData = []);
  return issueDetailData;
};

export const formatAverageEouData = (dataValue) => {
  const series = [];
  const result = {};
  let data = [];

  for (let i in dataValue) {
    let raters = {};
    raters.y = dataValue[i].genericEaseOfUseRating;
    raters.z = dataValue[i].numberOfRaters;
    raters.a = dataValue[i].numberOfActiveUsers;
    raters.b = dataValue[i].percentageOfActiveUsers;
    data.push(raters);
  }
  series.push({
    name: "Ease of Use",
    data: data,
  });
  result.series = series;
  return result;
};

export const formatOverallExpDates = (dataValue) => {
  const dates = [];
  for (let i in dataValue) {
    dates.push(dataValue[i].monthIdentifier);
  }
  return dates;
};

export const formatOverallExpData = (dataValue) => {
  const series = [];
  const result = {};
  let data = [];

  for (let i in dataValue) {
    let raters = {};
    raters.y = dataValue[i].averageOverallRating;
    raters.z = dataValue[i].numberOfOverallRaters;
    raters.a = dataValue[i].activeUserCount;
    raters.b = dataValue[i].percentageOfRaters;
    data.push(raters);
  }
  series.push({
    name: "Overall Experience",
    data: data,
  });
  result.series = series;
  return result;
};

export const formatBarChartData = (dataValue, detail) => {
  const series = [];
  for (let i in dataValue) {
    let data = [];
    let raters = {};
    let name = detail ? dataValue[i].issueDetail : dataValue[i].issueType;
    raters.y = dataValue[i].resolutionSpeed;
    raters.z = dataValue[i].count;
    data.push(raters);
    series.push({
      name: name,
      data: data,
    });
  }
  return series;
};

export const formatBarChartDate = (dataValue) => {
  const result = [];
  for (let i in dataValue) {
    result.push(dataValue[i].issueType);
  }
  return result;
};

export const formatBarChartDataStack = (dataValue) => {
  const countries = [];
  const result = [];
  const values = [];
  for (let i in dataValue) {
    if (dataValue[i].details) {
      Object.keys(dataValue[i].details).forEach((key) => {
        countries.push({ [key]: dataValue[i].details[key] });
      });
    }
  }
  for (let i in countries) {
    if (!result.includes(Object.keys(countries[i])[0])) {
      result.push(Object.keys(countries[i])[0]);
      values.push({
        name: Object.keys(countries[i])[0],
        data: [Object.values(countries[i])[0]],
      });
    } else {
      for (let j in values) {
        if (values[j].name === Object.keys(countries[i])[0]) {
          values[j].data.push(Object.values(countries[i])[0]);
        }
      }
    }
  }
  return values;
};

export const formatBarChartDateDetail = (dataValue) => {
  const ratingDates = [];
  for (let i in dataValue) {
    ratingDates.push(dataValue[i]?.timeRangeIdentifier);
  }
  return ratingDates;
};

export const formatBarChartDataDetail = (dataValue) => {
  const values = Object.values(dataValue);
  const issues = [];
  const result = [];
  for (let i in values) {
    for (let j in values[i].data) {
      let raters = {};
      if (!issues.includes(values[i].data[j].issueDetail)) {
        issues.push(values[i].data[j].issueDetail);
        raters.y = values[i].data[j].resolutionSpeed;
        raters.z = values[i].data[j].count;
        result.push({
          name: values[i].data[j].issueDetail,
          data: [raters],
        });
      } else {
        for (let k in result) {
          if (result[k].name === values[i].data[j].issueDetail) {
            result[k].data.push({
              y: values[i].data[j].resolutionSpeed,
              z: values[i].data[j].count,
            });
          }
        }
      }
    }
  }
  return result;
};

export const formatBarChartDataType = (dataValue) => {
  const values = Object.values(dataValue);
  const issues = [];
  const result = [];
  for (let i in values) {
    for (let j in values[i].data) {
      let raters = {};
      if (!issues.includes(values[i].data[j].issueType)) {
        issues.push(values[i].data[j].issueType);
        raters.y = values[i].data[j].resolutionSpeed;
        raters.z = values[i].data[j].count;
        result.push({
          name: values[i].data[j].issueType,
          data: [raters],
        });
      } else {
        for (let k in result) {
          if (result[k].name === values[i].data[j].issueType) {
            result[k].data.push({
              y: values[i].data[j].resolutionSpeed,
              z: values[i].data[j].count,
            });
          }
        }
      }
    }
  }
  return result;
};

export const formatMapData = (data) => {
  const title = Object.keys(data);
  const values = Object.values(data);
  const dataValues = [];
  const result = []
  for (let i in values) {
    let currentValue = [];
    values[i].forEach((item) => {
      let dataObj = {};
      dataObj.ipAddress = item.ipAddress
      dataObj.lon = Number(item.longitude);
      dataObj.lat = Number(item.latitude);
      currentValue.push(dataObj);
    });
    dataValues.push(currentValue);
    result.push({title:title[i], data: currentValue})
  }
  return result;
};
