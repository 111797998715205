import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const ZtnaLineChart = ({ data, title, yAxis, xAxis, avg }) => {
  Highcharts.setOptions({
    credits: {
      enabled: false,
    },
  });
  const options = {
    chart: {
      type: "line",
      width: null,
    },
    title: {
      text: title ? title : "",
    },

    xAxis: {
      categories: xAxis,
    },
    yAxis: {
      title: {
        text: yAxis,
      },
    },
    series: data,
    credits: {
      enabled: false,
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            xAxis: {
              labels: {
                formatter: function () {
                  return this.value.charAt(0);
                },
              },
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
  const avgOptions = {
    chart: {
      type: "line",
      width: null,
    },
    title: {
		text: title ? title : "",
    },

    xAxis: {
      categories: xAxis,
    },
    yAxis: {
      title: {
        text: yAxis,
		},
		max: 6,
    },
    series: data.series,
    credits: {
      enabled: false,
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },

    tooltip: {
      pointFormat:
        "<br>Raters: {point.z}, <br>Users: {point.a}, <br>Percentage: {point.b}",
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            xAxis: {
              //   labels: {
              //     formatter: function () {
              //       return this.value.charAt(0);
              //     },
              //   },
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <HighchartsReact
      options={avg ? avgOptions : options}
      highcharts={Highcharts}
    />
  );
};

export default ZtnaLineChart;
