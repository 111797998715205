import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../atoms";
import { FormStyle, FieldStyle } from "./Form.style";
import { useParams } from "react-router-dom";
import { deviceSendMessagePost } from "../../../Redux/Zte/Device/DeviceActions";

const SendNotificationForm = ({setOpen}) => {
  const dispatch = useDispatch();
  const { deviceId } = useParams();
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(deviceSendMessagePost({
      deviceId: deviceId,
      message: message,
    }));
   setOpen(false)
  };

  return (
    <FormStyle>
      <div className="create-issue">
        <FieldStyle>
          <div>
            <textarea
              onChange={handleChange}
              placeholder="Type here"
              maxLength="2000"
            />
          </div>
        </FieldStyle>

        <div className="threat-btn">
          <Button fill="fill" onclick={handleSubmit}>
            Send
          </Button>
        </div>
      </div>
    </FormStyle>
  );
};

export default SendNotificationForm;
