import { AdminCardStyles } from "./AdminCard.style";
import { Button } from "../../atoms";
import SearchDropDown from "./SearchDropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ztnaServicesGroupAdd,
  ztnaServicesGroupGet,
  ztnaServicesAdd,
  ztnaServicesGet,
  ztnaServicesIdentifierAdd,
} from "../../../Redux/Zte/Ztna/ZtnaActions";
import { getUsersRegions } from "../../../Redux/Zte/ZteUsers/ZteUsersActions";
import { toast } from "react-toastify";

const ZtnaManagementCard = () => {
  const dispatch = useDispatch();
  const serviceTypes = [
    { name: "Web", id: "0" },
    { name: "Non-Web", id: "1" },
  ];
  const environmentTypes = [
    { name: "Production", id: "0" },
    { name: "Test", id: "1" },
    { name: "Development", id: "2" },
  ];
  const [groupName, setGroupName] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [serviceName, setserviceName] = useState("");
  const [serviceIdentifierName, setServiceIdentifierName] = useState("");
  const [privateIp, setPrivateIp] = useState("");
  const [publicIp, setPublicIp] = useState("");
  const [vipName, setVipName] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [environmentType, setEnvironmentType] = useState("");
  const [isLoadBalanced, setIsLoadBalanced] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [image, setImage] = useState();
  const [imageFile, setImageFile] = useState();
  let fileInput = document.getElementById("file-input");
  let fileUpload = document.getElementById("image-upload");

  fileInput?.addEventListener("change", function () {
    if (fileInput.files.length > 0) {
      const fileSize = fileInput.files.item(0).size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb >= 2) {
        toast.error("Please select a file less than 2MB");
        fileUpload.disabled = true;
      } else {
        fileUpload.disabled = false;
      }
    }
  });

  const handleChange = (e) => {
    !fileUpload.disabled && setImage(URL.createObjectURL(e.target.files[0]));
    !fileUpload.disabled && setImageFile(e.target.files[0]);
  };

  const serviceGroup = {
    name: groupName,
  };

  const serviceIdentifierPayload = {
    name: serviceIdentifierName,
    ztnaServiceId: selectedService,
    publicIp: publicIp,
    privateIp: privateIp,
    ipRegionId: selectedRegion,
    vipName: vipName,
  };

  const fieldsCompletedService =
    serviceName && serviceType && serviceId && environmentType;

  const fieldsCompletedServiceIdentifier =
    serviceIdentifierPayload.name &&
    serviceIdentifierPayload.ztnaServiceId &&
    serviceIdentifierPayload.publicIp &&
    serviceIdentifierPayload.privateIp &&
    serviceIdentifierPayload.ipRegionId &&
    serviceIdentifierPayload.vipName;

  useEffect(() => {
    dispatch(ztnaServicesGroupGet());
    dispatch(ztnaServicesGet());
    dispatch(getUsersRegions());
  }, [dispatch]);

  const handleAddGroupname = () => {
    dispatch(ztnaServicesGroupAdd(serviceGroup));
  };
  const handleAddService = () => {
    const data = new FormData();
    data.append("Name", serviceName);
    data.append("ZTNAServiceGroupId", serviceId);
    data.append("IsLoadBalanced", isLoadBalanced);
    data.append("ServiceType", serviceType);
    data.append("Logo", imageFile);
    data.append("Environment", environmentType);
    fieldsCompletedService
      ? dispatch(ztnaServicesAdd(data))
      : toast.error("Enter required fields!");
  };
  const {
    ztnaServicesGroup,
    fetchingZtnaServicesGroup,
    ztnaServices,
    fetchingZtnaServices,
  } = useSelector((state) => state.ztna);

  const { allRegions, fetchingUsersRegions } = useSelector(
    (state) => state.users
  );

  const handleChecked = () => {
    setIsLoadBalanced((isLoadBalanced) => !isLoadBalanced);
  };

  const handleAddServiceIdentifier = () => {
    fieldsCompletedServiceIdentifier
      ? dispatch(ztnaServicesIdentifierAdd(serviceIdentifierPayload))
      : toast.error("All fields are required!");
  };

  const handleRemove = () => {
    setImageFile(null);
    setImage(null);
  };

  return (
    <AdminCardStyles>
      <div>
        <p>ZTNA Service Group</p>
        <div className="group-container">
          <input
            placeholder="Name"
            className="input-text"
            onChange={(e) => setGroupName(e.target.value)}
          />
          <Button
            className="button-style"
            fill="fill"
            onclick={handleAddGroupname}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="ztna-container">
        <div>
          <p>ZTNA Service</p>
          <div className="group-container">
            <input
              placeholder="Name"
              className="input-text"
              onChange={(e) => setserviceName(e.target.value)}
            />
            <div className="dropdown-group">
              <SearchDropDown
                title={"Service group"}
                type="select"
                data={ztnaServicesGroup}
                group={"services"}
                setData={setServiceId}
                loading={fetchingZtnaServicesGroup}
              />
              <SearchDropDown
                title={"Type"}
                type="select"
                data={serviceTypes}
                setData={setServiceType}
                group={"services"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="group-container">
        <div className="admin-input">
          <SearchDropDown
            title={"Environment"}
            type="select"
            data={environmentTypes}
            setData={setEnvironmentType}
            group={"services"}
          />
        </div>
        {image && <img className="image-container" src={image} alt="logo" />}
        <div className="admin-input">
          <div className="group-container">
            <input
              type="file"
              id="file-input"
              accept="image/*"
              onChange={handleChange}
            />
            {image && (
              <div className="remove-btn">
                <Button fill="fill" onclick={handleRemove}>
                  Remove
                </Button>
              </div>
            )}
            <div className="heading-style">
              <p>Load Balanced</p>
              <input
                type="checkbox"
                onChange={handleChecked}
                className="check"
              />
            </div>

            <Button fill="fill" onclick={handleAddService} id="image-upload">
              Add
            </Button>
          </div>
          <p className="small-label">Accepts .png, .jpeg and .jpg</p>
        </div>
      </div>
      <div className="">
        <p>ZTNA Service Identifier</p>
        <div className="group-container">
          <SearchDropDown
            title={"Ztna Service"}
            type="select"
            data={ztnaServices}
            group={"services"}
            setData={setSelectedService}
            loading={fetchingZtnaServices}
          />
          <SearchDropDown
            title={"IPRegion"}
            type="select"
            data={allRegions}
            group={"services"}
            setData={setSelectedRegion}
            loading={fetchingUsersRegions}
          />
          <div className="input-service-name">
            <input
              placeholder="Name"
              className="input-text"
              onChange={(e) => setServiceIdentifierName(e.target.value)}
            />
            <input
              placeholder="Private IP address"
              className="input-text"
              onChange={(e) => setPrivateIp(e.target.value)}
            />
          </div>
        </div>
        <div className="group-container">
          <input
            placeholder="Public IP address"
            className="input-text"
            onChange={(e) => setPublicIp(e.target.value)}
          />
          <input
            placeholder="VIP Name"
            className="input-text"
            onChange={(e) => setVipName(e.target.value)}
          />
          <div className="button-style">
            <Button fill="fill" onclick={handleAddServiceIdentifier}>
              Add
            </Button>
          </div>
        </div>
      </div>
    </AdminCardStyles>
  );
};

export default ZtnaManagementCard;
