import React, {useState, useEffect} from 'react'
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts";
import { formatCategoryPieBreakdown } from "../../../utils/formatChartData";
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend);

const ThreatSourcePie = () => {
	const { threatBreakdown } = useSelector((state) => state.threatDashboard);

const [formattedData, setFormattedData] = useState();

useEffect(() => {
		if (Object.keys(threatBreakdown).length > 0) {
			let formated = formatCategoryPieBreakdown(threatBreakdown, "Category Breakdown");
			setFormattedData(formated);
		}
	
	}, [threatBreakdown]);



  return (
    <HighchartsReact highcharts={Highcharts} options={formattedData} />
  )
// return <>yes</>
}

export default ThreatSourcePie;