import API from "../axio";

export const getUserIssueSummary = async () => {
  try {
    const { data } = await API.get(`/ZtnaDashboard/user-issue-summary`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueTypeSummary = async () => {
  try {
    const { data } = await API.get(`/ZtnaDashboard/issue-type-summary`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueDetailSummary = async () => {
  try {
    const { data } = await API.get(`/ZtnaDashboard/issue-detail-summary`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueTypeSummaryByDuration = async (duration) => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/issue-type-summary-by-duration?duration=${duration}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueDetailSummaryByDuration = async (interval) => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/issue-detail-summary-by-duration?interval=${interval}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueTypeSummaryByResolutionSpeed = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/issue-type-by-resolution-speed`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueTypeSummaryByResolutionSpeedByDuration = async (
  duration
) => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/issue-type-by-resolution-speed-by-duration?duration=${duration}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueTypeByCountry = async () => {
  try {
    const { data } = await API.get(`/ZtnaDashboard/issue-type-by-country`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueDetailByResolutionSpeed = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/issue-detail-by-resolution-speed`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getIssueDetailByResolutionSpeedByDuration = async (duration) => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/issue-detail-by-resolution-speed-and-duration?duration=${duration}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAverageUserServiceExperienceRating = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/average-user-service-experience-rating`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const getAverageUserServiceExperienceRatingRegion = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/average-user-service-experience-by-region`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAverageOverallRating = async () => {
  try {
    const { data } = await API.get(`/ZtnaDashboard/average-overall-rating`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAverageOverallRatingByCountry = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/average-overall-rating-by-country`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAverageOverallRatingByRegion = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/average-overall-rating-by-region`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAverageGenericEaseofUse = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/average-generic-ease-of-use`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getAverageGenericEaseofUseByCountry = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/average-generic-ease-of-use-by-country`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
export const activeUserHistoryByService = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/active-user-history-by-service`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const activeUserHistoryByRegion = async () => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/active-user-history-by-region`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const geoLocation = async (interval) => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/geo-location?interval=${interval}`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const hourlyConnectionTrend = async (interval) => {
  try {
    const { data } = await API.get(
      `/ZtnaDashboard/hourly-connection-trend`
    );
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
