import { useState } from "react";
import { AdminCardStyles } from "./AdminCard.style";
import { Table } from "../../atoms";
import { structureData } from "../../../utils/FormatData";
import { useSelector } from "react-redux";

const EmsGroupManagement = () => {
  const headers = [
    "Value",
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const { emsGroups, fetchingEmsGroups } = useSelector(
    (state) => state.devices
  );

  return (
    <AdminCardStyles>
      <div className="table-style">
        <Table
          headers={headers}
          data={structureData("emsGroupList", emsGroups?.data)}
          loading={fetchingEmsGroups}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={emsGroups?.totalCount}
          pageSize={10}
        />
      </div>
    </AdminCardStyles>
  );
};

export default EmsGroupManagement;
