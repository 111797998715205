import { Button } from "../../atoms";
import { RatingsCardStyles } from "./RatingsCard.style";
import RatingStarIcons from "./RatingsStarIcons";
const RatingsCard = ({
  question,
  type,
  options,
  yesValue,
  setYesValue,
  rating,
  setRating,
  setComment,
  handleSubmit,
  handleSubmitQuestions,
  score,
  setScore,
  questionType,
  submit,
  value
}) => {
  const handleNo = () => {
    setYesValue(2);
    setScore(6);
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };
  const handleUsageChange = (e) => {
    setScore(parseInt(e.target.value));
  };

  return (
    <RatingsCardStyles>
      <p>{question}</p>
      {type === "star" ? (
        <div className="">
          <RatingStarIcons rating={rating} setRating={setRating} />
        </div>
      ) : type === "select" && yesValue === 0 ? (
        <div className="submit-container">
          <div className="select-container">
            <input
              type="radio"
              name="answer"
              id="yes"
              onChange={() => setYesValue(1)}
            />
            <label htmlFor={"yes"}>Yes</label>
          </div>
          <div className="select-container" key="0">
            <input
              type="radio"
              name="answer"
              id="no"
              value={2}
              onChange={handleNo}
            />
            <label htmlFor={"no"}>No</label>
          </div>
        </div>
      ) : type === "select" && yesValue === 1 ? (
        <div>
          <p>If yes, how often over the last 30 days?</p>
          {options?.map((value, key) => (
            <div className="select-container" key={key}>
              <input
                type="radio"
                id={value.value}
                name="answer"
                value={value.value}
                onChange={handleUsageChange}
              />
              <label htmlFor={value.value}>{value.title}</label>
            </div>
          ))}
          <div className="option-button">
            <Button
              fill="fill"
              onclick={handleSubmitQuestions}
              disabled={!score}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : type === "select" && yesValue === 2 ? (
        <div>
          <p>No</p>
          <div className="option-button">
            <Button
              fill="fill"
              onclick={handleSubmitQuestions}
              disabled={!score}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : type === "select" && questionType === "usage" ? (
        <div>
          {options?.map((value, key) => (
            <div key={key} className="select-container">
              <input
                type="radio"
                id={value.value}
                name="answer"
                value={value.value}
                onChange={handleUsageChange}
              />
              <label htmlFor={value.value}>{value.title}</label>
            </div>
          ))}
        </div>
      ) : type === "no-service" ? (
        <p>
         {value}
        </p>
      ): (
        <div>
          <textarea
            name="comments"
            rows="3"
            cols="60"
            onChange={handleCommentChange}
            disabled={submit}
          />
          <div className="button-right">
            <Button fill="fill" onclick={handleSubmit} disabled={submit}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </RatingsCardStyles>
  );
};

export default RatingsCard;
