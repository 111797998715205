import { createStore, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
// import logger from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducer from "./RootReducer";


// const middleWares = [thunk  , logger];
const middleWares = [thunk ];

const store = createStore(rootReducer, applyMiddleware(...middleWares));

const persistor = persistStore(store);

const tools = { store, persistor };

export default tools;
